<template>
  <div>
    <modal :modal-id="modalId" modal-classes="modal--data-download-custom">
      <div class="full-height flex flex-h-center flex-v-center">
        <div>
          <h1>API Credentials</h1>
          <p class="modal__intro margin-space-top">You are about to regenerate your API credentials. The previous credentials will no longer be valid.</p>

          <button class="button--cta margin-space-right" @click="regenerateApiCredentials">Confirm</button>
          <button class="button--underline" @click="closeModal">Cancel</button>
        </div>
      </div>
    </modal>

    <h2 class="heading--underlined">API Credentials</h2>
    <div class="flex">
      <div class="flex-3">
        <p class="text-grey"><strong>Auth Key: </strong> {{ authKeyDup }}</p>
        <p class="text-grey"><strong>Auth Token: </strong> {{ authTokenDup }}</p>
      </div>
      <div class="flex-1 margin-space-top-small right">
        <modal-trigger :modal-id="modalId" class="button--cta-small">Regenerate</modal-trigger>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Modal from '../modal/Modal'
import ModalTrigger from '../modal/ModalTrigger'

import FlashProvider from '../../utilities/flash-provider.js'
import { eventHub } from '../../ibat.js'

export default {
  name: 'api-credentials',

  components: { Modal, ModalTrigger },

  props: {
    authKey: { type: String, required: true },
    authToken: { type: String, required: true },
    businessId: { type: String, required: true }
  },

  data() {
    return {
      modalId: 'modal--api-credentials-confirm',
      authKeyDup: this.authKey,
      authTokenDup: this.authToken
    }
  },

  created() {
    const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content');
    axios.defaults.headers.common['X-CSRF-Token'] = csrf;
    axios.defaults.headers.common['Accept'] = 'application/json';
  },

  methods: {
    regenerateApiCredentials() {
      axios.post(`/business/${this.businessId}/regenerate_api_credentials`)
        .then(res => {
          const newCreds = res.data;

          this.authKeyDup = newCreds.auth_key;
          this.authTokenDup = newCreds.auth_token;

          FlashProvider.display('API credentials successfully regenerated!')
          this.closeModal();
        })
        .catch(err => {
          FlashProvider.display(err.response.data.message, 'alert')
          this.closeModal();
        })
    },

    closeModal() {
      eventHub.$emit('requestModalClose', this.modalId)
    },
  }
}
</script>
