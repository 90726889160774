<template>
  <div :class="wrapperClass">
    <div :class="barClass" :style="`width: ${computedPercentage}%; height: ${height}px`"></div>
  </div>
</template>

<script>
export default {
  props: {
    height: { type: Number, default: 3 },
    percentage: { type: Number, required: true },
    border: { type: Boolean, default: false },
    color: { type: String, default: 'green' }
  },

  computed: {
    wrapperClass() {
      return this.border ? 'loader--bar-wrapper--border' : 'loader--bar-wrapper'
    },

    barClass() {
      return `loader--bar-bar-${this.color}`
    },

    computedPercentage() {
      if (this.percentage < 0) {
        return 0;
      } else if (this.percentage > 100) {
        return 100;
      } else {
        return this.percentage;
      }
    }
  }
}
</script>
