<template>
  <ul class="ul--table ul--sites ul-unstyled ul-no-margin">
    <li class="flex flex-h-between desktop-only">
      <div class="list-item--heading-flex-2 list-item--desktop-only"></div>
      <div class="list-item--heading-flex-6 list-item--desktop-only">Name</div>
      <div class="list-item--heading-flex-3 list-item--desktop-only">
        <div class="flex">
          <tooltip
            class="tooltip"
            :text="wdpaTooltipText">
            <span class="column-title">PAs</span>
            <page-sort v-if="!isPortfolio" sort-key="wdpa"></page-sort>
          </tooltip>
        </div>
      </div>
      <div class="list-item--heading-flex-3 list-item--desktop-only">
        <div class="flex">
          <tooltip
            class="tooltip"
            :text="redlistTooltipText">
              <span class="column-title">Red List</span>
              <page-sort v-if="!isPortfolio" sort-key="redlist"></page-sort>
          </tooltip>
        </div>
      </div>
      <div class="list-item--heading-flex-3 list-item--desktop-only">
        <div class="flex">
          <tooltip 
            class="tooltip"
            :text="kbaTooltipText">
            <span class="column-title">KBAs</span>
            <page-sort v-if="!isPortfolio" sort-key="kba"></page-sort>
          </tooltip>
        </div>
      </div>
      <div class="list-item--heading-flex-4 list-item--desktop-only">
        <div class="flex">
          <tooltip
            class="tooltip"
            :text="countryTooltipText">
            <span class="column-title">Country</span>
          </tooltip>
        </div>
      </div>
      <div v-if="!isPortfolio" class="list-item--heading-flex-3 list-item--desktop-only">Portfolios</div>
      <div class="list-item--heading-flex-4 list-item--desktop-only">Site Type</div>
      <div v-if="user.businessOwnerOrAdmin" class="list-item--heading-flex-4 list-item--desktop-only">Created By</div>
      <div class="list-item--heading-flex-3 list-item--desktop-only">Created<page-sort v-if="!isPortfolio" sort-key="created_at"></page-sort></div>
      <div class="list-item--heading-flex-1 list-item--desktop-only"></div>
      <div class="list-item--heading-flex-1 list-item--desktop-only"></div>
      <div class="list-item--heading-flex-1 list-item--desktop-only list-item--heading-end-icon">
        <i class="ul__icon icon-chevron-right flex-hs-end"></i>
      </div>
    </li>

    <page-item-site 
      v-show="!noResults"
      v-for="(item, index) in items" ref="item"
      :key="generateKey(index)"
      :site="item"
      :can-delete="canDelete"
      :isPortfolio="isPortfolio">
    </page-item-site>

  </ul>
</template>

<script>
  import axios from 'axios';
  import { eventHub } from '../../ibat.js';
  import FlashProvider from '../../utilities/flash-provider.js';
  import PageItemSite from './PageItemSite.vue';
  import PageSort from './PageSort.vue';
  import Tooltip from '../tooltip/Tooltip.vue';
  import { TooltipTexts } from '../../mixins/mixin-tooltip-texts.js';
  import { mixinPaginationGroup } from '../../mixins/mixin-pagination-group.js';
  import { mixinLoggedIn } from '../../mixins/mixin-logged-in.js'
  import helpers from '../../utilities/helpers.js'

  export default {
    name: 'page-sites',

    components: { PageItemSite, PageSort, Tooltip },

    mixins: [mixinPaginationGroup, TooltipTexts, mixinLoggedIn],

    props: {
      isPortfolio: { type: Boolean, default: false }
    },

    created() {
      eventHub.$on('setSiteAsUpdating', this.setSiteAsUpdating)
      eventHub.$on('updateSite', this.updateSite)
      eventHub.$on('removeSite', this.removeSite)
    },

    methods: {
      generateKey(string) {
        return helpers.generateKey(string)
      },

      setSiteAsUpdating(siteAttributes) {
        const siteToUpdate = this.obtainSiteFromId(siteAttributes.id)

        this.setUpdateInProgress(siteToUpdate)
      },

      removeSite(siteId) {
        this.items = this.items.filter(s => s.id != siteId);
      },

      updateSite(updatedSite) {
        const site = this.obtainSiteFromId(updatedSite.id)

        if (this.updateComplete(site, updatedSite)) {
          FlashProvider.display(`Site data for ${site.name} is up to date` )
        }

        Object.assign(site, updatedSite)
      },

      obtainSiteFromId(siteId) {
        return this.items.find((site) => site.id === siteId)
      },

      setUpdateInProgress(site) {
        site.updateInProgress = true
        site.requiresUpdate = false
      },

      updateComplete(site, updatedSite) {
        return site.updateInProgress && !updatedSite.updateInProgress
      }
    }
  }
</script>
