<template>
  <div>
    <label for="site">Select Site</label>

    <infinite-scroll-select  
    @input="emitSelectedSite" 
    v-model="selectedSite"
    :showValidationError="!isValidSiteSelection"
    :value="selectedSite"
    :apiEndPoint="apiEndPoint"
    :itemsPerPage="itemsPerPage"
    siteType="eib"
    >
  </infinite-scroll-select>

    <label for="habitat-type">Select Habitat Type</label>
    <radio-buttons
      id="habitat-type"
      :options="habitatTypes"
      v-model="selectedHabitatType">
    </radio-buttons>

    <label>Include 15-50km buffer?</label>
    <p class="text-14">Please check the list of projects that should apply the 15-50km buffer in the User Guide - Annex 1. The following buffers are applied by default; 0-5km, 5-15km.</p>
    <radio-buttons
      id="eib-buffer"
      :options="buffers"
      v-model="selectedBuffer">
    </radio-buttons>

    <p v-show="!submitting" class="form__submission">
      <button @click="submit" class="button--outline margin-space-right" :class="{ 'button--disabled': !formComplete }" :disabled="!this.formComplete">Create</button>
      <button @click="cancel" class="button--underline">Cancel</button>
    </p>

    <i v-show="submitting" class="icon--spinner"></i>

    <p class="padding-top light">Require further information on the reports IBAT offers? Head over to our <a href="/sample-downloads" target="_blank">examples page</a> for a detailed explanation of each report and a downloadable example.</p>
  </div>
</template>

<script>
  import SingleSelect from '../form_fields/SingleSelect'
  import RadioButtons from '../form_fields/RadioButtons'
  import { mixinFormReports } from '../../mixins/mixin-form-reports'
  import InfiniteScrollSelect from '../form_fields/infiniteScrollSelect'

  export default {
    name: 'form-create-eib-report',

    mixins: [mixinFormReports],

    components: {
      SingleSelect,
      RadioButtons,
      InfiniteScrollSelect
    },

    props: {
      defaultSite: { type: [Object, String], required: false , default: "" },
      itemsPerPage : {type : Number, default: 100},
      buffers: { type: Array, required: true },
      habitatTypes: { type: Array, required: true },
      apiEndPoint:{
        type:String , required: true 
      }
    },

    data() {
      return {
        selectedSite: this.defaultSite,
        selectedBuffer: this.buffers[0],
        selectedHabitatType: this.habitatTypes[0],
      }
    },

    computed: {
      isValidSiteSelection() {
        return this.hasSelected(this.selectedSite)
      },

      isValidBufferSelection() {
        return this.hasSelected(this.selectedBuffer)
      },

      isValidHabitatTypeSelection() {
        return this.hasSelected(this.selectedHabitatType)
      },

      formComplete() {
        return this.isValidSiteSelection && this.isValidBufferSelection && this.isValidHabitatTypeSelection
      },

      reportParams() {
        return {
          name: '',
          report_type_id: 'EibReport',
          site_ids: this.selectedSite.id,
          eib_buffer: this.selectedBuffer.id,
          habitat_type_id: this.selectedHabitatType.id
        }
      }
    }
  }
</script>
