import { render, staticRenderFns } from "./DonutChart.vue?vue&type=template&id=2c09b149"
import script from "./DonutChart.vue?vue&type=script&lang=js"
export * from "./DonutChart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports