<template>
  <form 
    id="user_migration"
    class="user_migration"
    :key="resetKey"
    @submit.prevent="onSubmit"
    @reset.prevent="onReset"
  >

    <div class="form__field">
      <label for="site">Select a Plan</label>
      <SingleSelect
        v-model="form.plan_id"
        :options="plans"
        value-attribute="id"
        title-attribute="name"
      >
      </SingleSelect>
    </div>

    <div class="form__field">
      <label for="site">Select User(s)</label>
      <Multiselect
        id="multiple-users"
        v-model="form.users"
        :options="planUsers"
        :multiple="true"
        label="email"
        placeholder=""
        :close-on-select="false"
        :clearOnSelect="false"
        track-by="id"
      >
      </Multiselect>
    </div>

    <div class="form__field">
      <label for="site">Select an Organisation</label>
      <SingleSelect
        v-model="form.organisation_id"
        value-attribute="id"
        title-attribute="name"
        :options="organisations"
      >
      </SingleSelect>
    </div>

    <div class="actions center button-wrapper">
      <button
        type="submit"
        class="button--cta button-margin-bottom margin-space-right"
        :disabled="busy"
      >
        Migrate
      </button>
      <button
        type="reset"
        class="button--underline"
      >
        Reset
      </button>
    </div>

  </form>
</template>

<script>
import { debounce } from 'lodash'
import FlashProvider from '../../utilities/flash-provider'
import Multiselect from 'vue-multiselect'
import SingleSelect from '../form_fields/SingleSelect'

const FORM_DEFAULTS = {
  plan_id: null,
  users: [],
  organisation_id: null
}

export default {
  components: {
    Multiselect,
    SingleSelect,
  },

  props: {
    plans: {
      required: true,
      type: Array,
      validation: (plans) => plans.every((plan) => typeof plan === 'object')
    },

    users: {
      required: true,
      type: Array,
      validation: (users) => users.every((user) => typeof user === 'object')
    },

    organisations: {
      required: true,
      type: Array,
      validation: (organisations) => organisations.every(
        (organisation) => typeof organisation === 'object'
      )
    },
  },

  data () {
    return {
      busy: false,
      form: { ...FORM_DEFAULTS },
      resetKey: 0, // helps reset the form
    }
  },

  methods: {

    // Determine whether the form should be considered busy.
    setBusy (boolean) {
      this.busy = boolean === true
    },

    onReset () {
      this.form = { ...FORM_DEFAULTS }
      this.resetKey++ // helps reset the form
    },

    onSubmit: debounce(function () {
      this.setBusy(true)

      const payload = {
        organisation_id: this.form.organisation_id,
        plan_id: this.form.plan_id,
        user_emails: this.form.users.map((user) => user.email),
      }

      // Send the form data
      this.$http.patch('/admin/user_migrations', payload)
        .then((res) => {
          console.log(res)
          if (res.data) {
            FlashProvider.display(res.data)
          }
        })
        .catch(err => {
          console.warn(err)
          if (err.response.data) {
            FlashProvider.display(err.response.data, 'alert')
          }
        })
        .finally(() => {
          this.setBusy(false)
        })
    }, 500)
  },

  computed: {
    planUsers () {
      return this.users.filter((user) => {
        return user.plan_id === this.form.plan_id
      })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
