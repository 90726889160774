<template>
  <div class="height-100">
    <div class="filter--search">
      <input type="text" v-model="searchTerm" :placeholder="placeholder" class="search__input">
      <i class="search__icon" v-show="noSearchTerm" @click="applySearch()"></i>
      <i class="search__icon close" v-show="!noSearchTerm" @click="clear()"></i>
    </div>

    <ul class="ul-unstyled filter__options-list filter__options-list--search filter__options-padding">
      <page-filter-select-multiple v-if="selectMultiple"
        :option="selectMultiple.title"
        :filterBy="selectMultiple.filter"
        v-on:selectMultipleOptions="selectMultipleOptions"
        >
      </page-filter-select-multiple>

      <template v-for="option in options">
        <page-filter-option v-show="matches(option)"
          :option="option"
          :selected="false"
          :isAlreadySelected="isAlreadySelected(option)"
          ref="childOption">
        </page-filter-option>
      </template>
    </ul>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  import PageFilterOption from './PageFilterOption.vue'
  import PageFilterSelectMultiple from './PageFilterSelectMultiple.vue'

  export default {
    name: 'page-filter-search',

    components: { PageFilterOption, PageFilterSelectMultiple },

    props: {
      options: {
        required: true,
        type: Array
      },
      name: {
        required: true,
        type: String
      },
      title: {
        required: true,
        type: String
      },
      type: {
        required: true,
        type: String
      },
      selectMultiple: Object,
      activeOptions: Array
    },

    data () {
      return {
        children: {},
        searchTerm: ''
      }
    },

    computed: {
      noSearchTerm () {
        return this.searchTerm.length == 0
      },

      placeholder () {
        return `Search ${this.title}`
      }
    },

    created () {
      eventHub.$on('resetSearchTerm', this.resetSearchTerm)
    },

    mounted () {
      this.children = this.$refs.childOption

      this.prepopulateSelectedOptions()
    },

    methods: {
      prepopulateSelectedOptions() {
        let preservedIds = this.$store.state.pagination.selectedFilterOptions[this.name];
        

        if (preservedIds) {
          preservedIds.forEach(id => {
            let childToSelect = this.children.find(child => child.id === id);
  
            if (childToSelect) { childToSelect.selectOption(); };
          });
        }
      },

      matches (option) {
        const noSearch = this.searchTerm == '';
        const regex = new RegExp(`${this.searchTerm}`, 'i');
        const match = option.name.match(regex);

        return noSearch || match
      },

      isAlreadySelected (option) {
        return this.activeOptions.indexOf(option) > -1
      },

      resetSearchTerm () {
        this.searchTerm = ''
      },

      clear () {
        this.resetSearchTerm()
      },

      applySearch () {
        this.resetSearchTerm()
        this.$emit('apply:filter')
      },

      selectMultipleOptions (object) {
        this.$emit('selectMultipleOptions', object)
      }
    }
  }
</script>
