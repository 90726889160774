<template>
  <div @click="goToLink" :class="['empty-state-message--wrapper', { 'empty-state-message--clickable': link }]">
    <i :class="['icon-' + emptyStateIcon, 'empty-state-message--icon']"></i>
    <h2>{{ lineOne }}</h2>
    <h2 v-if="lineTwo">{{ lineTwo }}</h2>
  </div>
</template>

<script>
  export default {
    name: 'empty-state-message',

    props: {
      lineOne: { required: true, type: String },
      lineTwo: { required: false, type: String },
      link: { required: false, type: String },
    },

    computed: {
      emptyStateIcon() {
        const possibleIcons = ['butterfly', 'bear', 'penguin', 'bird'];
        const index = Math.floor(Math.random() * possibleIcons.length);

        return possibleIcons[index]
      }
    },

    methods: {
      goToLink: function() {
        if (this.link) { window.location.href = this.link; };
      }
    }
  }
</script>
