<template>
  <div>
    <div @click="checkStatus">
      <slot name="trigger"></slot>
    </div>

    <div v-if="isActive">
      <slot name="target"></slot>
    </div>

    <div v-if="!isActive">
      <slot name="target-else"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'show-hide',

    props: {
      active: Boolean
    },

    data () {
      return {
        isActive: this.active
      }
    },

    methods: {
      checkStatus () {
        this.isActive = this.$el.querySelector('input.v-sh-trigger').checked
      }
    }
  }
</script>
