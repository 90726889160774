export const storeModal = {
  namespaced: true,

  state: {
    isActive: false,
    modalContent: '',
    useStoreContent: false
  },

  mutations: {
    updateModalStatus () {
      this.state.modal.isActive = !this.state.modal.isActive
    },

    resetModal () {
      this.state.modal.isActive = false
    },

    updateModalContent (state, content) {
      this.state.modal.modalContent = content
    },

    updateUseStoreContent (state, boolean) {
      this.state.modal.useStoreContent = boolean
    }
  }
}
