export default {
  escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  },

  kebabToCamel (string) {
    let array = string.split('-')
    let camelArray = array.slice(0,1)

    array = array.slice(1).map((string) => {
      return string.substr(0,1).toUpperCase() + string.substr(1)
    })

    camelArray = camelArray.concat(array)

    return camelArray.join('')
  },

  kebabToSpacedPascal(string) {
    return string.split(/-/).map((word) => {
      return word.substring(0, 1).toUpperCase() + word.substring(1)
    }).join(' ')
  },

  kebabToPascal (string) {
    let array = string.split('-')

    array = array.map((string) => {
      return string.substr(0,1).toUpperCase() + string.substr(1)
    })

    return array.join('')
  },

  removeWhiteSpaces (string) {
    const regex = new RegExp(/\s/g)

    return string.replace(regex, g => { return '' })
  },

  spacesToKebab (string) {
    const regex = new RegExp(/\s/g)

    return string.replace(regex, g => { return '-' })
  },

  trim (string) {
    return string.replace(/^\s+|\s+$/gm,'')
  },

  randomNumber () {
    return Math.floor(Math.random() * 1000) + 1000
  },

  getCookie (cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for(let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim()

      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  },

  generateKey(identifier) {
    return `${this.randomNumber()}${identifier}${this.randomNumber()}`
  },

  // From https://gist.github.com/gregkorossy/e33be1f201cf242197d9c4d0a1fa7335
  semaphore(max) {
    let counter = 0;
    let waiting = [];

    const take = function() {
      if (waiting.length > 0 && counter < max){
        counter++;
        let promise = waiting.shift();
        promise.resolve();
      }
    }

    let newSemaphore = {}

    newSemaphore.acquire = function() {
      if(counter < max) {
        counter++
        return new Promise(resolve => {
        resolve();
      });
      } else {
        return new Promise((resolve, err) => {
          waiting.push({resolve: resolve, err: err});
        });
      }
    }

    newSemaphore.release = function() {
     counter--;
     take();
    }
    return newSemaphore
  }
}
