<template>
	<div class="map-wrapper full-height">
		<div :id="id" class="map full-height">
			<div id="geocoder-wrapper" class="map--geocoder-wrapper flex-v-center">
				<tooltip
					class="tooltip margin-space-left"
					position="below"
					:text="coordinatesTooltipText"
				>
					<i class="icon-question-mark icon--tooltip-mapbox"></i>
				</tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import * as turf from "@turf/turf";

import Tooltip from "../tooltip/Tooltip";
import helpers from "../../utilities/helpers.js";
import mapFunctions from "../../utilities/map-functions.js";
import LocalGeocoder from "../../utilities/local-geocoder.js";
import { TooltipTexts } from "../../mixins/mixin-tooltip-texts";
import { eventHub } from "../../ibat.js";

import { mixinMapbox, mixinMapboxInfo } from "../../mixins/mixin-mapbox.js";
import { mixinLoggedIn } from "../../mixins/mixin-logged-in";

export default {
	mixins: [mixinMapbox, mixinMapboxInfo, TooltipTexts, mixinLoggedIn],

	components: { Tooltip },

	props: {
		inModal: {
			type: Boolean,
			required: true,
		},
	},

	data() {
		return {
			id: "map--data-download",
			geocoderControl: undefined,
			bufferTarget: undefined,
			layerLookup: {
				Point: {
					type: "circle",
					paint: { "circle-radius": 6, "circle-color": "#B42222" },
				},
				LineString: {
					type: "line",
					paint: { "line-color": "#B42222", "line-width": 2 },
				},
				Polygon: {
					type: "fill",
					paint: {
						"fill-outline-color": "red",
						"fill-color": "#088",
						"fill-opacity": 0.3,
					},
				},
			},
			firstDrawLayer: "gl-draw-polygon-fill-inactive.cold",
		};
	},

	mounted() {
		this.map.on("load", () => {
			this.addGeocoder(this.handleGeocoderOutput);

			this.addDrawControl({
				onTrashClick: this.handleTrashClick,
				onDrawCreate: this.handleMapDraw,
				onDrawUpdate: this.handleMapDraw,
			});

			this.alterToolTipsTexts();
		});
	},

	created() {
		eventHub.$on("updateSelectedSite", this.updateSelectedSite);
		eventHub.$on("modalOpened", this.resizeMap);
		eventHub.$on("updateBufferAmount", this.updateBufferAmount);
		eventHub.$on("clearDownloadMap", this.clearDownloadFeature);
	},

	beforeDestroy() {
		eventHub.$off("updateSelectedSite");
		eventHub.$off("modalOpened");
		eventHub.$off("updateBufferAmount");
		eventHub.$off("clearDownloadMap");
	},

	methods: {
		updateBufferAmount() {
			this.drawBuffer(false);
		},

		handleGeocoderOutput(event) {
			const feature = turf.feature(event.result.geometry);
			this.handleTrashClick();
			this.draw.deleteAll();
			this.draw.add(feature);
			this.map.flyTo({ center: feature.geometry.coordinates, zoom: 5 });
			this.handleMapDraw(feature);
		},

		handleMapDraw(feature) {
			this.clearLayers();
			this.toggleDrawControls();
			this.setDownloadFeature(feature);

			if (mapFunctions.isMarker(feature) || mapFunctions.isPolyline(feature)) {
				this.bufferTarget = feature;
				this.drawBuffer(false);
			} else if (mapFunctions.isPolygon(feature)) {
				this.setDownloadFeatureArea(feature);
				this.bufferTarget = undefined;
			}
		},

		drawBuffer(fitBounds = true) {
			if (this.map.getLayer("site-buffer")) {
				this.map.removeLayer("site-buffer");
				this.map.removeSource("site-buffer");
			}

			let bufferTarget = this.bufferTarget;
			const bufferAmount = this.$store.state.map.bufferAmount;

			if (bufferTarget) {
				if (bufferAmount) {
					bufferTarget = turf.buffer(bufferTarget.geometry, bufferAmount, {
						units: "kilometers",
					});

					this.map.addSource("site-buffer", {
						type: "geojson",
						data: {
							type: "Feature",
							geometry: bufferTarget.geometry,
						},
					});

					this.map.addLayer(
						{
							id: "site-buffer",
							type: "fill",
							source: "site-buffer",
							layout: {},
							paint: this.layerLookup["Polygon"].paint,
						},
						this.firstDrawLayer
					);

					this.toggleDrawControls();
				}

				this.setDownloadFeatureArea(bufferTarget.geometry);
			} else {
				this.setDownloadFeatureArea();
			}

			if (fitBounds) {
				if (bufferTarget.geometry.type == "Point") {
					this.map.flyTo({
						center: bufferTarget.geometry.coordinates,
						zoom: 5,
					});
				} else {
					const bbox = turf.bbox(bufferTarget.geometry);
					this.map.fitBounds(bbox, { padding: 200 });
				}
			}
		},

		updateSelectedSite(site) {
			this.draw.deleteAll();
			this.toggleDrawControls();

			this.bufferTarget = site;

			this.clearLayers();

			this.map.addSource("site", {
				type: "geojson",
				data: {
					type: "Feature",
					geometry: site.geometry,
				},
			});

			const config = this.layerLookup[site.geometry.type];

			this.map.addLayer({
				id: "site",
				type: config.type,
				source: "site",
				layout: {},
				paint: config.paint,
				filter: ["==", "$type", site.geometry.type],
			});

			this.drawBuffer();
		},

		handleTrashClick() {
			eventHub.$emit("deleteDownloadFeature");
			this.clearDownloadFeature();
		},

		clearDownloadFeature() {
			this.draw.deleteAll();
			this.clearLayers();
			this.setDownloadFeature();
			this.setDownloadFeatureArea();
			this.setGeocoderInput();
			this.toggleDrawControls(true);
		},

		clearLayers() {
			["site", "site-buffer"].forEach((name) => {
				if (this.map.getLayer(name)) {
					this.map.removeLayer(name);
					this.map.removeSource(name);
				}
			});
		},

		setDownloadFeature(feature) {
			if (!feature) {
				this.bufferTarget = undefined;
				this.$store.dispatch("map/updateDownloadFeature", null);
				return;
			}

			this.$store.dispatch("map/updateDownloadFeature", feature);
		},

		setGeocoderInput(input) {
			if (input) {
				this.geocoderControl.setInput(input);
			} else {
				this.geocoderControl._clear();
			}
		},

		setDownloadFeatureArea(feature) {
			if (!feature) {
				this.$store.dispatch("map/updateDownloadArea", 0);
				return;
			}

			this.$store.dispatch("map/updateDownloadArea", turf.area(feature));
		},

		toggleDrawControls(show) {
			const className = "controls--disabled";

			const lineControl = ".mapbox-gl-draw_line";
			const polyControl = ".mapbox-gl-draw_polygon";
			const pointControl = ".mapbox-gl-draw_point";

			const controls = [lineControl, polyControl, pointControl];

			controls.forEach((control) => {
				const element = document.querySelector(control);

				if (element) {
					if (show) {
						element.classList.remove(className);
					} else {
						element.classList.add(className);
					}
				}
			});
		},

		resizeMap() {
			setTimeout(() => this.map.resize(), 25);
		},

		alterToolTipsTexts() {
			document.querySelector('.mapbox-gl-draw_line')?.setAttribute('title', this.$t('tooltips.line'));
			document.querySelector('.mapbox-gl-draw_polygon')?.setAttribute('title', this.$t('tooltips.polygon'));
			document.querySelector('.mapbox-gl-draw_point')?.setAttribute('title', this.$t('tooltips.point'));
		},
	},
};
</script>
