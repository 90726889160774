<template>
  <img @click="openEditFieldModal" :class="computedClass" :src="require('images/icons/pen.svg')">
</template>

<script>
import { eventHub } from '../../ibat.js';

export default {
  props: {
    size: { type: String, required: false },
    attribute: { type: String, required: true },
    label: { type: String, required: true },
    options: { type: Array, required: false },
    option_types: { type: Array, required: false },
    organisation_type_id: { type: Number, required: false },
    passwordRequired: { type: Boolean, default: true },
    onClose: { type: Function, required: true },
    url: { type: String, required: true },
    resource: { type: String, required: true }
  },

  computed: {
    computedClass() {
      return this.size ? `button--edit-${this.size}` : 'button--edit'
    }
  },

  methods: {
    openEditFieldModal() {
      const config = {
        passwordRequired: this.passwordRequired,
        attribute: this.attribute,
        label: this.label,
        options: this.options,
        option_types: this.option_types,
        organisation_type_id: this.organisation_type_id,
        onClose: this.onClose,
        url: this.url,
        resource: this.resource
      }
  
      eventHub.$emit('requestEditModalOpen', config);
    }
  }
}
</script>
