<template>
	<div class="map-wrapper full-height">
		<div :id="id" class="map full-height">
			<div id="geocoder-wrapper" class="map--geocoder-wrapper flex-v-center">
				<tooltip
					class="tooltip margin-space-left"
					position="below"
					:text="coordinatesTooltipText"
				>
					<i class="icon-question-mark icon--tooltip-mapbox"></i>
				</tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import * as turf from "@turf/turf";

import helpers from "../../utilities/helpers.js";
import mapFunctions from "../../utilities/map-functions.js";
import { eventHub } from "../../ibat.js";
import { mixinResponsive } from "../../mixins/mixin-responsive.js";
import Tooltip from "../tooltip/Tooltip";
import { TooltipTexts } from "../../mixins/mixin-tooltip-texts";
import { mixinMapbox, mixinMapboxInfo } from "../../mixins/mixin-mapbox.js";
import { mixinLoggedIn } from "../../mixins/mixin-logged-in";

export default {
	name: "mapbox",

	mixins: [
		mixinMapbox,
		mixinMapboxInfo,
		mixinResponsive,
		TooltipTexts,
		mixinLoggedIn,
	],

	components: { Tooltip },

	props: {
		id: {
			type: String,
			required: true,
		},
		inModal: {
			type: Boolean,
			required: true,
		},
	},

	data() {
		return {
			featureGroup: "",
			popup: "",
			draw: undefined,
			bufferTarget: undefined,
			geocoderControl: {},
			newSiteLayer: {},
			hiddenFilterLayers: [],
		};
	},

	watch: {
		inModal(active) {
			if (active) {
				this.map.resize();
				this.hideFilterLayers();
			} else {
				this.map.resize();
				this.showFilterLayers();
			}
		},
	},

	created() {
		eventHub.$on("latLngAdded", this.addMarker);
		eventHub.$on("geolocationSearch", this.getLatLng);
		eventHub.$on("siteFormReset", this.resetMap);
		eventHub.$on("updateBufferAmount", this.updateBufferAmount);
		eventHub.$on("modalOpened", this.resizeMap);

		if (!this.inModal) {
			document.addEventListener("click", this.popupEvent);
		}
	},

	beforeDestroy() {
		eventHub.$off("latLngAdded");
		eventHub.$off("geolocationSearch");
		eventHub.$off("siteFormReset");
		eventHub.$off("updateBufferAmount");
		eventHub.$off("clearDownloadMap", this.clearDownloadMap);
		eventHub.$off("modalOpened");
		document.removeEventListener("click", this.popupEvent);
	},

	mounted() {
		this.$store.commit("map/resetActiveSites");
		this.$store.commit("map/storeMap", this.map);

		this.map.on("style.load", () => eventHub.$emit("reloadLayers"));

		this.map.on("load", () => {
			this.addGeocoder(this.emitGeocoderCoordinates);

			if (!this.isTouch()) {
				this.addDrawControl({
					onTrashClick: this.handleTrashClick,
					onDrawCreate: this.handleMapDraw,
					onDrawUpdate: (feature) => {
						this.updateStoredGeometry(feature);
					},
				});
			} else {
				this.addTouchMarkerLayer();
			}
			this.alterToolTipsTexts();
		});

		const clickHandler = (e) => {
			if (!this.draw) {
				return;
			}

			let selectedDraw = this.draw.getSelected();

			if (selectedDraw.features.length > 0) {
				let feature = selectedDraw.features[0];

				if (mapFunctions.isMarker(feature)) {
					this.addPopup(feature);
				} else {
					if (!this.inModal) {
						this.addPopup(feature);
					}
				}
			} else {
				if (this.popup) {
					this.popup.remove();
				}
			}
		};

		this.map.on("click", clickHandler);
	},

	computed: {
		isDesktop() {
			return this.isLarge();
		},
	},

	methods: {
		hideFilterLayers() {
			this.hiddenFilterLayers = [];

			this.map.getStyle().layers.forEach((layer) => {
				if (layer.id.substring(0, 14) === "vfilter-layer_") {
					if (
						this.map.getLayoutProperty(layer.id, "visibility") === "visible"
					) {
						this.hiddenFilterLayers.push(layer.id);
					}

					this.map.setLayoutProperty(layer.id, "visibility", "none");
				}
			});
		},

		showFilterLayers() {
			this.hiddenFilterLayers.forEach((layerId) => {
				this.map.setLayoutProperty(layerId, "visibility", "visible");
			});
		},

		handleTrashClick() {
			this.draw.deleteAll();

			if (this.popup) {
				this.popup.remove();
			}

			this.clearStoredGeometry();
			this.setGeocoderInput();
			this.toggleDrawControls(true);
		},

		handleMapDraw(feature) {
			this.updateStoredGeometry(feature);
			this.newSiteLayer = feature;
			this.toggleDrawControls(false);
		},

		alterToolTipsTexts() {
			document.querySelector('.mapbox-gl-draw_line')?.setAttribute('title', this.$t('tooltips.line'));
			document.querySelector('.mapbox-gl-draw_polygon')?.setAttribute('title', this.$t('tooltips.polygon'));
			document.querySelector('.mapbox-gl-draw_point')?.setAttribute('title', this.$t('tooltips.point'));
		},

		toggleDrawControls(show) {
			const className = "controls--disabled";

			const lineControl = ".mapbox-gl-draw_line";
			const polyControl = ".mapbox-gl-draw_polygon";
			const pointControl = ".mapbox-gl-draw_point";

			const controls = [lineControl, polyControl, pointControl];

			controls.forEach((control) => {
				const element = document.querySelector(control);

				if (element) {
					if (show) {
						element.classList.remove(className);
					} else {
						element.classList.add(className);
					}
				}
			});
		},

		addMarker(feature) {
			this.resetMap();

			if (!this.isTouch()) {
				this.draw.add(feature.geometry);
				this.newSiteLayer = feature;
				this.updateStoredGeometry(feature);
				this.addPopup(feature);
				this.toggleDrawControls(false);
			} else {
				this.addTouchMarker(feature);
			}

			this.map.panTo(this.getPopupCoords(feature));
		},

		addTouchMarker(feature) {
			const fc = turf.featureCollection([feature]);
			this.map.getSource("touch-layer").setData(fc);
		},

		addTouchMarkerLayer() {
			this.map.addSource("touch-layer", {
				type: "geojson",
				data: { type: "FeatureCollection", features: [] },
			});

			this.map.addLayer({
				id: "touch-layer-points",
				type: "circle",
				source: "touch-layer",
				paint: {
					"circle-radius": 6,
					"circle-color": "#B42222",
				},
				filter: ["==", "$type", "Point"],
				layout: {
					visibility: "visible",
				},
			});
		},

		getLatLng(geolocation) {
			axios
				.get(
					`${this.mapboxInfo.baseUrl}/${geolocation}.json?access_token=${this.mapboxInfo.accessToken}&limit-1`
				)
				.then((response) => {
					this.addMarker(response.data.features[0]);
				})
				.catch((error) => {
					this.$store.commit(
						"map/updateMarkerErrorMsg",
						"This location cannot be found, please enter a new one."
					);
					this.resetMap();
				});
		},

		addPopup(feature, marker) {
			let className = "mapboxgl-popup-point";

			if (!mapFunctions.isMarker(feature)) {
				className = "mapboxgl-popup-poly";
			}

			if (this.popup) {
				this.popup.remove();
			}

			this.popup = new mapboxgl.Popup({
				closeButton: true,
				closeOnClick: false,
				offset: !marker ? 10 : 40,
				className: className,
			});

			if (!marker) {
				this.popup.addTo(this.map);
			} else {
				marker.setPopup(this.popup);
			}

			this.updatePopupContent(feature, marker);
		},

		updatePopupContent(feature, marker) {
			if (!this.popup) {
				return;
			}

			let popupContent = "";

			if (mapFunctions.isMarker(feature)) {
				let coords = mapFunctions.geomToObj(feature.geometry);
				popupContent +=
					"<strong>Location</strong> " +
					Number(coords.lat).toFixed(2) +
					"," +
					Number(coords.lng).toFixed(2);
			}

			if (mapFunctions.isPolyline(feature)) {
				popupContent += "<strong>New polyline</strong>";
			}

			if (mapFunctions.isPolygon(feature)) {
				popupContent += "<strong>New polygon</strong>";
			}

			popupContent +=
				'<div class="button-wrapper button-wrapper--map-popup"><button v-click-save class="button--cta button--small">Save</button></div>';

			this.popup.setLngLat(this.getPopupCoords(feature));
			this.popup.setHTML(popupContent);
		},

		getPopupCoords(feature) {
			switch (feature.geometry.type) {
				case "Point":
					return feature.geometry.coordinates;
					break;
				case "LineString":
					return feature.geometry.coordinates[0];
					break;
				case "Polygon":
					return feature.geometry.coordinates[0][0];
					break;
			}
		},

		popupEvent(e) {
			if (e.target.attributes["v-click-save"]) {
				this.saveNewSite();
			}
		},

		resetMap() {
			if (this.draw) {
				this.draw.deleteAll();
				this.toggleDrawControls(true);
			}

			if (this.isTouch()) {
				this.map
					.getSource("touch-layer")
					.setData({ type: "FeatureCollection", features: [] });
			}

			if (this.popup) {
				this.popup.remove();
			}

			this.clearStoredGeometry();
		},

		updateStoredGeometry(feature) {
			let poly;
			let geometry;
			let type;

			if (mapFunctions.isMarker(feature)) {
				geometry = { point: mapFunctions.geomToObj(feature.geometry) };
				type = "point";

				this.updatePopupContent(feature);

				this.$store.commit(
					"map/updateLatLng",
					mapFunctions.latlngObjectToArray(
						mapFunctions.geomToObj(feature.geometry)
					)
				);

				this.setGeocoderInput(
					[
						feature.geometry.coordinates[1],
						feature.geometry.coordinates[0],
					].join(",")
				);
			}

			if (mapFunctions.isPolyline(feature)) {
				poly = Object.assign([], mapFunctions.geomToObj(feature.geometry));

				geometry = { poly };
				type = "polyline";

				this.setGeocoderInput();
			}

			if (mapFunctions.isPolygon(feature)) {
				poly = Object.assign([], mapFunctions.geomToObj(feature.geometry));
				geometry = { poly };
				type = "polygon";

				this.setGeocoderInput();
			}

			const site = {
				geometry: geometry,
				type: type,
			};

			this.$store.dispatch("map/updateSite", site);
		},

		clearStoredGeometry() {
			this.setGeocoderInput();
			this.$store.dispatch("map/resetSite");
		},

		saveNewSite() {
			this.popup.remove();

			const latlng = mapFunctions.latlngFromGeometry(
				this.$store.state.map.geometry,
				this.$store.state.map.geometryType
			);

			this.map.panTo(latlng);

			eventHub.$emit("saveNewSite");
			eventHub.$emit("requestModalOpen", "create-site");
		},

		drawBuffer() {
			const feature = this.bufferTarget;

			if (!feature) {
				this.draw.delete("draw-buffer");
				this.setDownloadFeatureArea();
				return;
			}

			if (mapFunctions.isMarker(feature) || mapFunctions.isPolyline(feature)) {
				let bufferAmount = this.$store.state.map.bufferAmount;

				if (bufferAmount) {
					const bufferFeature = turf.buffer(feature, bufferAmount, {
						units: "kilometers",
					});

					if (bufferFeature.properties.constructor.name != "Object") {
						bufferFeature.properties.constructor.name = "Object"; // required for IE11 but breaks other browsers
					}

					bufferFeature.id = "draw-buffer";

					this.draw.add(bufferFeature);
					this.setDownloadFeatureArea(bufferFeature);
				} else {
					this.draw.delete("draw-buffer");
					this.setDownloadFeatureArea();
				}
			}
		},

		updateBufferAmount() {
			this.drawBuffer();
		},

		setDownloadFeature(feature) {
			if (!feature) {
				this.$store.dispatch("map/updateDownloadFeature", null);
				return;
			}

			this.$store.dispatch("map/updateDownloadFeature", feature);
		},

		setDownloadFeatureArea(feature) {
			if (!feature) {
				this.$store.dispatch("map/updateDownloadArea", 0);
				return;
			}

			this.$store.dispatch("map/updateDownloadArea", turf.area(feature));
		},

		clearDownloadMap() {
			this.resetMap();
		},

		setGeocoderInput(input) {
			if (input) {
				this.geocoderControl.setInput(input);
			} else {
				this.geocoderControl._clear();
			}
		},

		resizeMap() {
			setTimeout(() => {
				this.map.resize();
			}, 25);
		},

		emitGeocoderCoordinates(event) {
			eventHub.$emit("latLngAdded", turf.feature(event.result.geometry));
		},
	},
};
</script>
