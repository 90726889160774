<template>
  <div :class="classes">
    <button class="button button--plain nav__burger icon-burger" @click="toggleNavPane"></button>

    <ul class="nav__pane ul-unstyled" :class="{ 'nav__pane--active': isActive }">
      <button class="button button--plain nav__close icon-close" @click="toggleNavPane"></button>
      <slot></slot>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'nav-burger',

    props: {
      classes: String
    },

    data () {
      return {
        isActive: false
      }
    },

    methods: {
      toggleNavPane () {
        this.isActive = !this.isActive
      }
    }
  }  
</script>
