<template>
  <div class="container">
    <edit-modal></edit-modal>
    <div class="flex flex-h-center">
      <h1 class="ellipsis heading--simple--no-margin no-margin--top">{{ this.organisation_name }}</h1>
          <div class="margin-space-left-small margin-space-right">
            <edit-button
              title="Edit the name of organisation"
              attribute="name"
              :option_types="this.options"
              :organisation_type_id="this.organisation_type_id"
              label="Organisation Name"
              :password-required="false"
              :on-close="(newName) => { this.organisation_name = newName }"
              :url="`/admin/business/${itemDup.id}`"
              resource="business"
              size="small"
            ></edit-button>
          </div>
        </div> 
   </div>
</template>

<script>
  import axios from 'axios'
  import { eventHub } from '../../ibat.js'
  import EditButton from '../buttons/EditButton.vue'
  import EditModal from '../modal/EditModal.vue'
  import Modal from '../modal/Modal.vue'
  import Tabs from '../tabs/Tabs.vue'
  import Tab from '../tabs/Tab.vue'
  import PageWrapper from '../pagination/PageWrapper.vue'
  import FlashProvider from '../../utilities/flash-provider.js'

  export default {
    name: 'show-business',

    components: {
      EditButton,
      EditModal,
      Modal,
      Tab,
      Tabs,
      PageWrapper,
    },

    props: {
      business: {
        type: Object,
        required: true
      },

      business_types: {
        type: Array,
        required: true
      },

    },

    data() {
      return {
        itemDup: this.business,
        errorMessage: '',
        modalId: 'update-business',
        options: this.business_types,
        organisation_name: this.business.name,
        organisation_type_id: this.business.organisation_type_id, 
      }
    },

    created() {
    // Listen for the custom event on the eventHub
    this.getUpdatingBusiness()
    eventHub.$on('organisation_update', this.getUpdatingBusiness)

    },

    beforeDestroy() {
    eventHub.$off('organisation_update', this.getUpdatingBusiness)
    },

    methods: {
      
      closeModal () {
        eventHub.$emit('requestModalClose', this.modalId)
      },

      getUpdatingBusiness (name, organisation_type_id) {
        if(!name && !organisation_type_id){
         this.organisation_name = this.business.name 
         this.organisation_type_id = this.business.organisation_type_id
        }else{
               this.organisation_name = name
        this.organisation_type_id = organisation_type_id
        }
   
      }
    }
  }
</script>
