<template>
  <div class="form--create-report flex flex-h-center flex-v-center bg-grey-xlight">
    <div class="form__content">
      <h2 class="center">Create Report</h2>
      <label for="report-type">Select Report Type</label>
      <radio-buttons
        :key="radioButtonsKey"
        id="report-type"
        :options="reportTypes"
        @input="handleRadioButtonClick">
      </radio-buttons>

      <div v-if="site.for_consultancy">
        <label class="form__validation-message">CONSULTANCY PLAN DOWNLOAD</label>
        <p>Please note by accessing this download that it will be added to the monthly subscription fees payable for access to IBAT, in accordance with IBAT’s current Pay as you go Rates (‘’Pay as you go details’’)</p>
        <p>More information can be found <a href="/subscriptions" target="_blank">here</a></p>
      </div>

      <form-create-ps6-report
        v-if="ps6Report"
        :key="ps6FormKey"
        :biomes="biomes"
        :default-site="selectedSite"
        :itemsPerPage="itemsPerPage"
        :apiEndPoint="apiEndPoint"
        >
      </form-create-ps6-report>

      <form-create-proximity-report
        v-if="proximityReport"
        :key="proximityFormKey"
        :buffers="buffers"
        :biomes="biomes"
        :default-site="selectedSite"
        :itemsPerPage="itemsPerPage"
        :apiEndPoint="apiEndPoint"
        >
      </form-create-proximity-report>

      <form-create-eib-report
        v-if="eibReport"
        :key="eibFormKey"
        :habitat-types="habitatTypes"
        :buffers="eibBuffers"
        :default-site="selectedSite"
        :itemsPerPage="itemsPerPage"
        :apiEndPoint="apiEndPoint"
        >
      </form-create-eib-report>

      <form-create-freshwater-report
        v-if="freshwaterReport"
        :key="freshwaterFormKey"
        :default-site="selectedSite"
        :itemsPerPage="itemsPerPage"
        :apiEndPoint="apiEndPoint"
        >
      </form-create-freshwater-report>

      <form-create-multi-site-report
        v-if="multiSiteReport"
        :key="multiSiteFormKey"
        :default-site="selectedSite"
        :itemsPerPage="itemsPerPage"
        :apiEndPoint="apiEndPoint"
        >
      </form-create-multi-site-report>

      <form-create-star-report
        v-if="starReport"
        :key="starFormKey"
        :default-site="selectedSite"
        :itemsPerPage="itemsPerPage"
        :apiEndPoint="apiEndPoint"
        >
      </form-create-star-report>

      <form-create-tnfd-locate-report
        v-if="tnfdReport"
        :key="tnfdFormKey"
        :default-site="selectedSite"
        :itemsPerPage="itemsPerPage"
        :apiEndPoint="apiEndPoint"
        :sitesOptionsLimit="sitesOptionsLimit"
        :dprSitesQuota="dprSitesQuota"
      >
      </form-create-tnfd-locate-report>
    </div>

  </div>
</template>

<script>
  import RadioButtons from '../form_fields/RadioButtons'
  import FormCreatePs6Report from '../form/FormCreatePs6Report'
  import FormCreateProximityReport from '../form/FormCreateProximityReport'
  import FormCreateEibReport from '../form/FormCreateEibReport'
  import FormCreateFreshwaterReport from '../form/FormCreateFreshwaterReport'
  import FormCreateMultiSiteReport from '../form/FormCreateMultiSiteReport'
  import FormCreateStarReport from '../form/FormCreateStarReport'
  import FormCreateTnfdLocateReport from '../form/FormCreateTnfdLocateReport'

  import { DEFAULT_SITES_OPTIONS_LIMIT } from '../../utilities/constants'

  import helpers from '../../utilities/helpers.js'
  import { eventHub } from '../../ibat.js'
  import axios from 'axios'


  export default {
    name: 'form-create-report',

    components: { 
      RadioButtons, 
      FormCreatePs6Report,
      FormCreateProximityReport,
      FormCreateEibReport,
      FormCreateFreshwaterReport,
      FormCreateMultiSiteReport,
      FormCreateStarReport,
      FormCreateTnfdLocateReport
    },

    props: {
      formFields: { required: true, type: Object },
      site: { type: [Object , String], default : "" },
    },

    created() {
      eventHub.$on('modalClosed', this.resetForm)
      eventHub.$on('siteSelected', this.setSelectedSite)
    },

    data () {
      return {
        selectedSite: this.site || '',
        selectedReportType: this.reportTypes ? this.reportTypes[0] : {},
        radioButtonsKey: helpers.randomNumber(),
        ps6FormKey: helpers.randomNumber(),
        proximityFormKey: helpers.randomNumber(),
        eibFormKey: helpers.randomNumber(),
        freshwaterFormKey: helpers.randomNumber(),
        multiSiteFormKey: helpers.randomNumber(),
        starFormKey: helpers.randomNumber(),
        tnfdFormKey: helpers.randomNumber(),
        itemsPerPage: 500,
        apiEndPoint: "/fetch_site_list",
        sitesOptionsLimit: this.sitesOptionsLimit,
        dprSitesQuota: this.dprSitesQuota
      }
    },

    computed: {
      reportTypes () {
        return this.formFields.types ? this.formFields.types : []
      },
      // sites () {
      //   return this.formFields.sites ? this.formFields.sites : []
      // },
      biomes () {
        return this.formFields.biomes ? this.formFields.biomes : []
      },
      buffers () {
        return this.formFields.buffers ? this.formFields.buffers : []
      },
      habitatTypes () {
        return this.formFields.habitat_types ? this.formFields.habitat_types : []
      },
      eibBuffers () {
        return this.formFields.optional_buffer ? this.formFields.optional_buffer : []
      },
      ps6Report () {
        return this.selectedReportType.slug == "ps6"
      },
      proximityReport () {
        return this.selectedReportType.slug == "proximity"
      },
      eibReport () {
        return this.selectedReportType.slug == "eib"
      },
      freshwaterReport () {
        return this.selectedReportType.slug == "freshwater"
      },
      multiSiteReport () {
        return this.selectedReportType.slug == "multi_site"
      },
      starReport () {
        return this.selectedReportType.slug == "star"
      },
      tnfdReport () {
        return this.selectedReportType.slug == "tnfd"
      }
    },

    methods: {
      setSelectedSite(site) {
        this.selectedSite = site
      },

      setSiteOptionsLimit() {
        this.sitesOptionsLimit = this.selectedReportType.sites_options_limit || DEFAULT_SITES_OPTIONS_LIMIT
      },

      setDprSitesQuota() {
        this.dprSitesQuota = this.selectedReportType.dpr_sites_quota || 0
      },

      resetForm() {
        this.setSelectedSite(this.site)
        this.radioButtonsKey += 1
        this.ps6FormKey += 1
        this.proximityFormKey += 1
        this.eibFormKey += 1
        this.freshwaterFormKey += 1
        this.multiSiteFormKey += 1
        this.tnfdFormKey += 1
      },

      handleRadioButtonClick(value) {
        if((value.id !== this.selectedReportType.id) ){
          this.selectedReportType =  value
          this.setSelectedSite(this.site)
          this.setSiteOptionsLimit()
          this.setDprSitesQuota()
        }
      },
      
    }
  }
</script>
