<template>
  <div>
    <div class="form__field">
      <label :for="attribute">{{ label }}</label>

      <div class="user-edit-field--false">
        <input :value="valueDup" :id="attribute" type="text" readonly>
        <edit-button
          class="user-edit-field--false-pen"
          :attribute="attribute"
          :label="label"
          :options="options"
          resource="user"
          url="/users"
          :on-close="onClose">
        </edit-button>
      </div>
    </div>
  </div>
</template>

<script>
import EditButton from '../buttons/EditButton'

export default {
  components: { EditButton },

  props: {
    value: { type: String, required: true, },
    attribute: { type: String, required: true },
    label: { type: String, required: true },
    options: { type: Array, required: false }
  },

  data() {
    return {
      valueDup: this.value,
    }
  },

  methods: {
    onClose(newValue) { 
      this.valueDup = newValue; 
    }
  }
}
</script>
