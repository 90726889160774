const organisations = [
  { Header: "Organisation", type: "obj", value: "details", child_key: 'business_name', sorting: true, link:true },
  { Header: "Owner", type: "obj", value: "details", child_key: 'user_name', sorting: true, link: true },
  { Header: "Type", type: "obj", value: "details", child_key: 'type', sorting: true, link: '' },
  { Header: "Plan", type: "str", value: "plan", sorting: true, link: '' },
  { Header: "Sectors", type: "str", value: 'sectors', sorting: false, link: true },
  { Header: "Sub-sector", type: "str", value: "sub_sectors", sorting: false, link: '' },
  { Header: "Users", type: "obj", value: "details", child_key: 'users', sorting: true, link: '' },
  { Header: "Sites", type: "obj", value: "details", child_key: 'projects', sorting: true, link: '' },
  { Header: "Reports", type: "num", value: "reports", sorting: true, link: '' },
  { Header: "Proximity", type: "num", value: "proximity", sorting: true, link: '' },
  { Header: "PS6 & ESS6", type: "num", value: "ps6_report", sorting: true, link: '' },
  { Header: "FreshWater", type: "num", value: "freshwater", sorting: true, link: '' },
  { Header: "Multi site", type: "num", value: "multi_site", sorting: true, link: '' },
  { Header: "STAR", type: "num", value: "star", sorting: true, link: '' },
  { Header: "GIS Downloads", type: "num", value: "data_download",sorting: true, link: '' },
  { Header: "Dataset Downloads", type: "num", value: "dataset_download", sorting: true, link: '' },
  { Header: "KBA", type: "num", value: "kba", sorting: true, link: '' },
  { Header: "WDPA", type: "num", value: "wdpa", sorting: true, link: '' },
  { Header: "RedList", type: "num", value: "redlist", sorting: true, link: '' },
  { Header: "STAR Layer", type: "num", value: "star_count", sorting: true, link: '' },
  { Header: "Status", type: "obj", value: "details",child_key: 'status', sorting: true, link: '' },
  { Header: "Last Sign In", type: "date", value: "last_sign_in", sorting: true, link: '' },
  { Header: "Expiry", type: "date", value: "account_expiry", sorting: true, link: '' },
  { Header: "Edit", type: "date", value: "action", sorting: false, link: true },
]

const users = [
  { Header: "User", type: "obj", value: "details", child_key: 'user_name', sorting: true, link: '/admin/business/' },
  { Header: "Business", type: "obj", value: "details", child_key: 'business_name', sorting: true, link: '/business/users/'},
  { Header: "Type", type: "obj", value: "details", child_key: 'type', sorting: true, link: ''},
  { Header: "Plan", type: "str", value: "plan", sorting: true, link: ''},
  { Header: "Sectors", type: "str", value: 'sectors', sorting: false, link: '/admin/subscriptions/'},
  { Header: "Job Title", type: "str", value: "job_title", sorting: true, link: ''},
  { Header: "Admin", type: "str", value: "admin", sorting: false, link: ''},
  { Header: "Sites", type: "obj", value: "details", child_key: 'projects', sorting: true, link: ''},
  { Header: "Reports", type: "num", value: "reports", sorting: true, link: ''},
  { Header: "DPR", type: "num", value: "dpr", sorting: true, link: ''},
  { Header: "Proximity", type: "num", value: "proximity", sorting: true, link: ''},
  { Header: "PS6 & ESS6", type: "num", value: "ps6_report", sorting: true, link: ''},
  { Header: "FreshWater", type: "num", value: "freshwater", sorting: true, link: ''},
  { Header: "Multi site", type: "num", value: "multi_site", sorting: true, link: ''},
  { Header: "STAR", type: "num", value: "star", sorting: true, link: ''},
  { Header: "GIS Downloads", type: "num", value: "data_download", sorting: true, link: ''},
  { Header: "Dataset Downloads", type: "num", value: "dataset_download", sorting: true, link: ''},
  { Header: "KBA", type: "num", value: "kba", sorting: true, link: ''},
  { Header: "WDPA", type: "num", value: "wdpa", sorting: true, link: ''},
  { Header: "RedList", type: "num", value: "redlist", sorting: true, link: ''},
  { Header: "STAR Layer", type: "num", value: "star_count", sorting: true, link: ''},
  { Header: "User Account Status", type: "obj", value: "details",child_key: 'status', sorting: true, link: ''},
  { Header: "Account Expiry", type: "date", value: "account_expiry", sorting: true, link: ''},
  { Header: "Subscription Expiry", type: "date", value: "subscription_expiry", sorting: true, link: ''},
  { Header: "Most recent sign in", type: "date", value: "last_sign_in", sorting: true, link: ''},
  { Header: "Edit", type: "date", value: "action", sorting: false, link: true},
]

const organisation_user = [
  { Header: "User", type: "obj", value: "details", child_key: 'user_name', sorting: true, link: true },
  { Header: "Email", type: "obj", value: "details", child_key: 'email', sorting: true, link: true },
  { Header: "Job Title", type: "str", value: "job_title", sorting: true, link: ''},
  { Header: "Country", type: "obj", value: "details", child_key: 'country', sorting: true, link: true },
  { Header: "Admin", type: "str", value: "admin", sorting: false, link: ''},
  { Header: "Sites", type: "obj", value: "details", child_key: 'projects', sorting: true, link: ''},
  { Header: "Reports", type: "num", value: "reports", sorting: true, link: ''},
  { Header: "Proximity", type: "num", value: "proximity", sorting: true, link: ''},
  { Header: "PS6 & ESS6", type: "num", value: "ps6_report", sorting: true, link: ''},
  { Header: "FreshWater", type: "num", value: "freshwater", sorting: true, link: ''},
  { Header: "Multi site", type: "num", value: "multi_site", sorting: true, link: ''},
  { Header: "STAR Report", type: "num", value: "star", sorting: true, link: ''},
  { Header: "GIS Downloads", type: "num", value: "data_download", sorting: true, link: ''},
  { Header: "Dataset Downloads", type: "num", value: "dataset_download", sorting: true, link: ''},
  { Header: "KBA", type: "num", value: "kba", sorting: true, link: ''},
  { Header: "WDPA", type: "num", value: "wdpa", sorting: true, link: ''},
  { Header: "Redlist", type: "num", value: "redlist", sorting: true, link: ''},
  { Header: "STAR Layer", type: "num", value: "star_count", sorting: true, link: ''},
  { Header: "Active", type: "obj", value: "details",child_key: 'active', sorting: false, link: ''},
  { Header: "Account Expiry", type: "date", value: "account_expiry", sorting: true, link: ''},
  { Header: "Subscription Expiry", type: "date", value: "subscription_expiry", sorting: true, link: ''},
  { Header: "Most recent sign in", type: "date", value: "last_sign_in", sorting: true, link: ''},
  { Header: "", type: "date", value: "last_sign_in", sorting: false, link: true},
  { Header: "Edit", type: "date", value: "action", sorting: false, link: true}
]

export { organisations, users, organisation_user }
