<template>
  <div class="chart-wrapper card--dashboard-large--right bg-white">
      <p v-if="!isGettingData && !shouldDisplayEmptyState" class="margin-space-top margin-space-left light">Reports by type</p>
      <div id="donut-chart"></div>
      <empty-state-message 
        v-if="shouldDisplayEmptyState"
        lineOne="You don't have any reports yet"
        lineTwo="Click here to create some"
        link="/reports">
      </empty-state-message>
      <loader v-if="isGettingData" :width="100" color="light-grey"></loader>
  </div>
</template>

<script>
  import axios from 'axios';
  import * as d3 from 'd3';

  import Loader from '../loading/Loader';
  import EmptyStateMessage from '../empty_state/EmptyStateMessage';

  import { mixinCharts } from'../../mixins/mixin-charts.js';

  export default {
  
  components: {
    Loader,
    EmptyStateMessage
  },

  mixins: [mixinCharts],

  props: {
    width: { required: false, default: 600, type: Number },
    height: { required: false, default: 300, type: Number },
    margin: { required: false, default: 10, type: Number },
  },

  data: function() {
    return { minPercentage: 4 }
  },

  computed: {
    radius() {
      return Math.min(this.width, this.height) / 2 - this.margin;
    },

    outerArcRadius() {
      return this.radius * 0.9;
    },
    // Compute the position of each group on the pie:
    computedData() {
      return d3.pie().sort(null).value(d => d.count)(this.chartData)
    },
    // Calculate the arc required to build the donut
    arc() {
      return d3.arc().innerRadius(this.radius * 0.7).outerRadius(this.outerArcRadius)
    },
    // Calculate the arc required for the labels
    outerArc() {
      return d3.arc().innerRadius(this.outerArcRadius).outerRadius(this.outerArcRadius);
    },
    //Calculate the arc required for the hover effect
    hoverArc() {
      return d3.arc().innerRadius(this.radius * 0.7).outerRadius(this.outerArcRadius * 1.05)
    }
  },

  methods: {
    renderChart() {
      this.createSvg();
      this.buildChart();
      this.appendLabels();
      this.appendLines();
      this.addTransitions();
    },

    createSvg() {
      const self = this
      
      this.svg = d3.select(this.selector)
        .append("svg")
          .attr('class', 'chart--svg')
          .attr('xmlns', 'http://www.w3.org/1999/xhtml')
          .attr('viewBox', '0 0 ' + self.width + ' ' + self.height)
          .attr('preserveAspectRatio', 'xMidYMid meet')
        .append("g")
          .attr("transform", "translate(" + self.width / 2 + "," + self.height / 2 + ")")
    },

    // Build the pie chart each part of the pie is a path that we build using the arc function
    buildChart() {
      this.svg
        .selectAll('path')
        .data(this.computedData)
        .enter()
        .append('path')
          .attr('opacity', 1)
          .attr('fill', d => d.data.color)
        .transition().delay((d,i) => i * 100)
        .duration(100)
          .attrTween('d', d => {
            const i = d3.interpolate(d.startAngle+0.1, d.endAngle);
            return (t) => {
              d.endAngle = i(t); 
              return this.arc(d);
            }
          });
    },

    appendLabels() {
      this.svg
        .selectAll('.donut-chart--label')
        .data(this.computedData)
        .enter()
        .filter(this.aboveMinPercentage)
        .append('text')
          .attr('class', 'donut-chart--label')
          .attr('visibility', 'hidden')
          .attr('transform', (d) => {
            const pos = this.outerArc.centroid(d);
            const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
            pos[0] = (this.radius * (midangle < Math.PI ? 1 : -1));
            pos[1] += 4; // move the label down slightly so the center point of the line is in the center of the label
            return 'translate(' + pos + ')';
          })
          .attr('visibility', 'visible')
          .transition().delay(this.chartData.length * 100)
          .text(d => d.data.type)
          .style('text-anchor', (d) => {
              const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
              return (midangle < Math.PI ? 'start' : 'end')
          })
    },

    aboveMinPercentage: function(entry) {
      return entry.data.percentage > this.minPercentage;
    },

    appendLines() {
      this.svg
        .selectAll('polyline')
        .data(this.computedData)
        .enter()
        .filter(this.aboveMinPercentage)
        .append('polyline')
        .transition().delay(this.chartData.length * 100)
        .attr('opacity', 1)
        .attr("stroke", "grey")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr('points', (d) => {
          const posA = this.arc.centroid(d) // line insertion in the slice
          const posB = this.outerArc.centroid(d) // line break: we use the other arc generator that has been built only for that
          const posC = this.outerArc.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = this.outerArcRadius * (midangle < Math.PI ? 1.05 : -1.05); // multiply by 1.05 or -1.05 to put it on the right or on the left
          return [posA, posB, posC]
        })
    },

    addTransitions() {
      this.addMouseover();
      this.addMouseleave();
    },

    addMouseover() {
      const self = this;

      this.svg.selectAll('path').on('mouseover', function(hoveredSegment, index, allSegments) { 
        self.svg.selectAll('path')
        .transition()
        .duration(500)
        .attr('opacity', (segment) => segment === this ? 1 : 0.7)
        .attr("d", self.arc)

        // increase width of selected segment + ensure it is opaque
        d3.select(this).transition()
        .duration(500)
        .attr("d", self.hoverArc)
        .attr('opacity', 1)

        // make all polylines vanish
        self.svg.selectAll('polyline')
          .transition()
          .duration(500)
          .attr('opacity', 0)
        
        // add text to center of chart
        let centerLabel = self.svg.append("text")
        .attr("text-anchor", "middle")
        .attr('class', 'donut-chart--label-center')
        .attr('opacity', 0)

        centerLabel
          .append('svg:tspan')
          .attr('x', 0)
          .attr('dy', -20)
          .text(hoveredSegment.data.type)

        centerLabel
          .append('svg:tspan')
          .attr('x', 0)
          .attr('dy', 30)
          .text(`${hoveredSegment.data.count} reports`)
        
        centerLabel
          .append('svg:tspan')
          .attr('x', 0)
          .attr('dy', 20)
          .text(`${hoveredSegment.data.percentage}% of total`)

        centerLabel.transition()
          .duration(500)
          .attr('opacity', 1)

        // remove labels
        self.svg.selectAll('.donut-chart--label')
          .transition()
          .duration(500)
          .attr('opacity', 0)
      })
    },

    addMouseleave() {
      const self = this;

      this.svg.selectAll('path').on('mouseleave', () => {
        // Make all arcs normal size
        self.svg.selectAll('path')
          .transition()
          .duration(500)
          .attr('opacity', 1)
          .attr("d", self.arc)

        // re-add the polylines
        self.svg.selectAll('polyline')
          .transition()
          .duration(500)
          .attr('opacity', 1)

        // remove the center donut label
        self.svg.selectAll('.donut-chart--label-center')
          .transition()
          .duration(500)
          .attr('opacity', 0)
          .remove()

        // add the outer labels back
        self.svg.selectAll('.donut-chart--label')
          .transition()
          .duration(500)
          .attr('opacity', 1)
      });
    }
  }
}
</script>
