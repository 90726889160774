<template>
  <div>
    <label for="site">Select Portfolio</label>
    <single-select
      v-model="selectedPortfolio"
      :key="portfolioFormKey"
      value-attribute="id"
      title-attribute="name"
      :options="portfolios"
      :showValidationError="!isValidPortfolioSelection">
    </single-select>

    <p v-show="!submitting" class="form__submission">
      <button @click="updatePortfolio" class="button--outline margin-space-right" :class="{ 'button--disabled': !formComplete }" :disabled="!this.formComplete">Add</button>
      <button @click="cancel" class="button--underline">Cancel</button>
    </p>

    <i v-show="submitting" class="icon--spinner"></i>
  </div>
</template>

<script>
  import SingleSelect from '../form_fields/SingleSelect'
  import { mixinFormPortfolio } from '../../mixins/mixin-form-portfolio'
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'form-add-to-portfolio',

    mixins: [mixinFormPortfolio],

    components: {
      SingleSelect
    },

    props: {
      formFilters: { required: true, type: Array },
    },

    data() {
      return {
        selectedPortfolio: null,
        portfolioFormKey: 0,
        portfolioFilter: [],
        portfolios: []
      }
    },

    created() {
      eventHub.$on('modalClosed', this.resetForm)
      eventHub.$on('updatePortfolioFilter', this.updatePortfolios )
      this.checkPortfolioFilter()
      this.portfolios = this.portfolioFilter[0]['options'] ? this.portfolioFilter[0]['options'] : []
    },

    computed: {
      // portfolios() {
      //   return (this.portfolioFilter[0] && this.portfolioFilter[0]['options']) ? this.portfolioFilter[0]['options'] : []
      // },

      isValidPortfolioSelection() {
        return this.hasSelected(this.selectedPortfolio)
      },

      updatePortfolioSiteParams() {
        let portfolios = this.portfolioFilter[0]['options']
        if(portfolios.length > 0) {
          let portfolio_sites = portfolios.find(x => x.id === this.selectedPortfolio).site_ids
          let site_ids = [...this.$store.state.pagination.selectedItems, ...portfolio_sites]
          return {
            portfolio_id: this.selectedPortfolio.id,
            site_ids: [...new Set(site_ids)]
          }
        }
      },

      formComplete() {
        return this.isValidPortfolioSelection
      }
    },

    methods: {
      resetForm() {
        this.selectedPortfolio = null
        this.portfolioFormKey += 1
      },

      checkPortfolioFilter() {
        this.portfolioFilter = this.formFilters.filter(child => {
          return child.name == 'portfolio'
        })
      },

      updatePortfolios(portfolio) {
        if(portfolio?.id) {
          this.portfolios = [...this.portfolios, portfolio]
        }
      }
    }
  }
</script>
