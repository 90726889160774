<template>
  <div>
    <p class="form__validation-message" v-show="showValidationError">Please enter a buffer between 1 and 50 to a maximum of 2 decimal places</p>

    <input
      @keyup="resetDebounce"
      @change="resetDebounce"
      v-model="input"
      type="number" 
      min="1" 
      max="50" 
      step="0.01"
    />
  </div>
</template>

<script>
import { eventHub } from '../../ibat.js'

export default {
  name: 'decimal-input',

  props: {
    name: { required: true, type: String },
    min: { default: 1, type: Number },
    max: { default: 50, type: Number },
    step: { default: 0.01, type: Number },
    initialValue: { default: 1, type: Number },
    showValidationError: { required: true }
  },

  data: function() {
    return {
      input: this.initialValue,
      debounceFunction: null,
    }
  },

  created() {
    this.updateBuffer(this.initialValue)
  },

  methods: {
    resetDebounce: function(event) {
      clearTimeout(this.debounceFunction);

      this.debounceFunction = setTimeout(() => { this.updateBuffer(event.target.value) }, 300);
    },

    updateBuffer(bufferValue) {
      const buffer = Number(bufferValue);

      this.$emit('input', buffer)
    }
  }
}
</script>
