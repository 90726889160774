import axios from 'axios'
import FlashProvider from '../utilities/flash-provider'
import { eventHub } from '../ibat'
import { mixinValidate } from './mixin-validate'

export const mixinFormReports = {

  mixins: [mixinValidate],

  data() {
    return { submitting: false }
  },

  created() {
    this.configureAxiosHeaders()
  },

  methods: {
    emitSelectedSite(site) {
      eventHub.$emit('siteSelected', site)
    },

    submit() {
      if (this.formComplete) {
        axios.post('/reports', { report: this.reportParams })
          .then(response => {
            this.submitting = false
            FlashProvider.display(response.data.message)

            eventHub.$emit('getNewItems')
            this.cancel()
          })
          .catch(error => {
            this.submitting = false
            FlashProvider.display(error.response.data.message, 'alert')
          })
      }
    },

    cancel() {
      eventHub.$emit('requestModalClose', 'create-report')
      eventHub.$emit('requestModalClose', 'portfolio-report')
    },

    configureAxiosHeaders() {
      const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
      axios.defaults.headers.common['X-CSRF-Token'] = csrf
      axios.defaults.headers.common['Accept'] = 'application/json'
    }
  }
}
