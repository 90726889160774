<template>
	<div class="subscription-plans__slide">
		<div class="slide__inner">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "subscription-plans-slide",
};
</script>
