<template>
  <tooltip
    class="tooltip--closer"
    :text="text">
    <a :href="`https://unstats.un.org/sdgs/report/2016/goal-${goalNumber}/`" target="blank_">
      <img :src="require(`images/icons/sdg/E-WEB-Goal-${goalNumber}.png`)" class="icon--sdg">
    </a>
  </tooltip>
</template>

<script>
import Tooltip from '../tooltip/Tooltip.vue'

export default {
  name: 'sdg-icon',

  components: { Tooltip },

  props: {
    text: { required: true },
    goalNumber: { required: true }
  }
}
</script>
