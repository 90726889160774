<template>
  <div class="tooltip">
    <div @mouseenter="toggleTooltip(true, $event)" @mouseleave="toggleTooltip(false, $event)" v-touch="toggleTooltip" class="tooltip__trigger">
      <slot></slot>
    </div>
    <div v-show="isActive" :class="tooltipClass" v-html="text"></div>
  </div>  
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'tooltip',

    props: {
      text: { type: String, required: false },
      position: { type: String, required: false, default: null }
    },

    data () {
      return {
        isActive: false,
        top: 0,
        left: 0,
        calculatedPosition: 'above'
      }
    },

    computed: {
      tooltipClass() {
        return `tooltip__target-${this.calculatedPosition}`;
      }
    },

    methods: {
      toggleTooltip (boolean, event) {
        const isActive = typeof boolean == 'boolean' ? boolean : !this.isActive

        if (isActive) {
          if (this.position) {
            this.calculatedPosition = this.position;
          } else if (event.y <= (window.innerHeight / 7)) {
            this.calculatedPosition = 'below';
          } else {
            this.calculatedPosition = 'above';
          }
        }

        this.isActive = isActive;
      }
    }
  }  
</script>
