<template>
  <div class="map-wrapper full-height">
    <div :id="id" class="map full-height"></div>
  </div>
</template>

<script>
  import * as turf from '@turf/turf'

  import { mixinResponsive } from '../../mixins/mixin-responsive.js'
  import { mixinMapbox, mixinMapboxInfo } from '../../mixins/mixin-mapbox.js'

  export default {
    name: 'mapbox-simple',

    mixins: [mixinMapbox,mixinMapboxInfo, mixinResponsive],

    props: {
      id: {
        type: String,
        required: true
      },
      geojson: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        mapboxControls: [
          { type: new mapboxgl.NavigationControl(), position: 'bottom-right' },
          { type: new mapboxgl.ScaleControl(), position: 'top-left' }
        ]
      }
    },

    mounted () {
      this.map.on('load', () => {
        let type = this.geojson.type.toLowerCase()

        if (type === 'polygon') {
          this.map.addLayer({
            'id': 'buffer-fill',
            'type': 'fill',
            'source': {
              'type': 'geojson',
              'data': this.geojson
            },
            'layout': {},
            'paint': {
              'fill-color': '#088',
              'fill-opacity': 0.2
            }
          })
        }

        this.map.addLayer({
          'id': 'buffer-line',
          'type': 'line',
          'source': {
            'type': 'geojson',
            'data': this.geojson
          },
          'layout': {},
          'paint': {
            'line-color': '#088',
            'line-opacity': 0.8,
            'line-width': 2
          }
        })

        if (type === 'point') {
          this.map.addLayer({
            'id': 'point',
            'type': 'circle',
            'source': {
              'type': 'geojson',
              'data': this.geojson
            },
            'layout': {},
            'paint': {
              'circle-color': '#088',
              'circle-opacity': 0.8,
              'circle-radius': 10,
            }
          })
        }

        let bbox = turf.bbox(this.geojson)

        this.map.fitBounds(bbox, {
          padding: 50,
          animate: false,
          maxZoom: 4
        })
      })
    }
  }
</script>
