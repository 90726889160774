<template>
  <div>
    <date-picker v-model="datesArray" :range="true" :inline="true"></date-picker>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  import DatePicker from 'vue2-datepicker'

  export default {
    name: 'page-filter-date',

    components: { DatePicker },

    data () {
      return {
        datesArray: []
      }
    },

    created() {
      this.datesArray = this.$store.state.pagination.selectedFilterOptions.date || [];
    },

    methods: {
      hasDate() {
        return this.datesArray.length > 0
      },

      resetDate(datesArray) {
        this.datesArray = datesArray
      },

      clearDate() {
        this.datesArray = []
      }
    }
  }
</script>
