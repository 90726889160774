import helpers from './helpers.js'

export default {
  dmsToLatLng (dmsArray) {
    const dmsRegex = /^(-?\d+(?:\.\d+)?)[°:d]?\s?(?:(\d+(?:\.\d+)?)['′ʹ:]?\s?(?:(\d+(?:\.\d+)?)["″ʺ]?)?)?\s?([NSEW])?/i

    let latlng = []

    if(dmsRegex.exec(dmsArray)) {
      dmsArray.forEach(dms => {
        let coordinate = dmsRegex.exec(helpers.trim(dms))
        let decimalCoordinate = ''
        let degrees = parseFloat(coordinate[1])
        const minutes = typeof (coordinate[2]) !== "undefined" ? parseFloat(coordinate[2]) / 60 : 0
        const seconds = typeof (coordinate[3]) !== "undefined" ? parseFloat(coordinate[3]) / 3600 : 0
        const hemisphere = coordinate[4] || null

        if (hemisphere !== null && /[SW]/i.test(hemisphere)) {
            degrees = Math.abs(degrees) * -1
        }
        if (degrees < 0) {
            decimalCoordinate = degrees - minutes - seconds
        } else {
            decimalCoordinate = degrees + minutes + seconds
        }

        latlng.push(decimalCoordinate)
      })
    }

    return latlng
  },

  latlngObjectToArray (latlngObj) {
    let latlng = []

    latlng.push(latlngObj.lat)
    latlng.push(latlngObj.lng)

    return latlng
  },

  convertMapBoundsToTurfBbox(bounds) {
    // // Only the NE and SW points are present in map.getBounds()
    return [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat]
  },

  addTerrainLayers (map) {
    const layers = {
      streets: L.mapbox.tileLayer('mapbox.streets'),
      hybrid: L.mapbox.tileLayer('mapbox.streets-satellite'),
      satellite: L.mapbox.tileLayer('mapbox.satellite')
    }

    layers.streets.addTo(map)
    L.control.layers(layers).setPosition('bottomleft').addTo(map)
  },

  isMarker (feature) {
    return feature.geometry.type === 'Point'
  },

  isPolyline (feature) {
    return (feature.geometry.type === 'LineString') && !(feature.geometry.type === 'Polygon')
  },

  isPolygon (feature) {
    return (feature.geometry.type === 'Polygon')
  },

  latlngFromGeometry (geometry, type) {
    let latlng = ''

    if(type == 'point') {
      latlng = geometry.point
    } else {
      latlng = geometry.poly[0]
    }

    return latlng
  },

  geomToObj(geometry) {
    switch (geometry.type) {
      case 'Point':
        return this.pointToObj(geometry.coordinates)
        break
      case 'LineString':
        return geometry.coordinates.map((c) => {
          return this.pointToObj(c)
        })
        break
      case 'Polygon':
        return geometry.coordinates[0].map((c) => {
          return this.pointToObj(c)
        })
        break
    }
  },

  pointToObj(point) {
    return {
      lat: point[1],
      lng: point[0]
    }
  },

  takeScreenshot(map) {
    map.once("render", () => {
      this.syncTakeScreenShot(map)
    })
    /* trigger render */
    map.setBearing(map.getBearing())
  },

  syncTakeScreenShot(map, opts = { remove: true }) {
    const image = new Image(),
      mapEl = map.getContainer(),
      mapClasses = mapEl.classList;

    image.src = map.getCanvas().toDataURL()
    image.classList = mapClasses

    mapEl.style.display = 'none'
    if (opts.remove) {
      map.remove()
    }

    const parent = mapEl.parentNode
    parent.appendChild(image)
    parent.classList.add("screenshot-ready")
  }
}
