<template>
  <button class="button--cta" @click.prevent="$emit('click')" :class="{ 'button--disabled': disabled }" :disabled="disabled">
    <span class="flex flex-v-center flex-h-center">
      <slot></slot>
      <loader v-if="disabled" class="margin-space-left inline-block" color="white" :width="25"></loader>
    </span>
  </button>
</template>

<script>
import Loader from '../loading/Loader'

export default {
  components: { Loader },

  props: {
    disabled: { type: Boolean, required: true }
  }
}
</script>
