import mapFunctions from './map-functions.js'

// Supports lat,lon and DMS
// eg. 51 30' 35.5140'' N, 0 7' 5.1312'' W
const LocalGeocoder = (query) => {
  let latLon = coordinatesGeocoder(query)

  if (latLon) { return latLon };

  const formattedDmsArray = query.split(',').filter(i => !!i.trim())
  const dms = mapFunctions.dmsToLatLng(formattedDmsArray)

  if (dms && dms.length > 0) {
    latLon = coordinatesGeocoder(dms.join(','), true)

    if (latLon) { return latLon };
  }

  return null
}

const coordinatesGeocoder = (query, yx) => {
  const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i)

  if (!matches) {
    return null
  }

  const coordinateFeature = (lng, lat) => {
    return {
      center: [lng, lat],
      geometry: {
        type: "Point",
        coordinates: [lng, lat]
      },
      place_name: 'Lat: ' + lat + ', Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature'
    }
  }

  const coord1 = Number(matches[1])
  const coord2 = Number(matches[2])

  let geocodes = []

  if (coord2 < -90 || coord2 > 90 || yx) {
    // lat, lng
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  if (coord1 < -90 || coord1 > 90) {
    // lng, lat
    geocodes.push(coordinateFeature(coord1, coord2))
  }

  if (geocodes.length === 0) {
    // could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord2, coord1))
    geocodes.push(coordinateFeature(coord1, coord2))
  }

  return geocodes
}

export default LocalGeocoder
