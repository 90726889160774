<template>
  <div class="form--create-report flex flex-h-center flex-v-center bg-grey-xlight">
    <div class="form__content">
      <label for="report">Portfolio Name</label>
    <text-input
      name="portfolio"
      id="portfolio"
      :showValidationError="!isValidPortfolioName"
      classes="report-form"
      v-model="portfolioName">
    </text-input>

    <multi-search-select
      id="multiple-sites"
      name="sites"
      :key="portfolioSiteFormKey"
      :options="sites"
      :defaults="multiSelectDefaults"
      :minimum="minimumSites"
      sort-by="title"
      label="Select Sites"
      :showValidationError="!isValidSitesSelection"
      v-model="selectedSites">
    </multi-search-select>

    <p v-show="!submitting" class="form__submission">
      <button @click="createPortfolio" class="button--outline margin-space-right" :class="{ 'button--disabled': !formComplete }" :disabled="!this.formComplete">Create</button>
      <button @click="cancelCreatePortfolio" class="button--underline">Cancel</button>
    </p>

    <i v-show="submitting" class="icon--spinner"></i>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { eventHub } from '../../ibat.js'
  import TextInput from '../form_fields/TextInput'
  import MultiSearchSelect from '../form_fields/MultiSearchSelect'
  import { mixinFormPortfolio } from '../../mixins/mixin-form-portfolio'

  export default {
    name: 'form-create-portfolio',

    mixins: [mixinFormPortfolio],

    components: {
      MultiSearchSelect,
      TextInput
    },

    props: {
      formFields: { required: true, type: Object },
      defaultSite: { type: Object, required: false }
    },

    data() {
      return {
        portfolioName: '',
        selectedSites: [],
        minimumSites: 1,
        portfolioSiteFormKey: 0,
        regex: /\/|\\/,
        sites: []
      }
    },

    created () {
      this.sites = this.formFields.sites ? this.formFields.sites : []
      eventHub.$on('modalClosed', this.resetDefaults)
      eventHub.$on('sites', this.getSites)

    },
  
    computed: {
      isValidSitesSelection() {
        return this.isValidLength(this.selectedSites, this.minimumSites)
      },

      isValidPortfolioName() {
        return this.isValidLength(this.portfolioName, 1)
          && this.isValidString(this.portfolioName, this.regex)
      },

      formComplete() {
        return this.isValidSitesSelection && this.isValidPortfolioName
      },

      portfolioSiteParams() {
        return {
          name: this.portfolioName,
          site_ids: this.selectedSites.map(site => site.id)
        }
      },

      multiSelectDefaults() {
        return this.defaultSite ? [this.defaultSite] : [];
      }
    },

    methods: {
      resetDefaults() {
        this.portfolioName = ''
        this.portfolioSiteFormKey += 1
      },

      getSites(sites){
        this.sites = sites
      }

    }
  }
</script>
