<template>
	<div :id="id" class="subscription-plans">
		<div class="subscription-plans__slides" :style="styles">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import { mixinResponsive } from "../../mixins/mixin-responsive.js";
import { eventHub } from "../../ibat.js";

export default {
	mixins: [mixinResponsive],

	name: "subscription-plans",
	props: {
		id: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			subscriptionPlanElement: "",
			subscriptionPlanStyle: "",
			slides: [],
			totalSlides: 0,
			slideWidth: 0,
			stylesObject: {
				"flex-basis": "0px",
				"flex-wrap": "nowrap",
				transform: "translateX(0px)",
				width: "0px",
			},
		};
	},

	created() {
		eventHub.$on("windowResized", this.configureSubscriptionPlans);
	},

	mounted() {
		this.subscriptionPlanSetup();
	},

	computed: {
		styles() {
			return this.stylesObject;
		},
	},

	methods: {
		subscriptionPlanSetup() {
			(this.subscriptionPlanElement = document.getElementById(this.id)),
				(this.subscriptionPlanStyle = window.getComputedStyle(
					this.subscriptionPlanElement,
					null
				));
			this.slides = this.$children.filter((child) => {
				return child.$options._componentTag == "subscription-plans-slide";
			});
			this.totalSlides = this.slides.length;
			this.configureSubscriptionPlans();
		},

		configureSubscriptionPlans() {
			// const subscriptionPlansWidth = screen.width;
			const subscriptionPlanWidth =
				this.subscriptionPlanElement.clientWidth -
				parseInt(this.subscriptionPlanStyle.getPropertyValue("padding-left")) -
				parseInt(this.subscriptionPlanStyle.getPropertyValue("padding-right"));

			this.stylesObject["flex-wrap"] = "nowrap";
			this.slideWidth = subscriptionPlanWidth / this.totalSlides;

			const slidesWidth = this.slideWidth * this.totalSlides;

			this.stylesObject["flex-basis"] =
				(this.slideWidth / slidesWidth) * 100 + "%";
			this.stylesObject.width = slidesWidth + "px";
		},
	},
};
</script>
