<template>
  <div class="filters__search button-margin-bottom button--search-white"> 
    <input type="text" v-model="searchTerm" @keyup.enter="search">
    <button @click="search" class="button--search__button" :class="[icon]"></button>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'filter-search',

    props: {
      searchEvent: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        searchTerm: '',
        searchMade: false,
        searchIcon: 'search'
      }
    },

    computed: {
      icon () {
        return 'icon-' + this.searchIcon
      }
    },

    methods: {
      search () {
        if (this.searchMade) {
          this.searchMade = false
          this.searchIcon = 'search'
          this.searchTerm = ''
          eventHub.$emit(this.searchEvent, '')

        } else {
          if (this.searchTerm != '') {
            this.searchMade = true
            this.searchIcon = 'close'
            eventHub.$emit(this.searchEvent, this.searchTerm)
          }
        }
      }
    }
  }  
</script>
