<template>
  <button class="button--plain icon-sort" @click="sort"></button>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'page-sort',

    props: {
      sortKey: {
        required: true
      }
    },

    data () {
      return {
        sortDirection: 1
      }
    },

    methods: {
      sort () {
        this.sortDirection = this.sortDirection * -1
        const order = this.sortDirection == 1 ? "ASC" : "DESC"

        this.$store.commit('pagination/updateSortField', this.sortKey,)
        this.$store.commit('pagination/updateSortOrder', order)
        this.$store.commit('pagination/updateRequestedPage', 1)
        eventHub.$emit('getNewItems')
      }      
    }
  }  
</script>
