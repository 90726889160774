import { eventHub } from '../ibat.js'

export const storePagination = {
  namespaced: true,

  state: {
    currentPage: 1,
    requestedPage: 1,
    searchTerm: '',
    sortField: 'created_at',
    sortOrder: 'DESC',
    items: [],
    selectedItems: [],
    itemIds: [],
    selectedFilterOptions: {}, // an object containing an array for each filter.
  },

  actions: {
    resetPagination({ commit }) {
      commit('updateCurrentPage', 1)
      commit('updateRequestedPage', 1)
      commit('updateSearchTerm', '')
      commit('updateSortField', 'created_at')
      commit('updateSortOrder', 'DESC')
      commit('updateItems', [])
      commit('updateAllSelectedItems', false)
    },

    updateFilterParameters({ commit }, filterOptions) {
      commit('updateFilterOptions', filterOptions)
    }
  },

  mutations: {
    updateCurrentPage (state, page) {
      this.state.pagination.currentPage = page
    },

    updateRequestedPage (state, page) {
      this.state.pagination.requestedPage = page
    },

    updateSearchTerm (state, searchTerm) {
      this.state.pagination.searchTerm = searchTerm
    },

    updateSortField (state, sortField) {
      this.state.pagination.sortField = sortField
    },

    updateSortOrder (state, sortOrder) {
      this.state.pagination.sortOrder = sortOrder
    },

    updateItems (state, items) {
      this.state.pagination.items = items

      eventHub.$emit('itemsUpdated')
    },

    addSelectedItem (state, item) {
      if(item.add) {
        if(this.state.pagination.selectedItems.indexOf(item.id) === -1) {
        this.state.pagination.selectedItems.push(item.id)
        }
      } else {
        if(this.state.pagination.selectedItems.indexOf(item.id) !== -1) {
          const index = this.state.pagination.selectedItems.indexOf(item.id)
          this.state.pagination.selectedItems.splice(index, 1)
        }
      }
    },

    updateAllSelectedItems (state, selected) {
      if(selected) {
        this.state.pagination.selectedItems = this.state.pagination.itemIds
      } else {
        this.state.pagination.selectedItems = []
      }
    },

    updateItemIds (state, id) {
      this.state.pagination.itemIds.push(id)
    },

    resetItemIds (state) {
      this.state.pagination.itemIds = []
    },

    setFilterOptions (state, options) {
      this.state.pagination.selectedFilterOptions = options
    },

    updateFilterOptions (state, newOptions) {
      this.state.pagination.selectedFilterOptions[newOptions.filter] = newOptions.options;
    },

    clearFilterOptions () {
      this.state.pagination.selectedFilterOptions = {};
    },

    removeFilterOption (state, removeOption) {
      const filteredOptions = this.state.pagination.selectedFilterOptions[removeOption.name]
        .filter(opt => opt !== removeOption.option);

      this.state.pagination.selectedFilterOptions[removeOption.name] = filteredOptions;
    }
  }
}
