<template>
  <div id="flash_container" class="flash-message" :class="[{ 'active': isActive }, messageType]">
    {{ message }}
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  import FlashProvider from '../../utilities/flash-provider.js'

  export default {
    data () {
      return {
        message: '',
        show: false
      }
    },

    props: {
      alert: String,
      notice: String,
      timeout: {
        type: Number,
        default: FlashProvider.DEFAULT_TIMEOUT
      }
    },

    data () {
      return {
        message: '',
        isActive: false,
        messageType: '',

        // used to prevent pre-mature hiding of the flash message
        timeoutId: undefined
      }
    },

    created () {
      eventHub.$on('showFlashMessage', this.ajaxMessage)
    },

    mounted () {
      if(this.alert){
        this.showMessage(this.alert, this.timeout)
        this.messageType = 'flash-message--alert'
      } else if(this.notice){
        this.showMessage(this.notice, this.timeout)
        this.messageType = 'flash-message--notice'
      }
    },

    methods: {
      ajaxMessage (messageObj) {
        // reset the hide timeout if another flash message comes in
        clearTimeout(this.timeoutId)

        this.messageType = `flash-message--${messageObj.type}`
        this.showMessage(messageObj.message, messageObj.timeout)
      },

      showMessage (message, timeout) {
        this.message = message
        this.isActive = true

        // hide the flash message after a set time, but remember it so we can cancel if we need to
        this.timeoutId = setTimeout(this.hide, timeout)
      },

      hide () {
        this.isActive = false
        setTimeout(function() { this.message = '' }, 1000)
      }
    }
  }
</script>

<style lang="scss">
  .flash-message {
    background-color: white;
    padding: 8px;
    text-align: center;

    position: fixed;
    top: -100px;
    right: 0;
    left: 0;

    transition: top 1s ease-out;

    &--alert { background-color: rgba(#f7c3c3, .8); }
    &--notice { background-color: rgba(#c6f7c3, .8); }

    &.active {
      top: 0;
      z-index: 9999;
    }
  }
</style>
