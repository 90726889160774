<template>
  <div>
    <form @submit.prevent="loginAttempt">
      <div>
        <div class="form__field">
          <label for='email'>Email Address</label>
          <input v-model="loginParams.email" id='email' type='email' autofocus='autofocus' autocomplete='email'>
        </div>

        <div class="form__field">
          <label for='password'>Password</label>
          <input v-model="loginParams.password" id='password' type='password' autocomplete='off'>
        </div>

      </div>

      <div class="actions center">
        <button class='button--cta button-wrapper'>Log in</button>
        <p>
          <a href='/users/password/new'>Forgot your password?</a>
        </p>
        <p>
          <a href='/users/unlock/new'>Didn't receive unlock instructions?</a>
        </p>
      </div>
    </form>

    <modal :modal-id="modalId" modal-classes="">
      <div class="full-height">
        <div class="form--2fa-token flex flex-h-center flex-v-center bg-grey-xlight">
          <div class="form__content">
            <h1 class='center heading--simple'>Two-factor Authentication</h1>
            <div v-if="backup">
              <h2 class="center">Enter one of your unused backup codes</h2>

              <form @submit.prevent="login">
                <div class="form__field">
                  <label for='otp_attempt'>Enter code</label>
                  <input v-model="loginParams.otp_attempt" id='otp_attempt' type='password' autofocus>
                </div>

                <div class="actions flex flex-v-center flex-column">
                  <button class="button--cta button-wrapper" type="submit">Login</button>
                  <a href='#' title='Cancel' @click.prevent="closeModal">Cancel</a>
                </div>
              </form>

            </div>
            <div v-else>
              <h2 class='center'>Enter a verification code from your Authenticator app to continue.</h2>

              <form @submit.prevent="login">
                <div class="form__field">
                  <label for='otp_attempt'>Passcode</label>
                  <input ref="wtf" v-model="loginParams.otp_attempt" id='otp_attempt' type='password' autofocus>
                </div>

                <div class="actions center">
                  <button class="button--cta button-wrapper" type="submit">Login</button>
                  <p class="no-margin--bottom">Having trouble?</p>
                  <p><a @click.prevent="useBackupCode" href='#'>Use a backup code</a></p>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import axios from 'axios'
import Modal from '../modal/Modal'
import ModalTrigger from '../modal/ModalTrigger'

import FlashProvider from '../../utilities/flash-provider.js'
import { eventHub } from '../../ibat.js'

export default {
  name: 'login-form',

  components: { Modal, ModalTrigger },

  props: {
  },

  data() {
    return {
      modalId: 'modal--login-otp',
      backup: false,
      loginParams: {
        email: '',
        password: '',
        otp_attempt: ''
      }
    }
  },

  created() {
    const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content');
    axios.defaults.headers.common['X-CSRF-Token'] = csrf;
    axios.defaults.headers.common['Accept'] = 'application/json';
  },

  methods: {
    loginAttempt() {
      axios.get('/users/is_otp_required.json?ajax=true', { params: this.loginParams })
        .then(res => {
          if (res.data.otp_required) {
            eventHub.$emit('requestModalOpen', this.modalId);
            this.focusOtpInput()
          } else {
            this.login();
          }
        })
        .catch(error => { FlashProvider.display(error.response.data.message, 'alert') })
    },

    login() {
      axios.post('/users/sign_in.json?ajax=true', { user: this.loginParams }).
        then(res => { window.location = '/sites' }).
        catch(error => { FlashProvider.display(error.response.data.error, 'alert') } )
    },

    useBackupCode() {
      this.loginParams.otp_attempt = ''
      this.backup = true
      this.focusOtpInput();
    },

    closeModal() {
      this.backup = false
      this.loginParams.otp_attempt = ''
      eventHub.$emit('requestModalClose', this.modalId)
    },

    focusOtpInput() {
      setTimeout(() => { document.getElementById('otp_attempt').focus() }, 50);
    }
  }
}
</script>
