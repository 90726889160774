<template>
  <div>
    <modal :modal-id="modalId" modal-classes="modal--data-download-custom">
      <div class="full-height flex flex-h-center flex-v-center">
        <div>
          <h1>Custom GIS download</h1>
          <p class="modal__intro margin-space-top">
            An email will be sent to you when your GIS download is ready. You
            can also see all of your GIS downloads by viewing the 'GIS
            Downloads' tab from the dashboard. If you have any queries on this,
            please contact the IBAT team at
            <a title="Email IBAT team" href="mailto:ibat@ibat-alliance.org"
              >ibat@ibat-alliance.org</a
            >.
          </p>

          <button
            class="button--cta margin-space-right"
            v-on:click="onClickDownload"
          >
            Confirm download
          </button>
          <button class="button--underline" v-on:click="closeConfirmModal">
            Cancel
          </button>
        </div>
      </div>
    </modal>

    <h2>Custom GIS download</h2>
    <p>
      GIS downloads are included in some IBAT subscriptions but can also be paid
      for on a PAYG basis. If you are a PAYG user, please select your polygon
      and the area and cost will automatically be calculated.
    </p>

    <p>To download data for a specific area:</p>

    <ol class="data-download-steps">
      <li>Draw your geometry</li>
      <li>Name your geometry</li>
      <li>Click 'Download'</li>
    </ol>

    <div :class="{ 'form__field--disabled': isDrawing }">
      <label for="data-site">Site</label>
      <div class="relative">
        <select
          :class="{ 'no-arrow': selectedSite }"
          @change="updateMap($event.target.value)"
          v-bind="{ disabled: isDrawing }"
        >
          <option value="" disabled v-bind="{ selected: !selectedSite }">
            Please select
          </option>
          <option
            v-for="site in sites"
            :key="site.id"
            :value="site.id"
            v-bind="{ selected: selectedSite && selectedSite.id === site.id }"
          >
            {{ site.title }}
          </option>
        </select>
        <button
          @click="resetDataDownload"
          v-if="selectedSite"
          class="form__clear button--plain"
        ></button>
      </div>
    </div>

    <div :class="{ 'form__field--disabled': disableName }">
      <label for="data-name">Data name</label>
      <text-input
        name="data-name"
        id="data-name"
        :showValidationError="!isValidName"
        :is-disabled="disableName"
        v-model="dataName"
      >
      </text-input>
    </div>

    <div :class="{ 'form__field--disabled': isDrawing }">
      <label for="data-dataset">Dataset</label>
      <div class="relative">
        <select v-model="selectedDataset">
          <option
            v-for="dataset in datasets"
            :key="dataset.title"
            :value="dataset.slug"
            v-bind="{
              selected: selectedDataset && selectedDataset === dataset.slug
            }"
          >
            {{ dataset.title }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <label>Selected area:</label>
      <p>{{ getDownloadArea.toLocaleString() }} km<sup>2</sup></p>
      <p v-if="checkDownloadAreaLimit" class="error_explanation">
        Selected area is larger than the 1,000,000 km<sup>2</sup> limit.
      </p>
    </div>

    <div>
      <div class="exclusion-wrapper">
        <label>Buffer (km)</label>
        <div class="tooltip-wrapper">
          <tooltip :text="minBufferText">
            <i class="icon-question-mark icon--tooltip"></i>
          </tooltip>
        </div>
      </div>
      <input
        type="number"
        v-model="bufferAmount"
        min="1"
        step="1"
        v-on:input="onChangeBuffer"
      />
    </div>

    <div class="exclusion-wrapper">
      <p class="flex">Require LC, DD and NT data?</p>
      <div class="tooltip-wrapper">
        <tooltip :text="tooltipText">
          <i class="icon-question-mark icon--tooltip"></i>
        </tooltip>
      </div>
    </div>

    <div class="button-wrapper">
      <modal-trigger
        modal-id="modal--data-download-confirm"
        class="button--cta"
        :class="{ 'button--disabled': disableDownload }"
        :disabled="disableDownload"
        >Download</modal-trigger
      >
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash.debounce';
import * as turf from '@turf/turf';

import mapFunctions from '../../utilities/map-functions.js';
import FlashProvider from '../../utilities/flash-provider.js';
import { eventHub } from '../../ibat.js';

import Modal from '../modal/Modal';
import ModalTrigger from '../modal/ModalTrigger';
import Tooltip from '../tooltip/Tooltip';
import TextInput from '../form_fields/TextInput';
import { mixinValidate } from '../../mixins/mixin-validate';

export default {
  mixins: [mixinValidate],

  components: {
    Modal,
    ModalTrigger,
    TextInput,
    Tooltip
  },

  props: {
    minBufferText: String,
    tooltipText: String,
    site: { type: Object, required: false },
    dataset: { type: Object, required: false },
    formFields: { type: Object, required: true }
  },

  data() {
    return {
      dataName: '',
      bufferAmount: 1,
      downloadArea: 0,
      downloadAreaLimit: 1000000,
      modalId: 'modal--data-download-confirm',
      hasFeature: false,
      selectedSite: this.site,
      selectedDataset: 'CoreDataDownload',
      hasFeature: false,
      regex: /\/|\\/
    };
  },

  mounted() {
    eventHub.$on('updateDownloadArea', this.updateDownloadArea);
    eventHub.$on('updateDownloadFeature', this.updateDownloadFeature);
    eventHub.$on('deleteDownloadFeature', this.resetDataDownload);
    eventHub.$on('modalClosed', this.resetForm);
    eventHub.$on('modalOpened', this.flyToSite);
  },

  beforeDestroy() {
    eventHub.$off('updateDownloadArea');
    eventHub.$off('updateDownloadFeature');
    eventHub.$off('deleteDownloadFeature');
    eventHub.$off('requestModalClose');
    eventHub.$off('modalOpened');
    eventHub.$off('modalClosed');
  },

  computed: {
    sites() {
      return this.formFields.sites ? this.formFields.sites : [];
    },

    datasets() {
      return this.formFields.datasets ? this.formFields.datasets : [];
    },

    disableDownload() {
      if (this.checkDownloadAreaLimit || this.invalidBuffer) {
        return true;
      }

      if (this.selectedSite) {
        return false;
      } else {
        return !this.hasFeature || this.dataName.length == 0;
      }
    },

    isPolygon() {
      const feature =
        this.selectedSite || this.$store.state.map.downloadFeature;

      return !!(feature && feature.geometry.type === 'Polygon');
    },

    isDrawing() {
      return !!this.$store.state.map.downloadFeature;
    },

    disableName() {
      return !!this.selectedSite;
    },

    getDownloadArea() {
      return Math.ceil(Number(this.downloadArea) / 1000000);
    },

    checkDownloadAreaLimit() {
      return this.getDownloadArea >= this.downloadAreaLimit;
    },

    isValidName() {
      return (
        this.isValidLength(this.dataName, 1) &&
        this.isValidString(this.dataName, this.regex)
      );
    },

    invalidBuffer() {
      return this.bufferAmount === '' || this.bufferAmount === '0';
    }
  },

  methods: {
    updateMap: debounce(function (siteId) {
      siteId = String(siteId);
      this.selectedSite = this.sites.find((site) => site.id === siteId);

      if (this.selectedSite.geometry.type == 'Polygon') {
        this.bufferAmount = 0;
      } else {
        this.bufferAmount = 1;
      }

      this.$store.dispatch('map/updateBufferAmount', this.bufferAmount);

      this.$store.dispatch('map/updateSelectedSite', this.selectedSite);
    }, 100),

    onChangeBuffer: debounce(function (event) {
      this.$store.dispatch('map/updateBufferAmount', event.target.value);
    }, 100),

    onClickDownload() {
      const csrf = document
        .querySelectorAll('meta[name="csrf-token"]')[0]
        .getAttribute('content');
      axios.defaults.headers.common['X-CSRF-Token'] = csrf;
      axios.defaults.headers.common['Accept'] = 'application/json';

      let data_download = {
        buffer: this.bufferAmount,
        total_area: this.getDownloadArea,
        custom_dataset: this.selectedDataset
      };

      if (this.isDrawing) {
        Object.assign(data_download, {
          geom: this.formatGeometry(this.$store.state.map.downloadFeature),
          geom_type: this.$store.state.map.downloadFeature.geometry.type,
          name: this.dataName
        });
      } else {
        Object.assign(data_download, { site_id: this.selectedSite.id });
      }

      axios
        .post('/data-download/download', { data_download })
        .then((response) => {
          this.closeConfirmModal();
          this.closeFormModal();
          this.dataName = '';
          eventHub.$emit('getNewItems');
          FlashProvider.display(response.data.message);
        })
        .catch((error) => {
          FlashProvider.display(error.response.data.message, 'alert');
        });
    },

    updateDownloadArea() {
      this.downloadArea = this.$store.state.map.downloadArea;
    },

    updateBuffer() {
      if (this.$store.state.map.downloadFeature.geometry.type == 'Polygon') {
        this.bufferAmount = 0;
        this.$store.dispatch('map/updateBufferAmount', this.bufferAmount);
      }
    },

    updateDownloadFeature(feature) {
      if (!feature) {
        this.hasFeature = false;
      } else {
        this.updateBuffer();
      }

      this.searchLocation = null;
      this.hasFeature = true;
    },

    geometryType(feature) {
      return feature ? feature.geometry.type : '';
    },

    formatGeometry(feature) {
      if (!feature) {
        return '';
      }

      let poly, geometry;

      if (mapFunctions.isMarker(feature)) {
        geometry = { point: mapFunctions.geomToObj(feature.geometry) };
      }

      if (mapFunctions.isPolyline(feature)) {
        poly = Object.assign([], mapFunctions.geomToObj(feature.geometry));
        geometry = { poly };
      }

      if (mapFunctions.isPolygon(feature)) {
        poly = Object.assign([], mapFunctions.geomToObj(feature.geometry));
        geometry = { poly };
      }

      return geometry;
    },

    updateFieldsWithErrors(hasPassed) {
      this.fieldsWithErrors = hasPassed
        ? this.fieldsWithErrors - 1
        : this.fieldsWithErrors + 1;
    },

    closeConfirmModal() {
      eventHub.$emit('requestModalClose', this.modalId);
    },

    closeFormModal() {
      eventHub.$emit('requestModalClose', 'create-data-download');
    },

    resetForm(modalId) {
      if (modalId === 'create-data-download') {
        this.resetDataDownload();
      }
    },

    resetDataDownload() {
      this.bufferAmount = 1;
      this.$store.dispatch('map/updateBufferAmount', this.bufferAmount);
      this.$store.dispatch('map/clearDownloadMap');

      this.searchLocation = null;
      this.hasFeature = false;
      this.selectedSite = undefined;
    },

    flyToSite(modalId) {
      if (modalId === 'create-data-download' && this.site) {
        this.updateMap(this.site.id);
      }
    }
  }
};
</script>
