<template>
  <div>
    <div class="tabs--large">
      <div class="container relative">
        <ul :class="classForPosition">
          <li v-for="child, index in children" @click="switchTab(child.id)" class="tab__trigger" :class="[{ 'tab--active' : child.isActive }, classForPadding]">
            <span class="tab__title">{{ child.title }}</span>
          </li>
        </ul>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  export default {
    name: 'page-tabs',
    props: {
      inModal: { default: false },
      centered: { type: Boolean, default: false },
      extra_padding: { type: Boolean, default: false }
    },
    data () {
      return {
        children: []
      }
    },
    created () {
      this.children = this.$children;
      if(this.inModal) {
        eventHub.$on('resetModalTabs', this.resetTabs)
      }
      this.navigateToNewLocationOnPage();
    },
    computed: {
      classForPosition() {
        return this.centered ? 'tabs__triggers--centered' : 'tabs__triggers';
      },

      classForPadding() {
        return this.extra_padding ? 'tab__resources-link' : 'tab__link';
      },
    },
    methods: {
      navigateToNewLocationOnPage() {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('tab')) { this.navigateToTab(urlParams) }
      },
      navigateToTab(urlParams) {
        setTimeout(() => {
          const targetTab = urlParams.get('tab')
          this.switchTab(targetTab)
          this.navigateToAnchor(urlParams);
        }, 400)
      },
      navigateToAnchor(urlParams) {
        const anchor = urlParams.get('anchor_id')
        const target = document.getElementById(anchor);
        const targetOffset = window.pageYOffset + target.getBoundingClientRect().top;
        window.scrollTo({ top: targetOffset, behavior: 'smooth' })
      },
      switchTab (tabId) {
        this.children.forEach(child => {
          child.isActive = child.id === tabId
        })
        eventHub.$emit('resizeMap')
      },
      resetTabs () {
        this.children.forEach((child, index) => {
          child.isActive = index === 0
        })
      }
    }
  }
</script>