<template>
  <div class="page-padding--bottom container">
    <edit-modal></edit-modal>

    <div class="flex flex-wrap section-padding">
      <div class="flex-2-fiths flex-s-whole" style="min-width: 400px;">

          <div v-if="itemDup.requiresUpdate || itemDup.updateInProgress" class="flex" style="height: 42px;">
            <tooltip v-if="itemDup.requiresUpdate" text="The data behind these statistics have recently been updated.">
              <i class="icon-alert"></i>
            </tooltip>
            <refresh-button v-if="itemDup.requiresUpdate" class="margin-space-top-small" :id="itemDup.id"></refresh-button>
            <tooltip v-if="itemDup.updateInProgress" text="Your project statistics are currently updating.">
              <loader :width="37" :percentage="itemDup.percentage_complete"></loader>
            </tooltip>
          </div>

          <div class="flex">
            <h1 class="ellipsis heading--simple--no-margin no-margin--top">{{ itemDup.name }}</h1>
            <div class="margin-space-left-small margin-space-right">
            <edit-button
              title="Edit the name of your project"
              attribute="name"
              label="Edit Site Name"
              :password-required="false"
              :on-close="(newName) => { this.itemDup.name = newName }"
              :url="`/sites/${itemDup.id}`"
              resource="site"
              size="small"
            ></edit-button>
            </div>
          </div>

        <div class="no-margin--top">
          <ul class="ul-unstyled ul-margin">
            <li>
              <span>
                <strong>Country: </strong>
                <a v-if="itemDup.country" :href="itemDup.country.countryProfileUrl">{{ itemDup.country.name }}</a>
                <span v-else>N/A</span>
              </span>
            </li>
            <li>
              <span>
                <strong>Location:</strong>
                <geo-centroid :geojson="itemDup.location"></geo-centroid>
              </span>
            </li>
            <li v-if="itemDup.location.type === 'Polygon'">
              <span><strong>Size of site: </strong>{{ itemDup.area }} km<sup>2</sup></span>
            </li>
            <li>
              <span><strong>Date created: </strong>{{ itemDup.created }}</span>
            </li>
            <li>
              <span><strong>Last updated: </strong>{{ itemDup.dataLastUpdatedAt }}</span>
            </li>
            <li>
              <span><strong>Created by: </strong>{{ itemDup.createdBy }}</span>
            </li>
            <li>
              <div class="flex flex-v-center">
                <span><strong>Site Type: </strong>{{ itemDup.site_type }}</span>
                  <edit-button
                    title="Edit site type"
                    attribute="site_type_name"
                    label="Edit Site Type"
                    :options="this.options"
                    :password-required="false"
                    :on-close="(newName) => { this.itemDup.site_type = newName }"
                    :url="`/sites/${itemDup.id}`"
                    resource="site"
                    size="small"
                  ></edit-button>
              </div>
            </li>
            <li>
              <strong v-if="itemDup.portfolios.length > 0">Portfolios: </strong>
              <span v-for="portfolio in itemDup.portfolios">
                <span class="text-fake-anchor">
                  <a :href="portfolio.portfolioUrl">{{ portfolio.name }}, </a>
                </span>
              </span>
            </li>
          </ul>
        </div>

        <div class="flex-inline flex-column margin-space-bottom">
          <modal-trigger id="create-report" modal-id="create-report" class="button--cta margin-space-bottom-small">New Report</modal-trigger>
          <modal-trigger id="create-data-download" modal-id="create-data-download" class="button--cta button-margin-bottom">New GIS Download</modal-trigger>
          <scroll-to-trigger classes="button--underline" target-id="reports">Reports & GIS Downloads <i class="button--down__icon icon-chevron-down"></i></scroll-to-trigger>
        </div>

      </div>

      <div class="flex-3-fiths flex-s-whole">
        <mapbox-simple id="map--site" :geojson="itemDup.location"></mapbox-simple>
      </div>
    </div>

      <div v-for="dataset in itemDup.datasets" class="card--dataset-stats box box-padding box-margin-bottom flex flex-wrap">
        <div class="flex-s-whole flex-2-fiths card__column-left">
          <img :src="dataset.logo" :alt="`${dataset.title} logo`" class="card__logo">
          <h2 class="heading--simple">
            <a :href="dataset.url" :title="`Visit ${dataset.title}`" target="_blank">{{ dataset.title }}</a>
          </h2>
          <p class="card__buffer light">{{ dataset.buffer }}</p>
          <p>{{ dataset.description }}</p>

          <div class="card__total-wrapper flex flex-column flex-center margin-center">
            <span :class="`card__total text-${dataset.theme}`">{{ dataset.total }}</span>
            <span class="card__type">{{ dataset.type }}</span>
          </div>

          <p class="card__smallprint">
            <span v-for="line in dataset.small_print" class="card__smallprint-line">{{ line }}</span>
          </p>
        </div>

        <div class="flex-s-whole flex-3-fiths flex flex-v-center">
          <ul class="ul-unstyled card__list">
              <li v-for="row in dataset.list" class="card__list-li">
                <p class="card__list-p no-margin flex flex-v-center">
                  <span class="card__list-total bold">{{ row.total }}</span>

                  <i v-if="row.icon_text" :class="['card__list-icon icon--redlist-medium', criticalClass(row)]">{{ row.icon_text }}</i>

                  <span class="card__list-title light">{{ row.title }}</span>

                  <tooltip class="margin-space-left" :text="row.description">
                    <i class="icon-question-mark icon--tooltip"></i>
                  </tooltip>
                </p>
              </li>
          </ul>
        </div>
      </div>

    <div id="reports">
      <tabs :in-modal="false" class="tabs--small">
        <tab id="form-1" title="Reports" :active="true" class="tab--white">
          <div class="box--no-border-top box-padding">
            <page-wrapper
              :search="false"
              :pagination="true"
              name="report"
              :source="`/sites/${itemDup.id}/reports.json`"
              http-method="get">

              <page-reports></page-reports>
            </page-wrapper>
          </div>
        </tab>

        <tab id="form-2" title="GIS Downloads" class="tab--white">
          <div class="box--no-border-top box-padding">
            <page-wrapper
              :source="`/sites/${itemDup.id}/data_downloads.json`"
              name="GIS download"
              :pagination="true"
              http-method="get"
            >
              <page-my-downloads></page-my-downloads>
            </page-wrapper>
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mixinWebsockets } from '../../mixins/mixin-websockets.js'
  import FlashProvider from '../../utilities/flash-provider.js'

  import EditButton from '../buttons/EditButton.vue'
  import GeoCentroid from '../geo/GeoCentroid.vue'
  import Loader from '../loading/Loader';
  import MapboxSimple from '../map/MapboxSimple.vue'
  import ModalTrigger from '../modal/ModalTrigger.vue'
  import PageReports from '../pagination/PageReports.vue'
  import PageMyDownloads from '../pagination/PageMyDownloads'
  import PageWrapper from '../pagination/PageWrapper.vue'
  import RefreshButton from '../buttons/RefreshButton.vue'
  import ScrollToTrigger from '../scrollTo/ScrollToTrigger.vue'
  import Tooltip from '../tooltip/Tooltip.vue'
  import Tabs from '../tabs/Tabs.vue'
  import Tab from '../tabs/Tab.vue'
  import EditModal from '../modal/EditModal.vue'
  import EditField from '../form_fields/EditField.vue'

  export default {
    name: 'show-site',

    components: {
      EditButton,
      GeoCentroid,
      Loader,
      MapboxSimple,
      ModalTrigger,
      PageMyDownloads,
      PageReports,
      PageWrapper,
      RefreshButton,
      ScrollToTrigger,
      Tab,
      Tabs,
      Tooltip,
      EditModal,
      EditField
    },

    mixins: [mixinWebsockets],

    props: {
      site: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        itemDup: this.site,
        websocketChannel: 'Site',
        updateMessage: `Site data for ${this.site.name} is updating`,
        completeMessage: `Site data for ${this.site.name} is up to date`,
        errorMessage: 'There was an error updating this site, please try again.',
        deletionMessage: "This project has been deleted, you are being returned to your site page.",
      }
    },

    mounted() {
      this.subscribeToChannel()
    },

    computed: {
      countryNotApplicable() {
        return this.itemDup.country_name === 'Not applicable';
      },

      portfolioName() {
        return this.itemDup.portfolios.map(portfolio => portfolio.name).join(', ')
      },

      options() {
        return this.site.site_types.map(site_type => { return { name: site_type.name, id: site_type.name}} );
      }
    },

    methods: {
      criticalClass(row) {
        return !row.critical ? ' icon--redlist-grey' : ''
      }
    }
  }

</script>
