<template>
  <div>
    <div class="one-time-password--toggle-wrapper">
      <checkbox @toggle="displayContent = !displayContent"></checkbox>
      <span class="margin-space-left">{{ label }}</span>
    </div>
    <slot name="main"></slot>
    <slot v-if="displayContent" name="togglable"></slot>
  </div>
</template>

<script>
import Checkbox from '../basic/Checkbox.vue';

export default {
  name: 'toggle',

  components: { Checkbox },

  props: {
    label: { required: true, type: String }
  },

  data() {
    return {
      displayContent: false
    }
  },
}
</script>

<style lang="scss">
  .one-time-password {
    &--toggle-wrapper {
      display: flex;
      align-items: center;
    }
  }
</style>
