<template>
  <div class="carousel__dots flex flex-h-center">
    <span v-for="n in pages" @click="changePage(n)" class="carousel__dot" :class="{ 'is-active': isActive(n) }"></span>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'carousel-dots',

    props: {
      activePage: {
        type: Number,
        required: true
      },
      pages: {
        type: Number,
        required: true
      }
    },

    methods: {
      isActive (index) {
        return index == this.activePage
      },

      changePage (index) {
        eventHub.$emit('requestNewPage', index)
      }
    }
  }
</script>

<style lang="scss">
  $dot-size: 20px;

  .carousel {

    &__dots {
      padding: 30px 0 14px 0;
    }

      &__dot {
        border-radius: 100%;
        cursor: pointer;
        margin: 0 6px;
        width: $dot-size; height: $dot-size;

        display: inline-block;
      }
  }
</style>