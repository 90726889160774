<template>
  <span @click.stop="toggleCheckbox" :class="{ 'active': isActive, 'checkbox--small': all }" class="checkbox">
    
  </span>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'page-checkbox',

    props: {
      id: Number,
      all: Boolean
    },

    data () {
      return {
        isActive: false,
        destroyed: false
      }
    },

    created () {
      // create an array of all the ids to use when the 'select all' checkbox
      // is triggered, looping over each checkbox to add the id is too slow

        eventHub.$on('toggle-all', this.updateCheckbox)
        eventHub.$on('resetCheckboxes', this.reset)
        // eventHub.$on('resetCheckboxIds', this.addToItemArray)

        this.addToItemArray()

      },

    beforeDestroy () {
      // adding work around because destroyed lifecycle hook is never triggered
      this.destroyed = true
    },

    methods: {
      toggleCheckbox () {
        if(this.all) {
          if(this.isActive) { 
            eventHub.$emit('toggle-all', false) 
          } else {
            eventHub.$emit('toggle-all', true)
          }
          this.$store.commit('pagination/updateAllSelectedItems', this.isActive)
        } else {
          this.isActive = !this.isActive
          this.$store.commit('pagination/addSelectedItem', { add: this.isActive, id: this.id })
        }
      },

      updateCheckbox (boolean) {
        this.isActive = boolean
      },

      addToItemArray () {
        if(!this.all && !this.destroyed){ 
          this.$store.commit('pagination/updateItemIds', this.id) 
        }
      },

      reset () {
        this.isActive = false
      }
    }
  }
</script>
