import axios from 'axios'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Accept'] = 'application/json'

/**
 * Register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached,
 * and it doesn't need to be done manually for every token.
 */

axios.interceptors.request.use(function (config) {
  const token = document.head.querySelector('meta[name="csrf-token"]')

  if (token) {
    config.headers.common['X-CSRF-Token'] = token.content
  } else {
    console.warn('CSRF token not found')
  }

  return config;
}, function (error) {
  // Do something with request error.
  return Promise.reject(error);
});

export default axios