<template>
  <div :class="[this.class, 'z-1']" @click="navigateToPage">
    <p><img :src="src">{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: { required: true, type: String },
    icon: { required: false, type: String },
    url: { required: false, type: String },
  },
  
  computed: {
    src: function() {
      return require(`images/icons/${this.icon}.svg`)
    },

    class: function() {
      return this.url ? 'dashboard-title--clickable' : 'dashboard-title'
    }
  },

  methods: {
    navigateToPage: function() {
      if (!this.url) { return }

      window.location.href = this.url
    }
  }
}
</script>
