<template>
  <div>
    <div v-if="businessDup.sectors.length > 0">
      <p class="text-grey">This organisation belongs to {{ businessDup.sectors.length }} sectors and {{ businessDup.sub_sectors.length }} sub-sectors.</p>

      <collapse-article-no-transition
        v-for="sector in businessDup.sectors"
        :is-open="openValForSector(sector)"
        :collapse-on-open="true"
        :key="generateKey(sector.id)"
        :ref="`sector${sector.id}`"
      >
        <template v-slot:title>
          <span class="bold flex flex-v-center">
            <clear-button @click.stop="removeFromSector(sector)" :title="`Remove ${business.name} from the ${sector.name} sector.`" class="margin-space-right"></clear-button>
            {{ sector.name.toUpperCase() }}
          </span>
        </template>

        <template v-slot:body>
          <div class="form--add-sectors-sub-sectors">
            <span class="display-block margin-space-bottom text-14 bold">Sub-sectors</span>

            <p v-if="subSectorsForSector(sector).length === 0">This organisation hasn't been added to any sub-sectors for this sector. Add some below.</p>

            <div v-for="subSector in subSectorsForSector(sector)" :key="generateKey(subSector.id)">
              <div class="flex margin-space-top-small margin-space-bottom-small">
                <clear-button @click="removeFromSubSector(subSector, sector)" :width="18" :height="18" :title="`Remove ${businessDup.name} from the ${subSector.name} sub-sector.`"></clear-button>
                <span class="margin-space-left">{{ subSector.name }}</span>
              </div>
            </div>

            <div v-if="subSectorsForSelect(sector).length > 0">
              <span v-if="subSectorsForSelect(sector).length > 0" class="form--add-sectors-sub-heading">Add Sub-sectors</span>

              <select id="select-sector" v-model="selectedSubSectorIds" size="5" multiple>
                <option v-for="subSector in subSectorsForSelect(sector)" :key="subSector.id" :value="subSector.id">
                  {{ subSector.name }}
                </option>
              </select>
              <div class="flex flex-h-end margin-space-top">
                <button class="button--cta-small margin-space-bottom" @click="addToSubSector(sector)">Add</button>
              </div>
            </div>
            <p v-else-if="allSubSectorsForSector(sector).length > 0" class="margin-space-top">This organisation has been added to all available sub-sectors for this sector. <a href="/admin/sectors/manage">Create some</a>.</p>
            <p v-else class="margin-space-top">No sub-sectors have been created for this sector yet. <a href="/admin/sectors/manage">Create some</a>.</p>
          </div>
        </template>
      </collapse-article-no-transition>
    </div>
    <p v-else class="text-grey">This organisation hasn't been assigned to any sectors yet. Add some below.</p>

    <div class="margin-space-top">
      <span class="form--add-sectors-sub-heading">Add Sectors</span>

      <div v-if="sectorsForSelect.length > 0">
        <select id="select-sector" v-model="selectedSectorIds" size="5" multiple>
          <option v-for="sector in sectorsForSelect" :key="sector.id" :value="sector.id">
            {{ sector.name }}
          </option>
        </select>

        <div class="flex flex-h-end button-margin-top">
          <button class="button--cta" @click="addToSector">Add</button>
        </div>
      </div>
      <p v-else-if="sectors.length > 0">This organisation has been added to all available sectors. <a href="/admin/sectors/manage">Create some</a>.</p>
      <p v-else>No sectors have been created yet. <a href="/admin/sectors/manage">Create some</a>.</p>
    </div>
  </div>
</template>

<script>
import { eventHub } from '../../ibat.js'
import helpers from '../../utilities/helpers.js'
import CollapseArticleNoTransition from '../collapse/CollapseArticleNoTransition'
import ClearButton from '../buttons/ClearButton'
import FlashProvider from '../../utilities/flash-provider.js'
import axios from 'axios'

export default {
  components: { ClearButton, CollapseArticleNoTransition },

  props: {
    business: { type: Object, required: true },
    sectors: { type: Array, required: true },
    subSectors: { type: Array, required: true },
  },

  data() {
    let data = {
      businessDup: this.business,
      selectedSectorIds: [],
      selectedSubSectorIds: [],
      articles: {

      }
    }

    data.businessDup.sectors.forEach(sector => {
      data.articles[`sector${sector.id}`] = false
    })

    return data
  },

  created() {
    const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content');
    axios.defaults.headers.common['X-CSRF-Token'] = csrf;
    axios.defaults.headers.common['Accept'] = 'application/json';
  },

  computed: {
    sectorsForSelect() {
      return this.sectors.filter(s => {
        return !this.businessDup.sectors.map(bs => bs.id).includes(s.id)
      })
    },
  },

  methods: {
    pinOpen(sector) {
      Object.keys(this.articles).forEach(key => {
        if (key === `sector${sector.id}`) {
          this.articles[`sector${sector.id}`] = true
        } else {
          this.articles[key] = false
        }
      })
    },

    openValForSector(sector) {
      return this.articles[`sector${sector.id}`]
    },

    subSectorsForSelect(sector) {
      return this.allSubSectorsForSector(sector).filter(ss => {
        return !this.businessDup.sub_sectors.map(zz => zz.id).includes(ss.id)
      })
    },

    generateKey(num) {
      return `${helpers.randomNumber()}${num}`
    },

    allSubSectorsForSector(sector) {
      return this.subSectors.filter(ss => ss.sector_id === sector.id)
    },

    subSectorsForSector(sector) {
      return this.businessDup.sub_sectors.filter(ss => ss.sector_id === sector.id)
    },

    addToSector() {
      if (this.selectedSectorIds.length > 0) {
        const business = { sector_ids: this.selectedSectorIds }

        axios.put(`/admin/business/${this.business.id}/add_sectors`, { business })
          .then(res => {
            this.selectedSectorIds.forEach(sectorId => {
              this.articles[`sector${sectorId}`] = false
            })

            this.businessDup = res.data.business
            FlashProvider.display(res.data.message)
          })
          .catch(err => {
            FlashProvider.display(err.response.data.message, 'alert')
          })
      }
    },

    removeFromSector(sector) {
      const hasConfirmed = confirm(`Are you sure you want to remove ${this.business.name} from the ${sector.name} sector?`)
      
      if (hasConfirmed) {
        axios.post(`/admin/business/${this.business.id}/remove_sector`, { sector_id: sector.id })
          .then(res => {
            this.pinOpen(sector)
            this.businessDup = res.data.business
            FlashProvider.display(res.data.message)
          })
          .catch(err => {
            FlashProvider.display(err.response.data.message, 'alert')
          })
      }
    },

    addToSubSector(sector) {
      if (this.selectedSubSectorIds.length > 0) {
        const business = { sub_sector_ids: this.selectedSubSectorIds }

        axios.put(`/admin/business/${this.business.id}/add_sub_sectors`, { business })
          .then(res => {
            this.pinOpen(sector)
            this.businessDup = res.data.business
            FlashProvider.display(res.data.message)
          })
          .catch(err => {
            FlashProvider.display(err.response.data.message, 'alert')
          })
      }
    },

    removeFromSubSector(subSector, sector) {
      const hasConfirmed = confirm(`Are you sure you want to remove ${this.business.name} from the ${subSector.name} sub-sector?`)
      
      if (hasConfirmed) {
        axios.post(`/admin/business/${this.business.id}/remove_sub_sector`, { sub_sector_id: subSector.id })
          .then(res => {
            this.pinOpen(sector)
            this.businessDup = res.data.business
            FlashProvider.display(res.data.message)
          })
          .catch(err => {
            FlashProvider.display(err.response.data.message, 'alert')
          })
      }
    }
  }
}
</script>
