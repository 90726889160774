<template>
  <div class="filters__container">
    <page-search v-show="!showDelete"></page-search>
    <div class="filters__wrapper">

      <page-filter
        v-for="(filter, filterIndex) in filters"
        :key="filterIndex"
        :name="filter.name"
        :title="filter.title"
        :options="filter.options"
        :type="filter.type"
        :selectMultiple="filter.selectMultiple"
      >
      </page-filter>
    </div>

    <button
      @click="resetFilters"
      class="filters__clear button--plain__filters"
    >Clear all</button>
  </div>
</template>

<script>
import { eventHub } from "../../ibat.js"
import PageFilter from './PageFilter.vue'
import PageSearch from './PageSearch'

export default {
  name: 'page-filters',

  components: { PageFilter, PageSearch },

  props: {
    filters: {
      type: Array
    }
  },

  data () {
    return {
      children: this.$children,
    }
  },

  created () {
    this.createSelectedFilterOptions();
  },

  mounted () {
    eventHub.$on('clickDropdown', this.updateDropdowns)
  },

  computed: {
    showDelete () {
      const selectedItems = this.$store.state.pagination.selectedItems
      return selectedItems.length > 0
    }
  },

  methods: {
    updateDropdowns (name) {
      this.children.forEach(filter => {
        if (filter.name == name) {
          filter.isOpen = true
        } else if (filter.name !== 'search') {
          filter.closeSelect()
        }
      })
    },

    createSelectedFilterOptions () {
      const preservingFilters = this.$store.state.preserveFilters && (Object.keys(this.$store.state.pagination.selectedFilterOptions).length > 0)

      if (!preservingFilters) {
        const filterObj = this.filters.reduce((obj, filter) => {
          if (this.shouldCreateFilter(filter)) { obj[filter.name] = []; };

          return obj
        }, {});

        this.$store.commit('pagination/setFilterOptions', filterObj)
      }
    },

    resetFilters () {
      this.children.forEach(filter => filter.reset(false));

      eventHub.$emit('getNewItems');
    },

    shouldCreateFilter (filter) {
      return (filter.name !== undefined && filter.options !== undefined && filter.options.length > 0) || (filter.name === 'date')
    }
  }
}
</script>
