<template>
  <div>
    <ul :class="ulClass">
      <li v-for="child, index in children" @click="click(child)" class="tab__trigger tab__link" :class="{ 'tab--active' : child.isActive }">
        <span class="tab__title">{{ child.title }}</span>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'tabs',

    props: {
      inModal: { default: false },
      ulWidth: {
        type: String,
        default: ''
      }
    },

    data () {
      return {
        children: []
      }
    },

    created () {
      this.children = this.$children

      if(this.inModal) {
        eventHub.$on('resetModalTabs', this.resetTabs)
      }
    },

    computed: {
      ulClass() {
        let widthClass = ''
        if(this.ulWidth.length > 0) {
          widthClass = `--${this.ulWidth}`
        }
        return `tabs__triggers${widthClass} ul-inline`
      }
    },

    methods: {
      click (selectedChild) {
        this.children.forEach(child => {
          child.isActive = child.id === selectedChild.id
        })

        this.$emit('changeTab');

        if (selectedChild.title === 'Upload') {
          const lineControl = '.mapbox-gl-draw_line'
          const polyControl = '.mapbox-gl-draw_polygon'
          const pointControl = '.mapbox-gl-draw_point'

          const controls = [lineControl, polyControl, pointControl]

          controls.forEach((control) => {
            const element = document.querySelector(control)

            if (element) {
              element.classList.add("controls--disabled")
            }
          })
        }
      },

      resetTabs () {
        this.children.forEach((child, index) => {
          child.isActive = index === 0
        })
      }
    }
  }
</script>
