<template>
  <div>
    <enable-two-factor
      :qr-code="qrCode"
      @otpstatuschange="toggleOtpStatus">
    </enable-two-factor>

    <disable-two-factor @otpstatuschange="toggleOtpStatus"></disable-two-factor>

    <div class="margin-space-top padding-top flex flex-h-between flex-v-center solid-border-top">
      <h2 class="bold no-margin">Two-factor authentication</h2>
      <modal-trigger :modal-id="modalIdForOtpStatus" class="button--cta-small">{{ buttonText }}</modal-trigger>
    </div>
  </div>
</template>

<script>
import ModalTrigger from '../modal/ModalTrigger.vue'
import EnableTwoFactor from './EnableTwoFactor.vue';
import DisableTwoFactor from './DisableTwoFactor.vue';

export default {
  name: 'two-factor-auth',

  components: { ModalTrigger, EnableTwoFactor, DisableTwoFactor },

  props: {
    qrCode: { required: true, type: String }
  },

  data() {
    return {
      otpRequiredForLogin: this.$store.getters.user.otpRequiredForLogin,
      password: ''
    }
  },

  computed: {
    buttonText() {
      return this.otpRequiredForLogin ? 'Deactivate' : 'Activate';
    },

    modalIdForOtpStatus() {
      return this.otpRequiredForLogin ? 'disable-two-factor' : 'enable-two-factor';
    }
  },

  methods: {
    toggleOtpStatus() {
      this.otpRequiredForLogin = !this.otpRequiredForLogin;
    }
  }
}
</script>
