<template>
  <modal :closeable="closeabilityForStep" modal-id="enable-two-factor" modal-classes="modal--two-factor-auth">
    <div class="two-factor--container">
      <h1 class="two-factor--header heading--simple">Two-factor Authentication</h1>

      <progress-bar :number-of-steps="numberOfSteps" :active-step="currentStep"></progress-bar>

      <h3 class="two-factor--step-header">{{ titleForStep }}</h3>

      <div v-if="currentStep === firstStep">
        <p class="two-factor--content">Google Authenticator is a free two-factor authentication app available on the majority of devices. Download it to your mobile device by searching for "Google Authenticator on Google Play of the App Store."</p>

        <div class="two-factor--download">
          <a href="https://www.apple.com/uk/ios/app-store/" target="_blank">Apple Store <img :src="require(`images/icons/external-link.svg`)" width="12"></a>
          <vertical-line :height="26"></vertical-line>
          <a href="https://play.google.com/store" target="_blank">Google Play <img :src="require(`images/icons/external-link.svg`)" width="12"></a>
        </div>
      </div>

      <div v-if="currentStep === qrCodeStep">
        <p class="two-factor--content">Using the two-factor app please scan the barcode below."</p>

        <div class="flex flex-h-center padding-top" v-html="qrCode"></div>

        <p class="two-factor--content">To enable two-factor authentication enter the 6-digit token from your two-factor app along with your current password.</p>

        <div class="two-factor--input">
          <div class="form__field">
            <label class="text-14" for="verification-code">Verification Code</label>
            <input v-model="otpParams.otp" id="verification-code" type="text">
          </div>

          <div class="form__field">
            <label class="text-14" for="current-password">Current Password</label>
            <input v-model="otpParams.password" id="current-password" type="password">
          </div>
        </div>
      </div>

      <div v-if="currentStep === backupCodesStep">
        <p class="two-factor--content">You'll need these backup codes if you ever lose your device. Without your device or a backup code you'll have to contact IBAT to recover your account.</p>
        
        <div class="two-factor--content">
          <div class="two-factor--backup-codes--container">
            <span class="two-factor--backup-codes--code" v-for="code in backupCodes" :key="code">{{ code }}</span>
          </div>
        </div>

        <div class="two-factor--content">
          <a :href="backupCodesFile" download="backup_codes.txt" target="_blank">Download backup codes</a>
        </div>
      </div>

      <div v-if="currentStep === completionStep">
        <p class="two-factor--content">Two-factor authentication is now activated.</p>
      </div>

      <div class="two-factor--buttons">
        <button v-if="isReversibleStep" @click="previousStep" class="button--cta margin-space-right">Back</button>
        <button @click="moveToNextStep" :class="['button--cta', { 'button--disabled': disabledStatusForStep }]" :disabled="disabledStatusForStep">{{ buttonTextForStep }}</button>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from 'axios'

import FlashProvider from '../../utilities/flash-provider.js'
import { eventHub } from '../../ibat.js'

import Modal from '../modal/Modal.vue'
import ProgressBar from '../progress/ProgressBar.vue'
import VerticalLine from '../objects/VerticalLine.vue'

export default {
  name: 'enable-two-factor',

  components: { Modal, ProgressBar, VerticalLine },

  props: {
    qrCode: { required: true, type: String }
  },

  data() {
    return {
      numberOfSteps: 3,
      currentStep: 1,
      firstStep: 1,
      qrCodeStep: 2,
      backupCodesStep: 3,
      completionStep: 4,
      steps: {
        '1': {
          title: 'Step 1 - Download',
          stepComplete: this.nextStep,
          isReversible: false,
          isCloseable: true
        },
        '2': {
          title: 'Step 2 - Scan & Verify',
          stepComplete: this.completeStepTwo,
          isReversible: true,
          isCloseable: true
        },
        '3': {
          title: 'Step 3 - Backup Verification Codes',
          stepComplete: this.nextStep,
          isReversible: false,
          isCloseable: false
        },
        '4': {
          title: 'Complete',
          stepComplete: this.complete,
          isReversible: false,
          isCloseable: false
        }
      },
      backupCodes: [],
      otpParams: {
        password: '',
        otp: ''
      }
    }
  },

  created () {
    eventHub.$on('modalClosed', this.resetSteps)
    const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
    axios.defaults.headers.common['X-CSRF-Token'] = csrf
    axios.defaults.headers.common['Accept'] = 'application/json'
  },

  computed: {
    currentStepOpts() {
      return this.steps[this.currentStep];
    },

    titleForStep() {
      return this.currentStepOpts.title;
    },

    functionForStep() {
      return this.currentStepOpts.stepComplete;
    },

    isReversibleStep() {
      return this.currentStepOpts.isReversible;
    },

    disabledStatusForStep() {
      if (this.currentStep === this.qrCodeStep && !this.formComplete) {
        return true;
      } else {
        return false;
      }
    },

    closeabilityForStep() {
      return this.currentStepOpts.isCloseable;
    },

    buttonTextForStep() {
      return this.currentStep < this.completionStep ? 'Next' : 'Complete';
    },

    formComplete() {
      return !!this.otpParams.password && !!this.otpParams.otp
    },

    user () {
      return this.$store.getters.user
    },

    backupCodesFile() {
      return 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.backupCodes.join('\r'))
    }
  },

  methods: {
    moveToNextStep() {
      this.functionForStep();
    },

    completeStepTwo() {
      if (this.formComplete) {
        this.enableOtp();
      } else {
        FlashProvider.display('Please fill in the required fields', 'alert');
      }
    },

    enableOtp() {
      axios.get('enable_otp', { params: this.otpParams })
        .then(res => {
          this.backupCodes = res.data.backup_codes;
          this.nextStep();
        })
        .catch(error => {
          FlashProvider.display(error.response.data.message, 'alert')
        })
    },

    previousStep() {
      if (this.isReversibleStep) {
        this.currentStep--;
        this.resetForm();
      };
    },

    nextStep() {
      this.currentStep++
    },

    resetSteps(modalId) {
      if (modalId === 'enable-two-factor') { this.reset(); };
    },

    reset() {
      this.resetForm();
      this.currentStep = this.firstStep;
    },

    resetForm() {
      this.otpParams = { password: '', otp: '' };
      this.backupCodes = [];
    },

    complete() {
      FlashProvider.display('Two-factor authentication is now enabled for your account')
      eventHub.$emit('requestModalClose', 'enable-two-factor');
      this.$emit('otpstatuschange')
      this.reset();
    }
  }
}
</script>
