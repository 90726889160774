<template>
  <div class="modal-container active">
    <div class="modal-wrapper flex-h-center active">
      <div :class="['modal active', modalClass]">
        <div class="modal__content">
          <button v-if="closeable" class="button--close modal__close icon-close" @click="$emit('close')"></button>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeable: { type: Boolean, default: false },
    size: { type: String, default: 'small' }
  },

  computed: {
    modalClass() {
      return `modal--local-${this.size}`
    }
  }
}
</script>

<style lang="scss">
  .modal-container {
    display: none;
    position: absolute;
    top: -1000px;

    &.active {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2000;
    }
  }

  .modal-wrapper {
    background-color: rgba(black, .3);

    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
</style>
