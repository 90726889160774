<template>
  <img
    @click="click"
    :width="width"
    :height="height"
    :src="require('images/icons/clear.svg')"
    :class="computedClass"
  >
</template>

<script>
export default {
  props: {
    width: { default: 20 },
    height: { default: 20 },
    position: { type: String, default: '' }
  },

  computed: {
    computedPosition() {
      return this.position ? `-${this.position}` : ''
    },

    computedClass() {
      return `button--clear${this.computedPosition}`
    }
  },

  methods: {
    click(event) {
      this.$emit('click', event)
    }
  }
}
</script>
