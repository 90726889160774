import FlashProvider from '../utilities/flash-provider.js';

export const mixinWebsockets = {

  data() {
    return {
      cable: App.cable,
      subscription: undefined
    }
  },

  destroyed() {
    if (this.subscription) { this.subscription.unsubscribe(); };
    this.cable.disconnect();
  },

  computed: {
    channel() {
      return `ApplicationCable::${this.websocketChannel}Channel`;
    }
  },

  methods: {
    subscribeToChannel() {
      this.subscription = App.cable.subscriptions.create(
        {
          channel: this.channel,
          id: this.itemDup.id
        },
        {
          connected: this.onConnected,
          received: this.onReceived,
          disconnected: this.onDisconnected,
          rejected: this.onRejected,
        }
      )
    },

    // This is the default functionality but can be
    // overwritten in the including file
    onConnected(data) {},

    onReceived(data) {
      if (data.message) {
        FlashProvider.display(data.message)
      } else if (data.alert) {
        FlashProvider.display(data.alert, 'alert')
      }

      this.itemDup = data.resource
    },

    onRejected(data) {},

    onDisconnected(data) {}
  }
}
