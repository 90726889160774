<template>
  <li class="list-item--wrapper list-item--clickable" @click="goToSite">
    <div class="list-item--attr-flex-2">
      <page-checkbox class="margin-space-left" v-if="canDelete" :id="itemDup.id"></page-checkbox>
    </div>
    <div class="list-item--attr-flex-6 list-item--desktop-only ellipsis">
      <span :title="itemDup.name" class="margin-space-right ellipsis">{{ itemDup.name }}</span>
    </div>
    <div class="list-item--attr-flex-3 list-item--desktop-only"><span>{{ itemDup.pa }}</span></div>
    <div class="list-item--attr-flex-3 list-item--desktop-only"><span>{{ itemDup.redList }}</span></div>
    <div class="list-item--attr-flex-3 list-item--desktop-only"><span>{{ itemDup.kba }}</span></div>
    <div class="list-item--attr-flex-4 list-item--desktop-only ellipsis">
      <span v-if="itemDup.country" @click.stop="goToCountry" class="text-fake-anchor margin-space-right ellipsis" :title="itemDup.country.name">{{ itemDup.country.name }}</span>
      <span v-else class="margin-space-right" title="N/A">N/A</span>
    </div>
    <div v-if="!isPortfolio" class="list-item--attr-flex-3 list-item--desktop-only ellipsis">
      <span v-if="hasPortfolios" @click.stop="goToPortfolio" class="text-fake-anchor margin-space-right ellipsis" :title="portfolioName">{{ portfolioName }}</span>
      <span v-else class="margin-space-right" title="N/A">N/A</span>
    </div>
    <div :title="itemDup.site_type" class="list-item--attr-flex-4 list-item--desktop-only ellipsis"><span class="margin-space-right ellipsis">{{ itemDup.site_type }}</span></div>
    <div v-if="user.businessOwnerOrAdmin" class="list-item--attr-flex-4 list-item--desktop-only ellipsis"><span :title="itemDup.createdBy" class="ellipsis margin-space-right">{{ itemDup.createdBy }}</span></div>
    <div class="list-item--attr-flex-3 list-item--desktop-only"><span>{{ itemDup.created }}</span></div>
    <div class="list-item--attr-flex-1 list-item--desktop-only flex-h-center">
      <tooltip v-if="itemDup.requiresUpdate || itemDup.updateInProgress"
        class="tooltip--closer"
        :text="popoverTextForSiteStatus()">
        <i v-if="itemDup.requiresUpdate" class="icon-alert"></i>
        <loader v-else :width="37" :percentage="itemDup.percentage_complete"></loader>
      </tooltip>
    </div>
    <div class="list-item--attr-flex-1 list-item--desktop-only flex-h-center">
      <refresh-button v-if="itemDup.requiresUpdate" 
        :id="itemDup.id"
      ></refresh-button>
    </div>
    <div class="list-item--attr-flex-1 list-item--desktop-only">
      <i class="ul__icon icon-chevron-right flex-hs-end"></i>
    </div>
  </li>
</template>

<script>
  import axios from 'axios'
  import { eventHub } from '../../ibat.js'
  import { mixinLoggedIn } from '../../mixins/mixin-logged-in.js'
  import { mixinWebsockets } from '../../mixins/mixin-websockets.js'

  import Loader from '../loading/Loader.vue';
  import PageCheckbox from './PageCheckbox.vue'
  import RefreshButton from '../buttons/RefreshButton.vue'
  import Tooltip from '../tooltip/Tooltip.vue'

  export default {
    name: 'page-item-site',

    components: { Loader, PageCheckbox, Tooltip, RefreshButton },

    mixins: [ mixinLoggedIn, mixinWebsockets ],

    props: {
      site: { required: true, type: Object },
      canDelete: { type: Boolean, default: false },
      isPortfolio: { type: Boolean, default: false }
    },

    data() {
      return {
        itemDup: this.site,
        websocketChannel: 'Site'
      }
    },

    mounted() {
      this.subscribeToChannel()
    },

    computed: {
      portfolioName() {
        return this.itemDup.portfolios.map(portfolio => portfolio.name).join(', ')
      },

      hasPortfolios() {
        return this.itemDup.portfolios.length > 0
      }
    },

    methods: {
      goToSite() {
        if (this.isPortfolio) {
          window.open(this.itemDup.siteUrl);
        } else {
          window.location.href = this.itemDup.siteUrl;
        }
      },

      goToCountry() {
        window.location.href = this.itemDup.country.countryProfileUrl;
      },

      goToPortfolio() {
        window.location.href = this.itemDup.portfolios[0].portfolioUrl;
      },

      classForSiteStatus() {
        return this.itemDup.requiresUpdate ? 'alert' : 'spinner-of-dots';
      },

      popoverTextForSiteStatus() {
        if (this.itemDup.updateInProgress) {
          return "Your project statistics are currently updating."
        } else if (this.itemDup.requiresUpdate) {
          return "The data behind these statistics have recently been updated."
        }
      }
    }
  }
</script>
