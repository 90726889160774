<template>
  <div :id="id"></div>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'PieChart',
  props: {
    colours: Object,
    data: {
      type: Object, // { primary: { count: Number, percentage: Number, colour: String }, secondary: {...}}
      required: true
    },
    dimensions: {
      type: Object, // { width: Number, height: Number, margin: Number }
      default: () => ({})
    },
    id: {
      type: String, // Type of chart to be outputted
      required: true
    }
  },
  mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      const data = this.data

      const { width, height, margin } = this.dimensions
      const primaryColor = this.colours.primaryColour
      const secondaryColor = this.colours.secondaryColour
      const id = '#' + this.id

      const radius = Math.min(width, height) / 2 - margin

      // append the svg object to the div with the passed id
      const svg = d3.select(id)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")

      // set the color scale
      const color = d3.scaleOrdinal()
        .domain(['primary', 'secondary'])
        .range([primaryColor, secondaryColor])

      // Compute the position of each group on the pie:
      const pie = d3.pie()
        .value(function (d) { return d.value.percentage })
        .sort(null)

      const data_ready = pie(d3.entries(data))

      const outerArc = d3.arc()
        .innerRadius(0)
        .outerRadius((d) => d.data.key == 'primary' ? radius * 1.05 : radius)

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll('whatever')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', outerArc)
        .attr('fill', (d) => color(d.data.key))
        .attr("stroke", "white")
        .style("stroke-width", "2px")
        .style("opacity", 1)

      const arcForLabels = d3.arc()
        .innerRadius(radius)
        .outerRadius(radius * 1.4)

      svg
        .selectAll('mySlices')
        .data(data_ready)
        .enter()
        .append('text')
        .text((d) => {
          if (id === '#wdpa' || id === '#kba') {
            return d.data.value.count
          } else {
            return d.data.value.label + ' ' + d.data.value.percentage + '%'
          }
        })
        .attr("transform", function (d) { return "translate(" + arcForLabels.centroid(d) + ")" })
        .style("text-anchor", "middle")
        .style("font-size", 12)
    }
  },
}
</script>