export default {
  methods: {
    /*
     * Send request to get a "g-recaptcha-response" token which should be added to a request for validation. 
     *
     * The callback should be in the format: function (token) {} and within the callback, you should do
     * whatever you then need to do to setup a request, e.g. merge { 'g-recaptcha-response': token } to
     * the outgoing request.
     */
    recaptcha ({ action, siteKey }, callback) {
      // The siteKey can be specified here dynamically; by default it will look in the document head.
      siteKey = siteKey || document.head.querySelector('meta[name="google-recaptcha-v3-site-key"]').content

      window.grecaptcha.ready(() => {
        // Once the token has been received, it will be passed into the callback function.
        window.grecaptcha.execute(siteKey, { action }).then(callback)
      })
    }
  }
}
