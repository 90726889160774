<template>
  <div>
    <p class="form__validation-message" v-show="showValidationError">Please select between 1 and 3 options</p>

    <select multiple="multiple" v-model="selectedOptions" @change="changeInput" size="5">
      <option v-for="option in options" :value="option.id" :key="option.id">{{ option.title }}</option>
    </select>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'multi-select',

    props: {
      options: { required: true },
      showValidationError: { required: true }
    },

    data() {
      return {
        selectedOptions: []
      }
    },

    methods: {
      changeInput() {
        this.$emit('input', this.selectedOptions)
      }
    }
  }  
</script>
