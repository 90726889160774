<template>
  <div>
    <div class="flex-2 flex-no-shrink flex-basis-auto margin-space-bottom">
      <edit-modal></edit-modal>

      <div v-if="selectedSector">
        <h2 class="heading--underlined">
          <clear-button @click="deleteSector" class="margin-space-right vertical-align-bottom" title="Delete sector"></clear-button>
          <span class="flex-vs-center">{{ selectedSector.name }} sector</span>
          <edit-button
            class="vertical-align-bottom"
            title="Edit the name of the sector"
            attribute="name"
            label="Edit Sector Name"
            :password-required="false"
            :on-close="(newName) => { selectedSector.name = newName }"
            :url="sectorUpdateUrl"
            resource="sector"
            size="small"
          ></edit-button>
        </h2>

        <select id="select-sector" v-model="selectedSectorId" size="5" style="height: 180px;">
          <option v-for="sector in sectorsDup" :key="sector.id" :value="sector.id">
            {{ sector.name }}
          </option>
        </select>
      </div>

      <div v-else>
        <h2 class="heading--underlined">No sectors to view</h2>
      </div>

    </div>

    <input v-model="sectorName" placeholder="Add sector" type="text">

    <div class="flex flex-h-end button-margin-top">
      <button @click="createSector" class='button--cta margin-space-left'>Create</button>
    </div>

    <div v-if="selectedSector" class="margin-space-top flex-2 flex-no-shrink flex-basis-auto">
      <h2 class="heading--underlined">Sub-sectors for the {{ selectedSector.name }} sector</h2>

      <div v-if="filteredSubSectors.length > 0" class="margin-space-top">
        <div v-for="subSector in filteredSubSectors" :key="subSector.id" class="flex flex-v-center">
          <clear-button @click="deleteSubSector(subSector)" title="Delete sub-sector" width="18" height="18"></clear-button>
          <span class="margin-space-left margin-space-right-small">{{ subSector.name }}</span>
          <edit-button
            class="vertical-align-bottom"
            title="Edit the name of the sub-sector"
            attribute="name"
            label="Edit Sub-sector Name"
            :password-required="false"
            :on-close="(newName) => { subSector.name = newName }"
            :url="`/admin/sub_sectors/${subSector.id}`"
            resource="sub_sector"
            size="small"
          ></edit-button>
        </div>
      </div>
      <p v-else class="margin-space-top">There are no sub-sectors for this sector yet</p>

      <input v-model="subSectorName" placeholder="Add sub-sector" type="text">

      <div class="flex flex-h-end button-margin-top">
        <button @click="createSubSector" class='button--cta margin-space-left'>Create</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import FlashProvider from '../../utilities/flash-provider.js'
import ClearButton from '../buttons/ClearButton'
import EditButton from '../buttons/EditButton.vue'
import EditModal from '../modal/EditModal.vue'

export default {
  components: { ClearButton, EditButton, EditModal },

  props: {
    sectors: { type: Array, required: true },
    subSectors: { type: Array, required: true },
  },

  data() {
    return {
      sectorsDup: this.sectors,
      subSectorsDup: this.subSectors,
      selectedSectorId: this.sectors.length > 0 ? this.sectors[0].id : undefined,
      sectorName: '',
      subSectorName: ''
    }
  },

  created() {
    const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content');
    axios.defaults.headers.common['X-CSRF-Token'] = csrf;
    axios.defaults.headers.common['Accept'] = 'application/json';
  },

  computed: {
    selectedSector() {
      return this.sectorsDup.find(sector => sector.id === this.selectedSectorId)
    },

    sectorUpdateUrl() {
      return `/admin/sectors/${this.selectedSectorId}`
    },

    filteredSubSectors() {
      return this.subSectorsDup.filter(subSector => subSector.sector_id === this.selectedSectorId)
    }
  },

  methods: {
    createSector() {
      const sector = { name: this.sectorName }

      axios.post('/admin/sectors', { sector })
        .then(res => {
          const newSector = res.data.sector

          this.addTo(this.sectorsDup, newSector)
          this.alphabetizeSectors()
          this.setSelectedSectorId(newSector.id)
          this.resetField('sectorName')

          FlashProvider.display(res.data.message)
        })
        .catch(err => {
          FlashProvider.display(err.response.data.message, 'alert')
        })
    },

    deleteSector() {
      const hasConfirmed = confirm(`Are you sure you want to delete the ${this.selectedSector.name} sector? All of its sub-sectors will also be deleted.`)

      if (hasConfirmed) {
        axios.delete(`/admin/sectors/${this.selectedSectorId}`)
          .then(res => {
            this.sectorsDup = this.sectorsDup.filter(s => s.id !== this.selectedSectorId);
            this.subSectorsDup = this.subSectorsDup.filter(ss => ss.sector_id !== this.selectedSectorId);
            this.setSelectedSectorId()
  
            FlashProvider.display(res.data.message)
          })
          .catch(err => {
            FlashProvider.display(err.response.data.message, 'alert')
          })
      }
    },

    createSubSector() {
      const sub_sector = {
        sector_id: this.selectedSectorId,
        name: this.subSectorName
      }

      axios.post('/admin/sub_sectors', { sub_sector })
        .then(res => {
          this.addTo(this.subSectorsDup, res.data.sub_sector)
          this.resetField('subSectorName')

          FlashProvider.display(res.data.message)
        })
        .catch(err => {
          FlashProvider.display(err.response.data.message, 'alert')
        })
    },

    deleteSubSector(subSector) {
      const hasConfirmed = confirm(`Are you sure you want to delete the ${subSector.name} sub-sector from the ${this.selectedSector.name} sector?`)

      if (hasConfirmed) {
        axios.delete(`/admin/sub_sectors/${subSector.id}`)
          .then(res => {
            this.subSectorsDup = this.subSectorsDup.filter(ss => ss.id !== subSector.id)
  
            FlashProvider.display(res.data.message)
          })
          .catch(err => {
            FlashProvider.display(err.response.data.message, 'alert')
          })
      }
    },

    addTo(collection, object) {
      collection.push(object)
    },

    setSelectedSectorId(id = undefined) {
      if (id) {
        this.selectedSectorId = id
      } else {
        this.selectedSectorId = this.sectorsDup.length > 0 ? this.sectorsDup[0].id : undefined;
      }
    },

    alphabetizeSectors() {
      this.sectorsDup = this.sectorsDup.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1 }
        if (b.name.toLowerCase() < a.name.toLowerCase()) { return 1 }
        return 0
      })
    },

    resetField(field) {
      this[field] = ''
    }
  }
}
</script>
