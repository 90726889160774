export const TooltipTexts =  {
  computed: {
    wdpaTooltipText: () => {
      return "No. of protected areas that occur within 50km of this site.";
    },

    redlistTooltipText: () => {
      return "No. of species assessed on the IUCN Red List of Threatened Species that potentially occur within 50km of this site.";
    },

    kbaTooltipText: () => {
      return "No. of Key Biodiversity Areas that occur within 50km of this site.";
    },

    countryTooltipText: () => {
      return "The country is determined by the location of the centroid of the project polygon.";
    },

    coordinatesTooltipText: () => {
      return 'Coordinates should be input with the latitude followed by the longitude. IBAT accepts coordinates in the following format:<br></br>Lat, Lng e.g. 52,0.12';
    }
  }
}
