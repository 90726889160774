<template>
  <div v-if="!areCookiesAccepted" class="cookie-popup fixed--bottom">
    <div class="cookie-popup__content container--medium">
      <h2 class="cookie-popup__title heading--simple">{{ content.title }}</h2>
      <p class="cookie-popup__description">{{ content.description }}</p>
      <button @click="acceptCookies" class="cookie-popup__button button--medium button--outline-white">{{ content.button_label }}</button>
    </div>
  </div>
</template>

<script>
import helpers from '../../utilities/helpers.js'
const consentCookieName = '_ibat_cookie_consent'

export default {
  data () {
    return {
      areCookiesAccepted: false
    }
  },

  props: ['content'],

  methods: {
    acceptCookies () {
      const expiryDate = new Date()
      const ninetyDays = 90*24*60*60*1000

      expiryDate.setTime(expiryDate.getTime() + ninetyDays)
      document.cookie = `${consentCookieName}=true; expires=${expiryDate.toUTCString()}; path=/`

      this.areCookiesAccepted = true
    }
  },

  created () {
    this.areCookiesAccepted = Boolean(helpers.getCookie(consentCookieName))
  }
}
</script>