<template>
  <div>
    <label for="site">Select Project</label>

    <infinite-scroll-select  
    @input="emitSelectedSite" 
    v-model="selectedSite"
    :showValidationError="!isValidSiteSelection"
    errorMessage="Please select a project that has a point geometry. A freshwater report cannot be generated for polylines or polygons."
    :value="selectedSite"
    :apiEndPoint="apiEndPoint"
    :itemsPerPage="itemsPerPage"
    siteType="freshwater"
    >
  </infinite-scroll-select>

    <div class="form__freshwater bg-grey-light">
      <p>Define the upstream and downstream range for the report below.</p>
      <p class="form__validation-message" v-show="!isValidRanges">Please ensure the ranges are formatted correctly</p>

      <div class="flex">
        <div class="flex-1-half form__freshwater-upstream">
          <label class="no-margin">Upstream (KM)</label>

          <p class="flex no-margin">
            <label class="form__freshwater-label center no-margin">Zone</label>
            <label class="form__freshwater-label center flex-1">Distance</label>
          </p>

          <div>
            <p v-for="i in 3" :key="`upstream-${i}`" class="form__freshwater-field flex">
              <label :for="`upstream-${i}`" class="form__freshwater-label">{{ i }}</label>
              <number-input
                :name="`upstream-${i}`"
                :initialValue="defaults[i - 1]"
                classes="form__freshwater-input no-margin"
                classesWrapper="flex-1"
                v-model="ranges.upstream[`upstream_${i}`]">
              </number-input>
            </p>

            <div class="form__freshwater-field flex">
              <label class="form__freshwater-label">4</label>
              <span class="flex-center flex-1">Beyond Zone 3</span>
            </div>
          </div>
        </div>

        <div class="flex-1-half form__freshwater-downstream">
          <label class="no-margin">Downstream (KM)</label>

          <p class="flex no-margin">
            <label class="form__freshwater-label center no-margin">Zone</label>
            <label class="form__freshwater-label center flex-1">Distance</label>
          </p>

          <div>
            <p v-for="i in 3" :key="`downstream-${i}`" class="form__freshwater-field flex">
              <label :for="`downstream-${i}`" class="form__freshwater-label">{{ i }}</label>
              <number-input
                :name="`downstream-${i}`"
                :initialValue="defaults[i - 1]"
                classes="form__freshwater-input no-margin"
                classesWrapper="flex-1"
                v-model="ranges.downstream[`downstream_${i}`]">
              </number-input>
            </p>

            <div class="form__freshwater-field flex">
              <label class="form__freshwater-label">4</label>
              <span class="flex-center flex-1">Beyond Zone 3</span>
            </div>
          </div>
        </div>
      </div>
    </div>

      <p v-show="!submitting" class="form__submission">
        <button @click="submit" class="button--outline margin-space-right" :class="{ 'button--disabled': !formComplete }" :disabled="!this.formComplete">Create</button>
        <button @click="cancel" class="button--underline">Cancel</button>
      </p>

      <i v-show="submitting" class="icon--spinner"></i>

      <p class="padding-top light">Require further information on the reports IBAT offers? Head over to our <a href="/sample-downloads" target="_blank">examples page</a> for a detailed explanation of each report and a downloadable example.</p>
  </div>
</template>

<script>
  import SingleSelect from '../form_fields/SingleSelect'
  import NumberInput from '../form_fields/NumberInput'
  import { mixinFormReports } from '../../mixins/mixin-form-reports'
  import InfiniteScrollSelect from '../form_fields/infiniteScrollSelect'


  export default {
    name: 'form-create-freshwater-report',

    components: {
      SingleSelect,
      NumberInput,
      InfiniteScrollSelect
    },

    mixins: [mixinFormReports],

    props: {
      defaultSite: { type: [Object, String], required: false , default: "" },
      itemsPerPage : {type : Number, default: 100},
      apiEndPoint:{
        type:String , required: true 
      }
    },

    data() {
      return {
        selectedSite: this.defaultSite,
        defaults: [50, 100, 150],
        ranges: {
          upstream: {
            upstream_1: 50,
            upstream_2: 100,
            upstream_3: 150,
          },
          downstream: {
            downstream_1: 50,
            downstream_2: 100,
            downstream_3: 150
          }
        }
      }
    },

    computed: {
      isValidSiteSelection() {
        return this.hasSelected(this.selectedSite) && ['st_point', 'point'].includes(this.selectedSite.geom_type)
      },

      isValidRanges() {
        let isValid = true

        Object.keys(this.ranges).forEach(stream => {
          let previousValue = 0

          Object.keys(this.ranges[stream]).forEach(range => {
            if (this.ranges[stream][range] <= previousValue) {
              isValid = false
            }

            previousValue = this.ranges[stream][range]
          })
        })

        return isValid
      },

      formComplete() {
        return this.isValidSiteSelection && this.isValidRanges
      },

      formattedRanges() {
        return {
          upstream: {
            upstream_1: this.ranges.upstream.upstream_1,
            upstream_2: this.ranges.upstream.upstream_2,
            upstream_3: this.ranges.upstream.upstream_3,
          },
          downstream: {
            downstream_1: -this.ranges.downstream.downstream_1,
            downstream_2: -this.ranges.downstream.downstream_2,
            downstream_3: -this.ranges.downstream.downstream_3
          }
        }
      },

      reportParams() {
        return {
          name: '',
          report_type_id: 'FreshwaterReport',
          site_ids: this.selectedSite.id,
          ranges: this.formattedRanges
        }
      }
    }
  }
</script>
