<template>
  <div>
    <label for="site">Select Site</label>

    <infinite-scroll-select  
    @input="emitSelectedSite" 
    v-model="selectedSite"
    :showValidationError="!isValidSiteSelection"
    :value="selectedSite"
    :apiEndPoint="apiEndPoint"
    :itemsPerPage="itemsPerPage"
    siteType="ps6"
    >
  </infinite-scroll-select>

    <multi-search-select
      id="multiple-biomes"
      name="biome"
      :options="biomes"
      :defaults="multiBiomeDefaults"
      :minimum="minimumBiomes"
      sort-by="title"
      label="Select Biomes"
      :showValidationError="!isValidBiomesSelection"
      v-model="selectedBiomes">
    </multi-search-select>

    <p v-show="!submitting" class="form__submission">
      <button @click="submit" class="button--outline margin-space-right" :class="{ 'button--disabled': !formComplete }" :disabled="!this.formComplete">Create</button>
      <button @click="cancel" class="button--underline">Cancel</button>
    </p>

    <i v-show="submitting" class="icon--spinner"></i>

    <p class="padding-top light">Require further information on the reports IBAT offers? Head over to our <a href="/sample-downloads" target="_blank">examples page</a> for a detailed explanation of each report and a downloadable example.</p>
  </div>
</template>

<script>
  import SingleSelect from '../form_fields/SingleSelect'
  import MultiSearchSelect from '../form_fields/MultiSearchSelect'
  import { mixinFormReports } from '../../mixins/mixin-form-reports'
  import InfiniteScrollSelect from '../form_fields/infiniteScrollSelect'


  export default {
    name: 'form-create-proximity-report',

    components: { SingleSelect, MultiSearchSelect, InfiniteScrollSelect },

    mixins: [mixinFormReports],

    props: {
      biomes: { type: Array, required: true },
      defaultSite: { type: [Object, String], required: false , default: "" },
      itemsPerPage : {type : Number, default: 100},
      apiEndPoint:{
        type:String , required: true 
      }
    },

    data() {
      return { 
        selectedSite: this.defaultSite,
        selectedBiomes: [],
        minimumBiomes: 1
      }
    },

    computed: {
      isValidSiteSelection() {
        return this.hasSelected(this.selectedSite)
      },

      formComplete() {
        return this.isValidSiteSelection && this.isValidBiomesSelection
      },

      isValidBiomesSelection() {
        return this.isValidLength(this.selectedBiomes, this.minimumBiomes)
      },

      multiSelectDefaults() {
        return this.defaultSite ? [this.defaultSite] : [];
      },

      multiBiomeDefaults() {
        return [];
      },

      reportParams() {
        return {
          name: '',
          report_type_id: 'Ps6Report',
          site_ids: this.selectedSite.id,
          biomes: this.selectedBiomes.map(biome => biome)
        }
      }
    }
  }
</script>
