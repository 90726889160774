<template>
  <form 
    id="migrate_data"
    class="migrate_data"
    :key="resetKey"
    @submit.prevent="onSubmit"
    @reset.prevent="onReset"
  >

    <div class="form__field">
      <label for="site">Select a User</label>
      <SingleSelect
        v-model="form.user_id"
        :options="users"
        value-attribute="id"
        title-attribute="select_name"
      >
      </SingleSelect>
    </div>

    <div class="actions center button-wrapper">
      <button
        type="submit"
        class="button--cta button-margin-bottom margin-space-right"
        :disabled="busy"
      >
        Move User Data
      </button>
      <button
        type="reset"
        class="button--underline"
      >
        Reset
      </button>
    </div>

  </form>
</template>

<script>
import { debounce } from 'lodash'
import FlashProvider from '../../utilities/flash-provider'
import SingleSelect from '../form_fields/SingleSelect'


const FORM_DEFAULTS = {
  user_id: null
}

export default {
  components: {
    SingleSelect,
  },

  props: {
    users: {
      required: true,
      type: Array,
      validation: (users) => users.every((user) => typeof user === 'object')
    },
    old_user_id: {
      required: true,
      type: Number
    },
    isAdmin: {
      required: true,
      type: Boolean
    }
  },

  data () {
    return {
      busy: false,
      form: { ...FORM_DEFAULTS },
      resetKey: 0, // helps reset the form
    }
  },

  methods: {

    // Determine whether the form should be considered busy.
    setBusy (boolean) {
      this.busy = boolean === true
    },

    onReset () {
      this.form = { ...FORM_DEFAULTS }
      this.resetKey++ // helps reset the form
    },

    onSubmit: debounce(function () {
      this.setBusy(true)

      let redirectUrl = '/business/users'
      let migrateUserDataPath = `/business/migrate_user_data/${this.form.user_id}`
      if(this.isAdmin === true) {
        redirectUrl = '/admin/users'
        migrateUserDataPath = `/admin/migrate_user_data/${this.form.user_id}`
      }

      const payload = {
        id: this.form.user_id,
        old_user_id: this.old_user_id
      }

      const hasConfirmed = confirm(`Are you sure you want to migrate user data?`)

      if (hasConfirmed) {
        this.$http.patch(migrateUserDataPath, payload)
          .then(res => {
            if (res.data) {
              window.location.href = redirectUrl
            }
          })
          .catch(err => {
            if (err.response.data) {
              FlashProvider.display(err.response.data, 'alert')
            }
          })
          .finally(() => {
            this.setBusy(false)
          })
      }
    }, 500)
  }
}
</script>
