<template>
  <div class="form__field--search-fixed-width button--search">
    <input type="text" v-model="searchTerm" v-on:keyup.enter="search" class="no-margin">
    <button @click="search" class="button--search__button icon-search"></button>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'page-search',

    data () {
      return {
        searchTerm: '',
        name: 'search'
      }
    },

    methods: {
      search (shouldGetItems = true) {
        this.$store.commit('pagination/updateSearchTerm', this.searchTerm)
        this.$store.commit('pagination/updateRequestedPage', 1)
        if (shouldGetItems) { eventHub.$emit('getNewItems') };
      },

      reset(shouldGetItems = true) {
        this.searchTerm = '';
        this.$store.commit('pagination/updateSearchTerm', this.searchTerm);
        if (shouldGetItems) { eventHub.$emit('getNewItems') };
      }
    }
  }  
</script>
