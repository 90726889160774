export const storeSemaphore = {
  namespaced: true,

  state: {
    semaphore: null
  },
  getters: {
    getSemaphore: state => state.semaphore
  },
  mutations: {
    setSemaphore: function(state, semaphore) {
      state.semaphore ||= semaphore
    }
  }
}
