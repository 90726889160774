<template>
  <div>
    <label for="first-name">First name</label>
    <input id="first-name" type="text" v-model="firstName">
      
    <label for="last-name">Last name</label>
    <input id="last-name" type="text" v-model="lastName">

    <label for="email">Email</label>
    <input id="email" type="email" v-model="email">

    <label for="active" class="form__field--checkbox">
      <input id="active" type="checkbox" class="checkbox" v-model="active">
      Account active?
    </label>

    <p class="form__submission center">
      <button @click="submit" class="button--cta">Create account</button>
    </p>
  </div>
</template>

<script>
  import axios from 'axios'
  import TextInput from '../form_fields/TextInput.vue'

  export default {
    name: 'form-create-account',

    components: { TextInput },

    props: {
      action: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        showValidationErrors: false,
        fieldsWithErrors: 0,
        firstName: '',
        lastName: '',
        email: '',
        active: true
      }
    },

    methods: {
      submit () {
        const hasErrors = this.fieldsWithErrors > 0

        if(hasErrors) {
          this.showValidationErrors = true
        } else {
          const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
          axios.defaults.headers.common['X-CSRF-Token'] = csrf
          axios.defaults.headers.common['Accept'] = 'application/json'

          const data = {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            active: this.active
          }

          console.log('post data', data) 

          axios
            .post(this.action, data)
            .then(response => {
              console.log('success')

              eventHub.$emit('getNewItems')
              this.resetform()
            })
            .catch(error => {
              console.log('fail')
            })
        }
      },

      resetform () {
        this.showValidationErrors = false
        this.fieldsWithErrors = 0
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.active = true
      }
    }
  }
</script>