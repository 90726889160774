<template>
  <div>
    <div class="multiple-select--label-wrapper">
      <label v-if="label">{{ label }}</label>
      <div
        @click="toggleSelectAll"
        class="multiple-select--clearable-wrapper"
      >
        <p>{{ clearOption }} all <span v-if="clearable">{{ clearableCount }}</span></p><i :class="['icon-' + iconClass, 'icon--small']"></i>
      </div>
    </div>

    <p
      class="form__validation-message"
      v-show="showValidationError"
    >Please select at least {{ minimum }} {{ name }}</p>

    <v-select
      @input="changeInput"
      class="multiselect"
      v-model="selectedOptions"
      :close-on-select="false"
      :multiple="true"
      label="title"
      :options="options"
    >
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import { eventHub } from '../../ibat.js'

export default {
  name: 'multi-search-select',

  components: { vSelect },

  props: {
    options: {
      required: true,
      type: Array
    },
    name: {
      required: true,
      type: String
    },
    defaults: { default: [], type: Array },
    sortBy: {
      required: true,
      type: String
    },
    label: {
      required: false,
      type: String
    },
    minimum: {
      required: true,
      type: Number
    },
    showValidationError: { required: true }
  },

  data () {
    return { selectedOptions: [] }
  },

  computed: {
    iconClass () {
      return this.clearable ? 'close' : 'plus';
    },

    clearable () {
      return this.selectedOptions.length > 0;
    },

    clearOption () {
      return this.clearable ? 'Clear' : 'Select';
    },

    clearableCount () {
      return this.selectedOptions.length
    }
  },

  created () {
    this.selectDefaults();
  },

  methods: {
    toggleSelectAll () {
      this.clearable ? this.reset() : this.selectAllOptions()
    },

    changeInput (selectedOptions) {
      this.sortSelectedOptions();
      this.emitSelectedOptions()
    },

    // selectAllOptions () {
    //   this.selectedOptions = this.options;
    //   this.sortSelectedOptions();
    //   this.emitSelectedOptions()
    // },

    selectAllOptions () {
      var search = this.$el.querySelectorAll('.vs__search')[0].value
      if (search.length > 0) {
        var filteredItem = this.options.filter((item) => {
          var selectedItem = (typeof item === 'object') ? item.title : item
          return selectedItem.toUpperCase().includes(search.toUpperCase())
        })
        if (filteredItem.length > 0) { this.selectedOptions = filteredItem; }
      }
      else { this.selectedOptions = this.options; }
      this.sortSelectedOptions();
      this.emitSelectedOptions()
    },

    selectDefaults () {
      this.selectedOptions = this.defaults;
      this.sortSelectedOptions();
      this.emitSelectedOptions()
    },

    reset () {
      this.selectedOptions = []
      this.emitSelectedOptions()
    },

    emitSelectedOptions () {
      this.$emit('input', this.selectedOptions)
    },

    sortSelectedOptions () {
      this.selectedOptions = this.selectedOptions.sort((a, b) => {
        if (a[this.sortBy] < b[this.sortBy]) { return -1 }
        if (b[this.sortBy] < a[this.sortBy]) { return 1 }
        return 0
      });
    }
  }
}
</script>
