<template>
  <div>
    <modal :modal-id="modalId" modal-classes="modal--delete">
      <p>Deleting this user will also delete {{ userDataFields.sites_count }} projects, {{ userDataFields.reports_count }} reports and {{ userDataFields.data_downloads_count }} GIS Downloads. Are you sure?"</p>

      <div class="flex flex-center">
        <button @click="deleteItems" class="button--cta-red margin-space-right">Yes</button>
        <button class="button--cta" @click="closeModal">Cancel</button>
      </div>
    </modal>

    <div class="flex flex-v-center margin-space-right w-40">
      <modal-trigger
        modal-id="delete-user-model"
        class="button--cta-red button-margin-top button-margin-left button-margin-bottom"
        :disabled="busy"
      >
        Delete
      </modal-trigger>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  import { eventHub } from '../../ibat.js'
  import Modal from '../modal/Modal.vue'
  import ModalTrigger from '../modal/ModalTrigger.vue'
  import FlashProvider from '../../utilities/flash-provider'

  export default {
    name: 'delete-user-modal',

    components: { Modal, ModalTrigger },

    props: {
      userDataFields: { type: Object, required: true },
      userId: { type: Number, required: true },
      isAdmin: { type: Boolean, required: true},
    },

    data () {
      return {
        busy: false,
        modalId: 'delete-user-model',
      }
    },

    beforeDestroy () {},

    methods: {

      // Determine whether the button should be considered busy.
      setBusy (boolean) {
        this.busy = boolean === true
      },

      deleteItems () {
        this.setBusy(true)

        let redirectUrl = '/business/users'
        let deleteUserPath = `/business/users/${this.userId}`
        if(this.isAdmin === true) {
          redirectUrl = '/admin/users'
          deleteUserPath = `/admin/users/${this.userId}`
        }

        axios.delete(deleteUserPath)
          .then((response) => {
            window.location.href = redirectUrl
          })
          .catch(err => {
            if (err.response.data) {
              FlashProvider.display(err.response.data.message, 'alert')
            }
          })
          .finally(() => {
            this.setBusy(false)
          })
      },

      closeModal () {
        eventHub.$emit('requestModalClose', this.modalId)
      },

    }
  }
</script>
