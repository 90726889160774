<template>
  <div>
    <p class="form__validation-message" v-if="!selectMultiple" v-show="showValidationError">{{ errorMessage }}</p>
      <div v-if="selectMultiple">
        <div class="multiple-select--label-wrapper" >
          <label>Select Sites</label>
          <div
            @click="toggleSelectAll"
            class="multiple-select--clearable-wrapper"
          >
            <p>{{ clearOption }} all <span v-if="clearable">{{ clearableCount }}</span></p><i :class="['icon-' + iconClass, 'icon--small']"></i>
          </div>
        </div>
        <p class="form__validation-message" v-show="showValidationError">{{ errorMessage }}</p>
      </div>
    <p class="no-margin select--chevron">
      <v-select class="select multiselect"  @input="onChange" :options="options" :filterable="false"
        :label="titleAttribute" @open="onOpen" @close="onClose" @search="handleSearch" v-model="selectedOption" :multiple="selectMultiple" v-on:input="limiter">
        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="infinite-loader">
            Loading more options...
          </li>
        </template>
      </v-select>
      
      <p v-if="optionsLimit > 0" class="select--limit-message">You can select up to {{ optionsLimit }}</p>
    </p>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import axios from "axios"
  export default {
    name: 'InfiniteScrollSelect',
    components: { vSelect },
    props: {
      titleAttribute: {
        type: String,
        default: 'title'
      },
      value: {
        required: false,
        default: ''
      },
      showValidationError: {
        required: false
      },
      errorMessage: {
        type: String,
        default: "Please select an option",
        required: false
      },
      apiEndPoint: {
        required: true,
        type: String,
        default: ""
      },
      itemsPerPage: {
        type: Number,
        default: 500
      },
      siteType: {
        required: true,
        type: String,
        default: "star"
      },
      selectMultiple: {
        required: false,
        type: Boolean
      },
      optionsLimit: {
        required: false,
        type: Number,
        default: 0
      }
    },

    data: () => ({
      observer: null,
      search: '',
      selectedOption: "",
      options: [],
      currentPage: 0,
      totalPage: 0,
      totalItems: 0,
    }),

    computed: {
      hasNextPage() {
        return this.options.length < this.totalItems
      },
      clearable () {
        return this.selectedOption.length > 0;
      },
      clearOption () {
        return this.clearable ? 'Clear' : 'Select';
      },
      clearableCount () {
        return this.selectedOption.length
      }, 
      iconClass () {
        return this.clearable ? 'close' : 'plus';
      },
    },

    mounted() {
      if (this.value) {
        this.selectedOption = this.value
      }
      this.observer = new IntersectionObserver(this.infiniteScroll)
    },

    methods: {
      async onOpen() {
        if (this.hasNextPage) {
          await this.$nextTick()
          this.observer.observe(this.$refs.load)
        }
        if (this.options.length === 0) {
          this.fetchSites()
          await this.$nextTick()
          this.observer.observe(this.$refs.load)
        }
      },

      onClose() {
        this.observer.disconnect()
        this.search = ""
      },

      async infiniteScroll([{ isIntersecting, target }]) {
        if (isIntersecting) {
          const ul = target.offsetParent
          const scrollTop = target.offsetParent.scrollTop
          let data =  await this.fetchSites()
          await this.$nextTick()
          if(data &&  data.length > 0) {
            ul.scrollTop = scrollTop
          }
        }
      },

      fetchSites() {
        return axios.get(this.apiEndPoint, { params: { type: this.siteType, requested_page: this.currentPage + 1, 
        items_per_page: this.itemsPerPage, search: this.search } }).then((res) => {
          if (res.status === 200 ) {
            if(this.search && !this.currentPage) {
              this.options =  res.data.items
              this.currentPage = res.data.current_page
            } else {
              this.options = [...this.options, ...res.data.items]
              this.currentPage = res.data.current_page
              this.totalItems = res.data.total_items
            }
            return this.options
          }
        })
        .catch((error) => {
          console.log(error);
        })
      },

      fetchBySearch() {
        this.currentPage = 0
        this.fetchSites()
      },

      handleSearch: function (value) {
        clearTimeout(this.timeout);
        var self = this;
        this.search =  value
        this.timeout = setTimeout(() => this.fetchBySearch(), 1000);
      },

      toggleSelectAll() {
        this.clearable ? this.reset() : this.selectAllSites()
      },

      selectAllSites() {
        this.emitSelectedOptions()
      },

      reset() {
        this.selectedOption = []
        this.$emit('input', [])
      },

    async emitSelectedOptions() {
      let newUrl = this.apiEndPoint

      const url = this.apiEndPoint.includes('portfolios') ? newUrl.replace("fetch_portfolio_sites", "fetch_all_sites") : '/fetch_all_sites';
      return axios.get(url).then((res) => {
        if (res.status === 200) {
          this.options = res.data

          if (this.optionsLimit > 0)
            this.selectedOption = this.options.slice(0, this.optionsLimit) 
          else
            this.selectedOption = this.options
          
          this.$emit('input', res.data)
          this.totalItems = this.options.length
        }
      })
        .catch((error) => {
          console.log(error);
        })
      },

      onChange() {
        this.$emit('input', this.selectedOption)
      },

      limiter() {
        if (this.selectedOption.length > this.optionsLimit && this.optionsLimit > 0) {
          this.selectedOption = this.selectedOption.slice(0, this.optionsLimit)
        }
      }
    },
  }
</script>
