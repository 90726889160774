import { eventHub } from '../ibat.js'

export const storeForm = {
  namespaced: true,

  state: {
    addSiteName: '',
    newSiteId: ''
  },

  getters: {
    mapGeometry (state, getters, rootState, rootGetters) {
      return rootGetters['map/geometry']
    }
  },

  actions: {
    validateForm ({ commit }) {
      eventHub.$emit('validate')
    },

    resetAddSiteForm ({ commit }) {
      commit('resetFormField', 'addSiteName')

      eventHub.$emit('updatedAddSiteName')
    }
  },

  mutations: {
    updateFormField (state, updateObject) {
      this.state.form[updateObject.name] = updateObject.value
    },

    resetFormField (state, fieldName) {
      this.state.form[fieldName] = ''
    },

    updateNewSiteId (state, id) {
      this.state.form.newSiteId = id
    }
  }
}
