<template>
  <div class="loader--wrapper">
    <img :width="width" :src="require(`images/icons/loader-${color}.svg`)">
    <loading-bar v-if=percentage
      :percentage="percentage"
      :height="barHeight">
    </loading-bar>
  </div>
</template>

<script>
  import LoadingBar from './LoadingBar'

  export default {
    name: 'loader',

    components: { LoadingBar },

    props: {
      width: { type: Number, default: 50 },
      color: { type: String, default: 'grey' },
      percentage: { type: Number, required: false },
      barHeight: { type: Number, default: 3 }
    }
  }
</script>
