<template>
  <form
    id="new_user"
    class="new_user"
    @submit.prevent="onSubmit()"
  >
    <div class="form__field" v-show="!fetchUser()">
      <label>{{ $t('forms.register.labels.account_type') }}</label>
      <div class="flex">

        <button
          ref="accountTypePersonalCTA"
          @click.prevent="onPersonalAccountTypeClick"
          class="button-margin-bottom"
          :class="{
            'button--cta': form.user.business_attributes.is_personal,
            'button--outline': !form.user.business_attributes.is_personal,
          }"
        >
          {{ $t('forms.register.labels.account_types.personal') }}
        </button>

        <button
          ref="accountTypeOrganisational"
          @click.prevent="onOrganisationAccountTypeClick"
          class="button-margin-bottom button-margin-left"
          :class="{
            'button--cta': !form.user.business_attributes.is_personal,
            'button--outline': form.user.business_attributes.is_personal,
          }"
        >
          {{ $t('forms.register.labels.account_types.organisation') }}
        </button>
      </div>
    </div>

    <template v-if="!form.user.business_attributes.is_personal">
      <div
        class="form__field"
        :class="{ 'field_with_errors': hasError('business') }"
      >
        <label for="user_business_attributes_name">
          {{ $t('forms.register.labels.business_attributes.name') }}
        </label>
        <input
          type="text"
          name="user[business_attributes][name]"
          id="user_business_attributes_name"
          v-model="form.user.business_attributes.name"
          @input.prevent="clearErrors('business')"
        >
        <VErrors :errors="errors.business || errors['business.name']" />
      </div>

      <div
        class="form__field"
        :class="{ 'field_with_errors': hasError('organisation_type_id') }"
      >
        <label for="user_business_attributes_organisation_type">
          {{ $t('forms.register.labels.business_attributes.type_id') }}
        </label>
        <select
          name="user[business_attributes][organisation_type_id]"
          id="user_business_attributes_organisation_type_id"
          v-model="form.user.business_attributes.organisation_type_id"
          @input.prevent="clearErrors('organisation_type_id')"
        >
          <option :value="null"></option>
          <option
            v-for="organisationType in organisationTypes"
            :value="organisationType.id"
          >{{ organisationType.name }}</option>
        </select>
        <VErrors :errors="errors.organisation_type_id || errors['business.organisation_type_id']" />
      </div>
    </template>

    <div
      class="form__field"
      :class="{ 'field_with_errors': hasError('country_id') }"
    >
      <label for="user_country">
        {{ $t('forms.register.labels.country_id') }}
      </label>
      <select
        name="user[country_id]"
        id="user_country_id"
        v-model="form.user.country_id"
        @input.prevent="clearErrors('country_id')"
      >
        <option value="">N/A</option>
        <option
          v-for="country in countries"
          :value="country.id"
        >{{ country.name }}</option>
      </select>
      <VErrors :errors="errors.country_id" />
    </div>

    <div
      class="form__field"
      :class="{ 'field_with_errors': hasError('first_name') }"
    >
      <label for="user_first_name">
        {{ $t('forms.register.labels.first_name') }}
      </label>
      <input
        type="text"
        v-model="form.user.first_name"
        name="user[first_name]"
        id="user_first_name"
        @input.prevent="clearErrors('first_name')"
      >
      <VErrors :errors="errors.first_name" />
    </div>

    <div
      class="form__field"
      :class="{ 'field_with_errors': hasError('last_name') }"
    >
      <label for="user_last_name">
        {{ $t('forms.register.labels.last_name') }}
      </label>
      <input
        type="text"
        v-model="form.user.last_name"
        name="user[last_name]"
        id="user_last_name"
        @input.prevent="clearErrors('last_name')"
      >
      <VErrors :errors="errors.last_name" />
    </div>

    <div
      class="form__field"
      :class="{ 'field_with_errors': hasError('email') }"
    >
      <label for="user_email">
        {{ $t('forms.register.labels.email') }}
      </label>
      <input
        type="email"
        v-model="form.user.email"
        autocomplete="email"
        name="user[email]"
        id="user_email"
        @input.prevent="clearErrors('email')"
      >
      <VErrors :errors="errors.email" />
    </div>

    <div
      class="form__field"
      :class="{ 'field_with_errors': hasError('job_title') }"
    >
      <label for="user_job_title">
        {{ $t('forms.register.labels.job_title') }}
      </label>
      <input
        type="text"
        v-model="form.user.job_title"
        name="user[job_title]"
        id="user_job_title"
        @input.prevent="clearErrors('job_title')"
      >
      <VErrors :errors="errors.job_title" />
    </div>

    <div
      class="form__field"
      :class="{ 'field_with_errors': hasError('password') }"
    >
      <label for="user_password">
        {{ $t('forms.register.labels.password') }}
      </label>
      <em v-if="minimumPasswordLength">
        {{ $t('forms.register.labels.minimum_password_length', { value: minimumPasswordLength }) }}
      </em>
      <input
        autocomplete="off"
        type="password"
        v-model="form.user.password"
        name="user[password]"
        id="user_password"
        @input.prevent="clearErrors('password')"
      >
      <VErrors :errors="errors.password" />
    </div>

    <div
      class="form__field"
      :class="{ 'field_with_errors': hasError('password_confirmation') }"
    >
      <label for="user_password_confirmation">
        {{ $t('forms.register.labels.password_confirmation') }}
      </label>
      <input
        autocomplete="off"
        type="password"
        v-model="form.user.password_confirmation"
        name="user[password_confirmation]"
        id="user_password_confirmation"
        @input.prevent="clearErrors('password_confirmation')"
      >
      <VErrors :errors="errors.password_confirmation" />
    </div>

    <div
      class="form__field--radio"
      :class="{ 'field_with_errors': hasError('terms') }"
      v-show="!fetchUser()"
    >
      <label for="user_terms" v-html="$t('forms.register.labels.terms')" />
      <input
        type="checkbox"
        v-model="form.user.terms"
        name="user[terms]"
        id="user_terms"
        @input.prevent="clearErrors('terms')"
      >
      <VErrors :errors="errors.terms" />
    </div>

    <div class="actions center button-wrapper">
      <button
        type="submit"
        class="button--cta button-margin-bottom"
        :disabled="busy"
      >
        {{ $t('forms.register.labels.submit') }}
      </button>
      <p v-html="$t('forms.register.ctas.login', { path: '/users/sign_in' })" />
    </div>
  </form>
</template>
<script>
import { debounce } from 'lodash'
import mixinGrecaptcha from '../../mixins/mixin-grecaptcha.js'
import FlashProvider from '../../utilities/flash-provider.js'
import VErrors from '../../components/errors/VErrors.vue'

const ARRAY_OF_NAME_ID_OBJECTS_VALIDATOR = (array) => {
  return array.every(obj => {
    return typeof obj === 'object' && obj.hasOwnProperty('name')
      && obj.hasOwnProperty('id')
  })
}

const FORM_DEFAULTS = {
  user: {
    business_attributes: {
      is_personal: false,
      name: '',
      organisation_type_id: null
    },
    country_id: null,
    first_name: '',
    last_name: '',
    email: '',
    job_title: '',
    password: '',
    password_confirmation: '',
    terms: false
  }
}

export default {
  components: {
    VErrors,
  },

  mixins: [ mixinGrecaptcha ],

  props: {
    countries: {
      type: Array,
      required: true,
      validator: ARRAY_OF_NAME_ID_OBJECTS_VALIDATOR
    },
    // To be used with the "Personal" account type name.
    dummyOrganisationId: {
      type: String | Number,
      required: false
    },
    minimumPasswordLength: {
      type: Number,
      default: undefined
    },
    organisationTypes: {
      type: Array,
      required: true,
      validator: ARRAY_OF_NAME_ID_OBJECTS_VALIDATOR
    }
  },

  data () {
    return {
      busy: false,
      errors: {},
      form: { ...FORM_DEFAULTS }
    }
  },
  
  watch: {
    errors (errors) {
      // Show a flash message if there is a base error on the form.
      if (errors.hasOwnProperty('base')) {
        FlashProvider.display(errors.base, 'alert')
      }
    }
  },

  computed: {
    // Configure what a "Personal" business account should be named.
    personalAccountOrganisationNameDefault () {
      return `Dummy org ${this.dummyOrganisationId}`
    }
  },

  mounted () {
    return this.$store.state.user.currentUser?.isAdmin ? this.$refs.accountTypeOrganisational.click() : this.$refs.accountTypePersonalCTA.click()
  },

  methods: {
    // Erase the errors for a particular field.
    clearErrors (key) {
      this.$delete(this.errors, key)
    },

    // Determine whether there is an error for a particular field.
    hasError (key) {
      return this.errors.hasOwnProperty(key)
    },

    // Determine whether the form should be considered busy.
    setBusy (boolean) {
      this.busy = boolean === true
    },

    // Set business attributes on the form.
    setBusinessAttributes ({ is_personal, name, organisation_type_id }) {
      this.$set(this.form.user, 'business_attributes', {
        is_personal,
        name,
        organisation_type_id
      })
    },
    // Set condition for call account type label
    fetchUser() { 
      return this.$store.state.user.currentUser?.isAdmin ? true : false
    },

    onSubmit() {
      return this.fetchUser() ? this.onCreateOrganisation() : this.onCreateUser()
    },

    resetForm () {
      this.form = { ...FORM_DEFAULTS }
    },

    // Set the business attributes when the user clicks on the "Personal" account type.
    onPersonalAccountTypeClick () {
      this.setBusinessAttributes({
        is_personal: true,
        name: `${this.personalAccountOrganisationNameDefault}`, // Set a dummy organisation name.
        organisation_type_id: null // Clear the organisation type.
      })
    },

    // Set the business attributes when the user clicks on the "Organisation" account type.
    onOrganisationAccountTypeClick () {
      this.setBusinessAttributes({
        is_personal: false,
        name: '', // Clear the name.
        organisation_type_id: null // Clear the organisation type.
      })
    },

    // Submit a registration request to the server.
    onCreateUser: debounce(function () {
      this.setBusy(true)

      // Fetch the Google reCAPTCHA token.
      this.recaptcha({ action: 'register' }, (grecaptchaToken) => {
        // Send the form data with the reCAPTCHA token attached.
        this.$http.post('/users', { ...this.form, 'g-recaptcha-response': grecaptchaToken })
          .then((res) => {
            console.log(res)
            if (res.data.message) {
              FlashProvider.display(res.data.message)
            }
            window.open('/users/confirmation', '_self') // Redirect to the confirmation page after registering.
          })
          .catch(err => {
            console.warn(err)
            if (err.response.data.message) {
              FlashProvider.display(err.response.data.message, 'alert')
            }
            this.errors = err.response.data.errors
          })
          .finally(() => {
            this.setBusy(false)
          })
      })

    }, 500),

    onCreateOrganisation: debounce(function () {
      this.setBusy(true)
        this.$http.post('/admin/business', { ...this.form })
          .then((res) => {
            console.log(res)
            if (res.data.message) {
              FlashProvider.display(res.data.message)
            }
            if (res.data.status === 200) {
              window.location.href = '/admin/subscriptions'
            }
            if (res.data.errors) {
              this.errors = res.data.errors
            }
          })
          .catch(err => {
            console.warn(err)
            if (err.response.data.message) {
              FlashProvider.display(err.response.data.message, 'alert')
            }
            this.errors = err.response.data.errors
          })
          .finally(() => {
            this.setBusy(false)
          })

    }, 500)
  }
}
</script>
