<template>
  <button :href="href" :class="classes" @click="scroll">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: 'scroll-to-trigger',

    props: {
      classes: String,
      targetId: {
        required: true,
        type: String
      },
    },

    computed: {
      href () {
        return '#' + this.targetId
      }
    },

    methods: {
      scroll () {
        const target = document.getElementById(this.targetId);
        const targetOffset = window.pageYOffset + target.getBoundingClientRect().top;

        window.scrollTo({ top: targetOffset, behavior: 'smooth' })
      }
    }
  }
</script>
