<template>
  <modal :modal-id="modalId" modal-classes="modal--stripe">
    <div>
      <h1 class="two-factor--header heading--simple">De-activate Two-factor Authentication</h1>

      <p class="two-factor--content">You'll need to reconfigure two-factor authentication verification if you decide to start using it again. Any unused backup codes will be invalidated.</p>

      <form @submit.prevent="disableOtp">
        <div class="form__field">
          <label class="text-14" for="enter-password">Enter password</label>
          <input v-model="otpParams.password" id="enter-password" type="password" class="solid-border">
        </div>

        <div class="form__field">
          <label class="text-14" for="enter-otp">Enter passcode</label>
          <input v-model="otpParams.otp" id="enter-otp" type="password" class="solid-border">
        </div>

        <div class="flex-v-center two-factor--buttons">
          <button type="submit" :class="['button--cta-small margin-space-right', { 'button--disabled': !formComplete }]" :disabled="!formComplete">De-activate</button>
          <a @click.prevent="cancel" href="#" class="display-block">Cancel</a>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import axios from 'axios';
import FlashProvider from '../../utilities/flash-provider.js'
import { eventHub } from '../../ibat.js'
import Modal from '../modal/Modal.vue'

export default {
  name: 'disable-two-factor',

  components: { Modal },

  created () {
    const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
    axios.defaults.headers.common['X-CSRF-Token'] = csrf
    axios.defaults.headers.common['Accept'] = 'application/json'

    eventHub.$on('modalClosed', this.reset)
  },

  data() {
    return {
      modalId: 'disable-two-factor',
      otpParams: {
        password: '',
        otp: ''
      }
    }
  },

  computed: {
    formComplete() {
      return !!this.otpParams.password && !!this.otpParams.otp
    },
  },

  methods: {
    disableOtp() {
      if (this.formComplete) {
        axios.get('disable_otp', { params: this.otpParams })
          .then(res => {
            FlashProvider.display(res.data.message);
            eventHub.$emit('requestModalClose', this.modalId)
            this.$emit('otpstatuschange')
            this.resetForm();
          })
          .catch(error => {
            FlashProvider.display(error.response.data.message, 'alert')
          })
      }
    },

    reset(modalId) {
      if (modalId === this.modalId) { this.resetForm(); };
    },

    resetForm() {
      this.otpParams.password = '';
      this.otpParams.otp = '';
    },

    cancel() {
      eventHub.$emit('requestModalClose', this.modalId);
    }
  }
}
</script>
