<template>
  <button @click="openModal"><slot></slot></button>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'modal-trigger',

    props: {
      modalId: {
        type: String,
        required: true
      },
      modalContent: String,
      disabled: Boolean,
      useStoreContent: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      openModal () {
        if (!this.disabled) {
          if(this.modalContent) {
            this.$store.commit('modal/updateModalContent', this.modalContent)
            this.$store.commit('modal/updateUseStoreContent', this.useStoreContent)
          }

          eventHub.$emit('requestModalOpen', this.modalId)
        }
      }
    }
  }
</script>
