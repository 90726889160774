<template>
  <div class="list-item--wrapper">
    <div class="list-item--attr-flex-1">
      <page-checkbox class="margin-space-left" v-if="canDelete" :id="itemDup.id"></page-checkbox>
    </div>
    <div class="list-item--attr-flex-1 list-item--desktop-only margin-space-left">
      <span :title="itemDup.id" class="margin-space-right">{{ itemDup.id }}</span>
    </div>
    <div class="list-item--attr-flex-4 ellipsis">
      <a v-if="itemDup.siteUrl" :href="itemDup.siteUrl" :title="itemDup.name" class="ellipsis margin-space-right">{{ itemDup.name }}</a>
      <span v-else class="ellipsis margin-space-right">{{ itemDup.name }}</span>
    </div>
    <div class="list-item--attr-flex-3 list-item--desktop-only ellipsis">
      <span v-if="itemDup.country" @click.stop="goToCountry" class="text-fake-anchor ellipsis margin-space-right" :title="itemDup.country.name">{{ itemDup.country.name }}</span>
      <span v-else class="margin-space-right" title="N/A">N/A</span>
    </div>
    <div class="list-item--attr-flex-4 list-item--desktop-only"><span>{{ itemDup.download_type }}</span></div>
    <div v-if="user.businessOwnerOrAdmin" class="list-item--attr-flex-3 list-item--desktop-only ellipsis">
      <span :title="itemDup.createdBy" class="ellipsis margin-space-right">{{ itemDup.createdBy }}</span>
    </div>
    <div class="list-item--attr-flex-2 list-item--desktop-only"><span>{{ itemDup.created_at }}</span></div>
    <div class="list-item--attr-flex-1 list-item--desktop-only flex-h-center">
      <tooltip v-if="isPending" class="margin-space-right-small" :text="pendingText">
        <loader :width="37" :percentage="itemDup.percentage_complete"></loader>
      </tooltip>
      <a v-else-if="isSuccessful" :href="itemDup.downloadUrl" :class="downloadIcon" title="Download" target="_blank"></a>
      <tooltip v-else-if="isFailed" class="margin-space-right-small" :text="failureText">
        <img :src="require('images/icons/warning.svg')">
      </tooltip>
    </div>
  </div>
</template>

<script>
import { mixinLoggedIn } from '../../mixins/mixin-logged-in.js'
import { mixinWebsockets } from '../../mixins/mixin-websockets.js'

import Loader from '../loading/Loader';
import PageCheckbox from './PageCheckbox.vue';
import Tooltip from '../tooltip/Tooltip.vue';

export default {
  components: { Loader, PageCheckbox, Tooltip },

  mixins: [mixinLoggedIn, mixinWebsockets],

  props: {
    item: { type: Object, required: true },
    canDelete: { type: Boolean, default: false }
  },

  data() {
    return {
      itemDup: this.item,
      websocketChannel: 'DataDownload'
    }
  },

  mounted() {
    this.subscribeToChannel()
  },

  computed: {
    downloadIcon() {
      return this.itemDup.beenPurchased ? 'icon-download' : 'icon-download-lock'
    },

    isSuccessful () {
      return this.itemDup.status == 'success';
    },

    isPending() {
      return this.itemDup.status == 'pending';
    },

    isFailed() {
      return this.itemDup.status == 'fail';
    },

    failureText() {
      return 'This GIS Download failed to generate properly. Please contact us for more information.'
    },

    pendingText() {
      return 'This GIS Download is currently generating. You will be notified when it is ready to download.'
    }
  },

  methods: {
    goToCountry() {
      window.location.href = this.itemDup.country.countryProfileUrl;
    },
  }
}
</script>
