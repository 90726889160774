<template>
  <div v-show="isActive" class="dropdown__target">
    <slot></slot>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'dropdown-target',

    data () {
      return {
        isActive: false
      }
    },

    created () {
      eventHub.$on('dropdownClicked', this.toggle)
    },

    methods: {
      toggle () {
        this.isActive = !this.isActive
      }
    }
  }
</script>
