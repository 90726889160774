<template>
  <div class="accordion__item">
    <a class="accordion__trigger flex" :class="{'is-active' : isActive }" @click.self="toggleAccordion()">
      <span @click.self="toggleAccordion()">{{ title }}</span>

      <modal-trigger v-if="hasModal" modal-id="filter-info" :modalContent="modalContent" :useStoreContent="true" class="accordion__info-icon button-margin-left" :class="buttonClass">
        <i class="icon-question-mark icon--info"></i>
      </modal-trigger>
    </a>

    <transition name="accordion-toggle">
      <div v-show="isActive" class="accordion__target">
        <div class="accordion__content">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  import ModalTrigger from '../modal/ModalTrigger.vue'

  export default {
    name: 'accordion-item',

    components: { ModalTrigger },

    props: {
      id: {
        type: String,
        required: true
      },
      accordionId: {
        type: String,
        required: true
      },
      toggleId: String,
      title: String,
      open: Boolean,
      hasModal: {
        type: Boolean,
        default: false
      },
      modalContent: String
    },

    data () {
      return {
        isActive: this.open
      }
    },

    computed: {
      buttonClass () {
        return {'accordion__info-icon--left-aligned': !this.hasSubsets()}
      }
    },

    methods: {
      toggleAccordion () {
        eventHub.$emit(this.accordionId, this.id);
      },

      hasSubsets () {
        return this.accordionId.indexOf('subsets') >= 0
      }
    }
  }
</script>

<style lang="scss">
  @keyframes open {
    from { max-height: 0; }
    to { max-height: 500px; }
  }

  @keyframes close {
    from { max-height: 500px; }
    to { max-height: 0; }
  }

  .accordion-toggle-enter-active {
    animation: open .4s forwards ease-in;
  }

  .accordion-toggle-leave-active {
    animation: close .4s forwards ease-out;
  }

  .accordion {
    &__trigger {
      cursor: pointer;
    }

    &__target {
      overflow: hidden;
      height: auto;
    }
  }
</style>
