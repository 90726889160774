<template>
  <div>
    <modal :modal-id="modalId" modal-classes="modal--delete">
      <p v-show="!deleteError">Are you sure you want to {{ deleteAction }} the following item(s)?</p>
      <div v-show="deleteError" class="list-item--scrollable-content">
        <span class="left" v-if="deletePortfolio">
          <p>There are portfolios for the selected project(s):</p>
          <ul>
            <li class="bold text-red left" v-if="site.portfolios.length > 0" v-for="site in errorMessage">
              {{ site.site_name }} -
              <a v-for="portfolio in site.portfolios" class="text-fake-anchor" :href="portfolio['portfolio_url']">{{ portfolio['name'] }}({{ portfolio['sites_count'] }}),</a>
            </li>
          </ul>
          <p>For portfolios with only one project, this will also delete the portfolio itself.</p>

          <p>Are you sure that you want to delete them ?</p>
        </span>
        <p v-else class="bold text-red">{{ errorMessage }}</p>
      </div>

      <div class="flex flex-center">
        <button v-show="deleteError && !deletePortfolio" class="button--outline margin-space-right" @click="deleteItems('true')">Delete</button>
        <button v-show="deleteError && !deletePortfolio" class="button--outline margin-space-right" @click="closeModal">Close</button>
        <button v-show="!deleteError" class="button--outline margin-space-right" @click="closeModal">Cancel</button>
        <button v-show="!deleteError" @click="deleteItems('false')" class="button--cta margin-space-right">Continue</button>
        <button v-show="deleteError && deletePortfolio" @click="deleteItems('true')" class="button--cta margin-space-right">Yes</button>
        <button v-show="deleteError && deletePortfolio" class="button--outline" @click="resetCheckboxes">No</button>
      </div>
    </modal>

    <div v-if="filters || isPortfolio" class="filter-wrapper">
      <div v-if="canDelete" class="button--fake flex flex-v-center margin-space-right-small">
       <page-checkbox :all="true"></page-checkbox><span class="margin-space-left">All</span>
      </div>

      <div v-show="showButton" class="flex flex-v-center margin-space-right-small">
        <modal-trigger v-if="canDelete" :modal-id="modalId" class="button--outline">{{ deleteText }}</modal-trigger>
      </div>

      <page-filters v-if="filters" :filters="updatedFilters"></page-filters>
      <div class="flex flex-12 flex-h-end">
        <div v-show="showButton && addToPortfolio && hasPortfolio" class="flex flex-v-center margin-space-right">
          <modal-trigger modal-id="add-to-portfolio" class="button--outline button--cta">Add to Portfolio</modal-trigger>
        </div>
  
        <div v-show="showButton && addSiteType " class="flex flex-v-center margin-space-small">
          <modal-trigger modal-id="add-site-type" class="button--outline button--cta">Add Site Type</modal-trigger>
        </div>
      </div>

      <div v-if="isPortfolio" class="list-item--attr-flex-1 list-item--desktop-only flex-h-center flex-h-end">
        <refresh-button 
          :update-all=true
          :portfolio-id="itemId"
          :sites-to-update="projectSiteIds"
          @update = "assignSite($event)"
    ></refresh-button>
      </div>

      <loader v-if="isGettingData" class="flex-hs-end"></loader>
    </div>

    <p v-if="isInitialPageLoad" class="fallback-message">Fetching...</p>
    <slot v-else-if="!isInitialPageLoad && hasSavedItems"></slot>
    <p v-else class="fallback-message">No {{ getName }}s to show</p>

    <div v-if="pagination && !isInitialPageLoad && hasSavedItems" class="right">
      {{ pageItemsStart }} - {{ pageItemsEnd }} of {{ totalItems }}

      <template>
        <select v-if="this.name === 'site'" class="pagination" v-model="selectedPerPage" @change="handlePerPageChage">
          <option v-for="pageNum in itemsPerPages" :key="pageNum" :value="pageNum">
            {{ pageNum }} items
          </option>
        </select>
      </template>
      <page-button type="previous" :total-pages="totalPages">
        <i class="icon-chevron-left"></i>
      </page-button>

      <page-button type="next" :total-pages="totalPages">
        <i class="icon-chevron-right"></i>
      </page-button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { eventHub } from '../../ibat.js'
  import PageButton from './PageButton.vue'
  import PageCheckbox from './PageCheckbox.vue'
  import PageFilters from './PageFilters.vue'
  import RefreshButton from '../buttons/RefreshButton.vue'
  import Modal from '../modal/Modal.vue'
  import ModalTrigger from '../modal/ModalTrigger.vue'
  import FlashProvider from '../../utilities/flash-provider.js'
  import Loader from '../loading/Loader'

  export default {
    name: 'page-wrapper',

    components: { PageButton, PageCheckbox, PageFilters, RefreshButton, Modal, ModalTrigger, Loader },

    props: {
      pageQuota: {
        type: Number,
        default: 10
      },
      source: {
        type: String,
        required: true
      },
      pagination: {
        type: Boolean,
        default: false
      },
      canDelete: {
        type: Boolean,
        default: false
      },
      search: {
        type: Boolean,
        default: false
      },
      filters: {
        type: Array
      },
      isPortfolio: {
        type: Boolean,
        required: false
      },
      itemId: {
        type: Number,
        required: false
      },
      sitesToUpdate: {
        type: Array
      },
      searchEvent: String,
      name: { type: String, optional: false },
      httpMethod: { type: String, default: 'post' },
      preserveFilters: { type: Boolean, default: false },
      addToPortfolio: { type: Boolean, default: false },
      addSiteType: { type: Boolean, default: false },
    },

    data () {
      return {
        pageItemsStart: 0,
        pageItemsEnd: 0,
        totalItems: 0,
        totalPages: 0,
        sortDirection: 1,
        modalId: 'delete',
        deleteError: false,
        errorMessage: '',
        isInitialPageLoad: true,
        isGettingData: true,
        deletePortfolio: false,
        projectSiteIds : [],
        updatedFilters: [],
        itemsPerPages : [ 10, 50, 100, 500 ],
        selectedPerPage : 10
      }
    },

    mounted (){
      if (this.isPortfolio) {
        this.projectSiteIds = this.sitesToUpdate.map(x => x.id)
      }
    },

    created () {
      this.configureAxiosHeaders();
      this.configureFilterPreservation();
      this.getItems();
      this.updatedFilters = this.filters
      this.checkPortfolioFilter()
      eventHub.$on('updatePortfolioFilter', this.updatePortfolioFilter )
      eventHub.$on('getNewItems', this.getItems)
      eventHub.$on('resetPages', this.resetErrorMessages)
    },

    updated() {
      if (this.isPortfolio && this.$store.state.pagination.items.length > 0) {
        let ids = this.$store.state.pagination.items.filter(item => item.requiresUpdate === true).map(item => item.id)
        if (JSON.stringify(ids) !== JSON.stringify(this.projectSiteIds)) {
          this.projectSiteIds = ids
        }
      }
    },

    beforeDestroy () {
      this.$store.commit('dismissFilter')
      this.$store.dispatch('pagination/resetPagination')
      eventHub.$off('getNewItems')
    },

    computed: {
      hasSavedItems () {
        return this.totalItems > 0 || this.totalItems == 0 && this.$store.state.pagination.searchTerm != ''
      },

      showButton () {
        const selectedItems = this.$store.state.pagination.selectedItems
        return selectedItems.length > 0
      },

      deleteText() {
        return this.isPortfolio ? 'Remove from Portfolio' : 'Delete';
      },

      deleteAction() {
        return this.isPortfolio ? 'remove' : 'delete';
      },

      hasPortfolio() {
        return this.portfolioFilter[0] && this.portfolioFilter[0]['options'] && this.portfolioFilter[0]['options'].length > 0
      },

      getName() {
        return this.name == 'portfolio' ? 'project' : this.name
      }
    },

    methods: {
      configureAxiosHeaders() {
        const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
        axios.defaults.headers.common['X-CSRF-Token'] = csrf
        axios.defaults.headers.common['Accept'] = 'application/json'
      },

      configureFilterPreservation() {
        if (this.preserveFilters) { this.$store.commit('preserveFilter') }
      },

      updateProperties (data) {
        this.isGettingData = false
        this.pageItemsStart = data.page_items_start
        this.pageItemsEnd = data.page_items_end
        this.totalItems = data.total_items
        this.totalPages = data.total_pages
        this.$store.commit('pagination/updateCurrentPage', data.current_page)
        this.$store.commit('pagination/updateItems', data.items)
        let sites = data.items.map(x => ({title : x.name , id :  x.id}))
        eventHub.$emit('sites' , sites )
      },

      handlePerPageChage(e) {
        this.$store.commit('pagination/updateRequestedPage', 1)
        this.$store.commit('pagination/updateItems', [])
        eventHub.$emit('getNewItems')
      },

      paginationParams () {
        return {
          params: {
            requested_page: this.$store.state.pagination.requestedPage,
            items_per_page: this.selectedPerPage || this.pageQuota,
            sort_field: this.$store.state.pagination.sortField,
            sort_order: this.$store.state.pagination.sortOrder,
            search: this.$store.state.pagination.searchTerm,
            filters: this.$store.state.pagination.selectedFilterOptions
          }
        }
      },

      getItems () {
        this.isGettingData = true;
        const data = this.paginationParams()

        axios[this.httpMethod](this.source, data)
          .then((response) => {
            this.$store.commit('pagination/resetItemIds')
            this.$store.commit('pagination/updateItems', [])
            eventHub.$emit('resetCheckboxIds')
            this.updateProperties(response.data)
            this.isGettingData = false;
            if (this.isInitialPageLoad) { this.isInitialPageLoad = false; };
          })
          .catch(console.error)
      },

      deleteItems (skip_checks) {
        const items = this.$store.state.pagination.selectedItems
        let action, data = this.paginationParams()
        let req_type
        data['params']['skip_checks'] = skip_checks

        console.log(req_type, action, data['params'], 'daaatatatrtataaction')
        if(this.isPortfolio) {
          action = `/${this.name}s/${this.itemId}/sites/destroy_many`
          data['params']['site_ids'] = items
          req_type = 'post' 
        } else {
          if(items.length == 1) {
            action = `/${this.name}s/${items[0]}`
            req_type = 'delete'
          } else {
            action = `/${this.name}s/destroy_many`
            data['params']['ids'] = items
            req_type = 'post'
          }
        }

        axios[req_type]( action, data['params'] )
          .then((response) => {
            this.$store.commit('pagination/resetItemIds')
            this.getItems();
            this.resetCheckboxes();
            window.location.reload();
            FlashProvider.display(response.data.message)
          })
          .catch((error) => {
            if (error.response) {
              this.deleteError = true
              if (error.response.data.delete_portfolio) {
                this.errorMessage = error.response.data.message.sites_with_portfolios
                this.deletePortfolio = error.response.data.delete_portfolio
              } else {
                this.errorMessage = error.response.data.message
              }
            }
          })
      },

      closeModal () {
        eventHub.$emit('requestModalClose', this.modalId)
        if(this.deleteError) { this.deleteError = false }
        this.errorMessage = ''
      },

      resetCheckboxes () {
        if(this.deleteError) { this.deleteError = false }
        this.errorMessage = ''
        this.closeModal()
      },

      resetErrorMessages () {
        if(this.deleteError) { this.deleteError = false }
        this.errorMessage = ''
      },

      assignSite (newData) {
        this.getItems()
      },

      updatePortfolioFilter (portfolio) {
        if( portfolio?.id ) {
          this.updatedFilters = this.filters.map(item => item.name === "portfolio" ? 
            {...item , options :  [...item.options ,  portfolio]}
            : item
            )
        }
      },

      checkPortfolioFilter() {
        if(this.filters && this.filters.length > 0) {
          this.portfolioFilter = this.filters.filter(child => {
            return child.name == 'portfolio'
          })
        }
      }
    }
  }
</script>
