<template>
  <article class="flex flex-h-center">
    <div class="collapse-article--wrapper" :style="{ width: computedWidth }">
      <div @click="toggleBody" class="collapse-article--title flex flex-h-between">
        <slot name="title"></slot>
        <i :class="[icon]"></i>
      </div>
      <div v-if="isViewingBody" ref="articleBody" class="collapse-article--body">
        <slot name="body"></slot>
      </div>
    </div>
  </article>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  export default {
    props: {
      title: { required: false },
      width: { default: 100 },
      collapseOnOpen: { type: Boolean, default: false },
      isOpen: { type: Boolean, default: false }
    },

    data() {
      return { isViewingBody: this.isOpen }
    },

    created() {
      if (this.collapseOnOpen) { eventHub.$on('articleOpened', this.hideBody) }
    },

    beforeDestroy() {
      if (this.collapseOnOpen) { eventHub.$off('articleOpened', this.hideBody) }
    },
    
    computed: {
      icon() {
        return this.isViewingBody ? 'icon-minus' : 'icon-plus'
      },
      computedWidth() {
        return `${Number(this.width)}%`
      }
    },

    methods: {
      toggleBody() {
        if (!this.isViewingBody) {
          eventHub.$emit('articleOpened')
        }
        this.isViewingBody = !this.isViewingBody
      },

      hideBody() {
        this.isViewingBody = false
      }
    }
  }  
</script>
