<template>
  <div class="progress-bar--container">
    <template v-for="i in numberOfSteps">
      <div :key="key(i)" :class="stepClassForStep(i)">
        <span class="progress-bar--step-number">Step {{ i }}</span>
      </div>
      <div v-if="i !== numberOfSteps" :key="key(i)" :class="barClassForStep(i)"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'progress-bar',

  props: {
    numberOfSteps: { required: true, type: Number },
    activeStep: { required: true, type: Number }
  },

  methods: {
    key(index) {
      return `${index}_${Math.random()}`
    },

    stepClassForStep(step) {
      return `progress-bar--step-${this.activityStatusForStep(step)}`;
    },

    barClassForStep(step) {
      return `progress-bar--bar-${this.activityStatusForBar(step)}`;
    },

    activityStatusForStep(step) {
      if (step < this.activeStep) {
        return 'complete';
      } else if (step === this.activeStep) {
        return 'active';
      } else {
        return 'pending';
      }
    },

    activityStatusForBar(step) {
      if (step < this.activeStep) {
        return 'complete';
      } else {
        return 'pending';
      }
    }
  }
}
</script>
