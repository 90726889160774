import { eventHub } from '../ibat.js'

const DEFAULT_TIMEOUT = 4000

const FlashProvider = {
  DEFAULT_TIMEOUT: DEFAULT_TIMEOUT,
  display: (message, type = 'notice', timeout = DEFAULT_TIMEOUT) => {
    const options = { type: type, message: message, timeout: timeout }

    eventHub.$emit('showFlashMessage', options)
  }
}

export default FlashProvider
