<template>
  <div class="card--dashboard-large--left bg-white">
    <p class="margin-space-top margin-space-left bold">{{reportType}} in {{createdAt}}</p>
    <div id="bar-chart"></div>
  </div>
</template>

<script>
  import axios from 'axios';
  import * as d3 from 'd3';

  export default {
    props: {
      formFields: { required: true, type: Array },
      reportType: {required: true, type: String},
      createdAt: {required: true, type: String},
    },

    data() {
      return {
        margin: { top: 20, right: 30, bottom: 30, left: 50 }
      }
    },

    computed: {

      fullHeight() {
        return 500
      },

      height() {
        return this.fullHeight - this.margin.top - this.margin.bottom;
      },

      fullWidth() {
        return 1200
      },

      width() {
        return this.fullWidth - this.margin.left - this.margin.right;
      },
    },

    mounted () {
      this.renderChart();
    },

    methods: {
      renderChart() {
        this.createSvg();
        this.createData();
        this.buildAxes();
        this.buildBarChart();
      },

      createSvg() {
        this.svg = d3.select('#bar-chart')
          .append("svg")
            .attr('class', 'chart--svg')
            .attr('xmlns', 'http://www.w3.org/1999/xhtml')
            .attr('viewBox', '0 0 ' + this.fullWidth + ' ' + this.fullHeight)
            .attr('preserveAspectRatio', 'xMidYMid meet')
          .append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
      },

      createData() {
        this.createXData();
        this.createYData();
      },

      createXData() {
        const width = this.width
        const x = d3.scaleBand().range([0, width]).padding(0.1);

        x.domain(this.formFields.map(d => { return d.group }));

        this.x = x;
      },

      createYData() {
        const height = this.height;
        const y = d3.scaleLinear().range([height, 0]);
        y.domain([0, d3.max(this.formFields, (d) => { return d.count })]);
        this.y = y;
      },

      buildAxes() {
        this.buildXAxis();
        this.buildYAxis();
      },

      buildXAxis() {
        // Create x gridlines
        const xGridlines = d3.axisLeft(this.y)
          .ticks(this.calculateTicksNumber(this.formFields))
          .tickSize(-this.width)

        // add the x axis
        const xAxis = this.svg.append('g')
          .attr('class', 'bar-chart--gridlines')
          .call(xGridlines)
          
        xAxis.selectAll('text')
          .attr('x', -10)
          .attr('class', 'bar-chart--label-usage-axis') //move labels to the left
        xAxis.select(".domain").remove() //remove the y axis line
      },

      buildYAxis() {
        const yAxis = this.svg.append("g")
          .attr('class', 'bar-chart--x-tick')
          .attr("transform", "translate(0," + this.height + ")")
          .call(d3.axisBottom(this.x))
        
        yAxis.selectAll('text').attr('y', 6)
          .attr('class', 'bar-chart--label-usage-axis')

        yAxis.select(".domain").remove();
      },

       buildBarChart() {
        this.addBars();
        // this.addCounts();
      },

      addBars() {
        // append the rectangles for the bar chart
        this.svg.selectAll(".bar-chart--bar")
          .data(this.formFields)
          .enter().append("rect")
          .attr("class", "bar-chart--bar")
          .attr('opacity', 1)
          .attr("x",  (d) => { return this.x(d.group) + 9 })
          .attr("width", this.x.bandwidth() - 18)
              .attr("y",  (d) => this.height)
              .attr("height", 0)
              .transition()
              .duration(500)
              .delay((d, i) => i * 20)
          .attr("y",  (d) => this.y(d.count))
          .attr("height",  (d) => { return this.height - this.y(d.count) });
      },

      calculateTicksNumber(data) {
        const countsByReport = data.map(report => report.count);
        const maxCount = Math.max(...countsByReport);

        if (maxCount <= 2) {
          return 2
        } else if (maxCount <= 4) {
          return 3
        } else {
          return 8
        }
      },
    }

  }
</script>
