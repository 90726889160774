<template>
  <div :class="[classesWrapper, { 'field_with_errors': false }]">

    <input
      :name="name"
      @keyup="resetDebounce"
      @change="resetDebounce"
      v-model="input"
      type="number" 
      :min="min"
      :max="max"
      :step="step"
      :class="classes"
    />
  </div>
</template>

<script>
import { eventHub } from '../../ibat.js'

export default {
  name: 'number-input',

  props: {
    name: { required: true, type: String },
    min: { default: 1, type: Number },
    max: { default: 8000, type: Number },
    step: { default: 1, type: Number },
    initialValue: { default: 1, type: Number },
    classesWrapper: String,
    classes: String,
  },

  data: function() {
    return {
      input: this.initialValue,
      debounceFunction: null,
      minimumInput: this.min,
      maximumInput: this.max
    }
  },

  methods: {
    resetDebounce: function(event) {
      clearTimeout(this.debounceFunction);

      this.debounceFunction = setTimeout(() => { this.updateBuffer(event.target.value) }, 300);
    },

    updateBuffer: function(bufferValue) {
      const buffer = Number(bufferValue);

      this.$emit('input', buffer)
    }
  }
}
</script>
