<template>
  <div class="inline-block margin-space-right-small" v-if="isDateFilter || hasOptions">
    <div
      @click="openSelect()"
      class="filter__button"
      :class="{ 'filter__button--active' : isOpen , 'filter__button--has-selected' : hasSelected && type != 'date'}">
      {{ computedTitle }}
      <span v-show="hasSelected && type != 'date'" class="filter__button-total">({{ totalSelectedOptions }})</span>
    </div>

    <div class="filter__options" :class="{ 'filter__options--active' : isOpen }">

      <template v-if="type == 'search'">
        <div class="filter__options--search" :class="filterClass">
          <page-filter-search
            v-on:apply:filter="apply"
            v-on:clear:filter="updateAllTo(false)"
            v-on:selectMultipleOptions="updateAllTo"
            :options="options"
            :name="name"
            :title="title"
            :type="type"
            :selectMultiple="selectMultiple"
            :activeOptions="activeOptions">
          </page-filter-search>
        </div>
      </template>

      <template v-else>
        <page-filter-date v-on:apply:filter="apply">
        </page-filter-date>
      </template>

      <div :class="['filter__buttons', { 'filter__buttons--search': type == 'search' }]">
        <button @click="updateAllTo(false)" class="button--link bold float-left">Clear</button>
        <button @click="cancel()" class="button--link">Cancel</button>
        <button @click="apply()" class="button--link button--link--green bold">Apply</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  import PageFilterOption from './PageFilterOption.vue'
  import PageFilterSearch from './PageFilterSearch.vue'
  import PageFilterDate from './PageFilterDate.vue'

  export default {
    name: 'page-filter',

    components: { PageFilterOption, PageFilterSearch, PageFilterDate },

    props: {
      title: {
        required: true,
        type: String
      },
      name: String,
      options: Array,
      type: String,
      selectMultiple: Object // { filterBy: String, option: String }
    },

    data () {
      return {
        children: this.$children,
        isOpen: false,
        activeOptions: [],
        dateRange: null
      }
    },

    computed: {
      computedTitle() {
        if (this.type === 'date' && this.children.length > 0 && this.children[0].hasDate()) {
          const [from, to] = this.children[0].datesArray

          return `${this.formatDate(from)} - ${this.formatDate(to)}`
        } else {
          return this.title
        }
      },
      // set a flag for theme options that belong to the theme filter
      // this is used to prefilter the table for an individual theme
      isThemeFilter () {
        return this.name === 'themes'
      },

      isDateFilter() {
        return this.type === 'date'
      },

      hasOptions () { return this.options && (this.options.length > 0) },

      selectedOptions () {
        let selection = []

        if (this.hasOptions) {
          this.children.forEach(child => {
            if(this.type == 'boolean' && child.isSelected != null) {
              selection.push(child.isSelected)
            } else if (this.type == 'search') {
              child.children.forEach(child => {
                if (child.isSelected) { selection.push(child.option.id); };
              })
            } else {
              if (child.isSelected) { selection.push(child.option.id); };
            }
          })
        } else if (this.type === 'date' && this.children.length > 0) {
          selection = this.children[0].datesArray
        }

        return selection
      },

      hasSelected () {
        return this.totalSelectedOptions > 0
      },

      totalSelectedOptions () {
        return this.selectedOptions.length
      },

      filterClass () {
        return 'filter__options--' + this.name.replace('_| |(|)', '-').toLowerCase()
      }
    },

    // beforeDestroy() {
    //   this.reset(false)
    // },

    methods: {
      formatDate(date) {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        const yy = date.getFullYear().toString().slice(2);

        if (dd < 10) { dd = '0' + dd }
        if (mm < 10) { mm = '0' + mm }

        return `${dd}/${mm}/${yy}`
      },

      openSelect () {
        if(this.isOpen){
          this.closeSelect();
        } else {
          eventHub.$emit('clickDropdown', this.name)
        }
      },

      closeSelect () {
        this.isOpen = false
      },

      cancel() {
        this.closeSelect()

        if(this.hasOptions) {
          // reset each option to the correct state
          this.children.forEach(child => {
            if(this.type == 'boolean') {
              child.isSelected = this.activeOptions[0]
            } else if(this.type == 'search') {
              child.children.forEach(kid => {
                kid.isSelected = this.activeOptions.indexOf(kid.option.id) > -1 ? true : false
              })
              child.searchTerm = ''
            } else {
              child.isSelected = this.activeOptions.indexOf(child.option.id) > -1 ? true : false
            }
          })
        } else if (this.type == 'date') {
          this.children[0].resetDate(this.activeOptions)
        }
      },

      updateAllTo (condition) {
        if(this.hasOptions) {
          if(typeof condition === 'boolean') {
            // set the isSelected property on all options to true/false
            const boolean = condition

            this.children.forEach(child => {
              if(this.type == 'boolean') {
                child.isSelected = condition ? true : null
                return false
              } else if(this.type == 'search') {
                child.$children.forEach(child => {
                  child.isSelected = boolean
                })
                child.searchTerm = ''
              } else {
                child.isSelected = boolean
              }
            })
          } else {
            // set the isSelected property true/false depending on whether it statisfies the criteria
            this.children.forEach(child => {
              if(this.type == 'boolean') { return false
              } else if(this.type == 'search') {
                child.children.forEach(child => {
                  if(condition.propName in child && child[condition.propName]) {
                    child.isSelected = condition.select
                  }
                })
                child.searchTerm = ''
              } else {
                if(condition.propName in child && child[condition.propName]) {
                  child.isSelected = condition.select
                }
              }
            })
          }
        } else if (this.type == 'date') {
          this.children[0].clearDate()
        }
      },

      apply (shouldGetItems = true) {
        this.closeSelect()
        eventHub.$emit('clearSearch')
        this.$store.commit('pagination/resetItemIds')

        if(this.type == 'search') { eventHub.$emit('resetSearchTerm') }

        //update the active filters array
        this.activeOptions = this.selectedOptions

        this.$store.commit('pagination/updateRequestedPage', 1)

        const newFilterOptions = {
          filter: this.name,
          options: this.activeOptions
        }

        this.$store.dispatch('pagination/updateFilterParameters', newFilterOptions)
        if (shouldGetItems) { eventHub.$emit('getNewItems'); };
      },

      clear () {
        this.updateAllTo(false)
      },

      reset (shouldGetItems = true) {
        this.updateAllTo(false)
        this.apply(shouldGetItems)
      }
    }
  }
</script>
