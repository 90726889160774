<template>
  <div :class="[classesWrapper, { 'field_with_errors': false }]">
    <p class="form__validation-message" v-show="shouldShowError">Please enter a name (must not include '/' or '\')</p>
    <input :name="name" type="text" :value="value" @input="updateInput" v-bind="{ 'disabled': isDisabled }" :class="classes">
  </div>
</template>

<script>
  export default {
    props: {
      name: { required: true },
      value: { type: String, default: '' },
      classes: String,
      classesWrapper: String,
      onInput: { required: false, type: Function },
      showValidationError: { type: Boolean, default: false },
      isDisabled: { type: Boolean, default: false }
    },

    computed: {
      isEnabled() {
        return !this.isDisabled
      },

      shouldShowError() {
        return this.showValidationError && this.isEnabled
      }
    },

    methods: {
      updateInput(event) {
        if (this.onInput) { this.onInput(event.target.value) }
        this.$emit('input', event.target.value)
      }
    }
  }
</script>
