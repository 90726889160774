<template>
  <div class="carousel__slide">
    <div class="slide__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'carousel-slide'
  }
</script>