<template>
  <div>
    <p
      class="form__validation-message"
      v-show="showValidationError"
    >{{ errorMessage }}</p>

    <p class="no-margin select--chevron" v-if="showVselect">
      <v-select
      @input="onChange"
      class="select multiselect"
      v-model="selectedOption"
      :close-on-select="true"
      label="title"
      :options="options"
      >
      </v-select>
    </p>

    <p class="no-margin select--chevron" v-else>
      <select
        class="select"
        v-model="selectedOption"
        @change="onChange"
      >
        <option
          selected
          disabled
        >
          Select an option
        </option>
        <option
          v-for="option in options"
          :value="getValue(option)"
          :key="option.id"
          class="select__option"
        >
          {{ getOptionTitle(option) }}
        </option>
      </select>
    </p>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
export default {
  name: 'single-select',
  components: { vSelect },
  props: {
    options: {
      required: true
    },
  
    valueAttribute: {
      type: String,
      default: undefined
    },

    titleAttribute: {
      type: String,
      default: 'title'
    },

    value: {
      required: false
    },

    showValidationError: {
      required: false
    },

    errorMessage: {
      type: String,
      default: "Please select an option"
    },
    showVselect : {
      type : Boolean,
      required :  false
    }
  },

  data () {
    return {
      selectedOption: this.value
    }
  },

  methods: {
    onChange () {
      this.$emit('input', this.selectedOption)
    },

    getOptionTitle (option) {
      return option[this.titleAttribute]
    },

    getValue (option) {
      if (this.valueAttribute) {
        return option[this.valueAttribute]
      }

      return option
    }
  }
}
</script>
