<template>
  <button class="button--pagination" :class="{ 'button--disabled' : !isActive }" v-bind="{ 'disabled' : !isActive }" @click="changePage">
    <slot></slot>
  </button>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'pagination-button',

    props: {
      type: { 
        type: String,
        required: true 
      },
      totalPages: {
        required: true,
        type: Number
      }
    },

    computed: {
      isActive () {
        const currentPage = this.$store.state.pagination.currentPage

        return (this.type === 'previous' && currentPage > 1) || (this.type === 'next' && currentPage < this.totalPages)
      }
    },

    methods: {
      changePage () {
        const currentPage = this.$store.state.pagination.currentPage

        if (!this.isActive) return false

        let page

        if (this.type === 'previous' && currentPage > 1) {
          page = currentPage - 1

        } else if (this.type === 'next' && currentPage < this.totalPages) {
          page = currentPage + 1
        }

        this.$store.commit('pagination/updateRequestedPage', page)
        eventHub.$emit('getNewItems')
      }
    }
  }
</script>
