<template>
  <div>
    <modal modal-id="user-edit-field" modal-classes="modal--user-edit-field">
      <form @submit.prevent="updateUser">
        <div v-if="options">
          <label :for="attributeFieldId">{{ label }}</label> 
          <select :id="attributeFieldId" v-model="selectedOption">
            <option selected disabled> Select an option </option>
            <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
          </select>
        </div>

        <div v-else-if="isPasswordChange" >
          <div :class="['form__field', 'user-edit-field--form-field', { 'field_with_errors': hasAttributeError }]">
            <label :for="`update-${attribute}`">New {{ label }}</label>
            <br/><em>Length should be 8-70 characters and include at least the following: 1 uppercase, 1 lowercase, 1 digit and 1 among these special characters (# ? ! @ $ % ^ & * -)</em><br />
            <input v-model="attrToUpdate" :id="`update-${attribute}`" type="password">
          </div>

          <div :class="['form__field', 'user-edit-field--form-field', { 'field_with_errors': hasAttributeError }]">
            <label :for="`update-${attribute}-confirm`">Confirm {{ label }}</label>
            <input v-model="attrToUpdateConfirm" :id="`update-${attribute}-confirm`" type="password">
          </div>
        </div>

        <div v-else :class="['form__field', 'user-edit-field--form-field', { 'field_with_errors': hasAttributeError }]">
          <label :for="`update-${attribute}`">{{ label }}</label>
          <input v-model="attrToUpdate" :id="`update-${attribute}`" type="text">
          
          <span v-if="config.option_types">
            <label>Organisation Type</label>
            <select v-model="selectedType" >
              <option v-if="!selectedType" disabled selected value="">Select Type</option>
              <option v-for="option in config.option_types" :key="option.id" :value="option.id">{{ option.name }}</option>
            </select>
          </span>
        </div>

        <div v-if="passwordRequired" :class="['form__field', 'user-edit-field--form-field', { 'field_with_errors': hasPasswordError }]">
          <label :for="passwordFieldId">Password</label>
          <input v-model="current_password" :id="passwordFieldId" type="password">
        </div>

        <div class="user-edit-field--buttons">
          <button type="submit" :class="['button--cta-small margin-space-right', { 'button--disabled': !formComplete}]" :disabled="!formComplete">Update</button>
          <a @click.prevent="closeModal" href="#" class="display-block">Cancel</a>
        </div>
      </form>

    </modal>
  </div>
</template>

<script>
import { eventHub } from '../../ibat.js';
import helpers from '../../utilities/helpers.js'
import FlashProvider from '../../utilities/flash-provider.js'

import Modal from '../modal/Modal.vue';

export default {
  components: { Modal },

  data() {
    return {
      config: {},
      attrToUpdate: '',
      attrToUpdateConfirm: '',
      current_password: '',
      selectedOption: null,
      fieldsWithErrors: [],
      selectedType: ""
    }
  },

  created() {
    eventHub.$on('requestEditModalOpen', this.displayModal)
    eventHub.$on('modalClosed', this.handleModalCloseRequest)
  },

  destroyed() {
    eventHub.$off('requestEditModalOpen', this.displayModal)
    eventHub.$off('modalClosed', this.handleModalCloseRequest)
  },

  computed: {
    attribute() {
      return this.config.attribute
    },

    selectedTypeId() {
      return this.config.organisation_type_id
    },

    label() {
      return this.config.label
    },

    options() {
      return this.config.options
    },

    onClose() {
      return this.config.onClose
    },

    passwordRequired() {
      return this.config.passwordRequired
    },

    isPasswordChange() {
      return this.attribute === 'password';
    },

    attributeFieldId() {
      return `update-${this.attribute}-${helpers.randomNumber()}`;
    },

    passwordFieldId() {
      return `old-password-${helpers.randomNumber()}`;
    },

    hasAttributeError() {
      return this.fieldsWithErrors.includes(this.attribute);
    },

    hasPasswordError() {
      return this.fieldsWithErrors.includes('current_password')
    },

    url() {
      return this.config.url
    },

    resource() {
      return this.config.resource
    },

    formComplete() {
      if (this.options) {
        if (this.passwordRequired) {
          return this.selectedOption && this.current_password.length > 0;
        } else {
          return !!this.selectedOption
        }
      } 
      else {
        if (this.passwordRequired) {
          return this.attrToUpdate.length > 0 && this.current_password.length > 0;
        } 
        else if (this.config.option_types) {
          return this.attrToUpdate.length > 0 && this.selectedType !== ""
        }
        else {
          return this.attrToUpdate.length > 0
        }
      }
    },

    params() {
      let resource = {
        [this.attribute]: this.options ? this.selectedOption.id : this.attrToUpdate,
      }

      if (this.passwordRequired) {
        resource = Object.assign({}, resource, { current_password: this.current_password })
      }

      if (this.isPasswordChange) {
        resource = Object.assign({}, resource, { password_confirmation: this.attrToUpdateConfirm })
      }
      
      if (this.config.option_types) {
        resource = Object.assign({}, resource, { organisation_type_id: this.selectedType , is_personal: false })
      }

      const params = {};
      params[this.resource] = resource;

      return params
    }
  },

  methods: {
    displayModal(config) {
      this.config = config;
      eventHub.$emit('requestModalOpen', 'user-edit-field')

      if(config.organisation_type_id){
        this.selectedType = config.organisation_type_id
      }
    },

    closeModal() {
      this.config = {};
      eventHub.$emit('requestModalClose', 'user-edit-field')
    },

    updateUser() {
      this.$http.put(this.url, this.params)
        .then(res => {
          this.resetForm(this.isPasswordChange ? false : true);
          FlashProvider.display(res.data.message)
          eventHub.$emit('requestModalClose', 'user-edit-field')
          if (res.data.status === 200) {
            eventHub.$emit('organisation_update', res.data.name, res.data.organisation_type_id )
          }
        })
        .catch(error => {
          this.fieldsWithErrors = error.response.data.fields_with_errors
          FlashProvider.display(error.response.data.message, 'alert')
        })
    },

    resetForm(assignValue) {
      if (assignValue) { this.assignSelectedValueToField(); };
      this.selectedOption = null;
      this.attrToUpdate = '';
      this.current_password = '';
      this.fieldsWithErrors = [];
      this.selectedOption = '';
    },

    assignSelectedValueToField() {
      const newValue = this.options ? this.selectedOption.name : this.attrToUpdate
      
      this.onClose(newValue);
    },

    handleModalCloseRequest(modalId) {
      if ('user-edit-field' === modalId) { this.resetForm(false); };
    }
  }
}
</script>
