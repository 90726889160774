<template>
  <div class="pie-chart-wrapper" :class="{ star: /(star)/.test(id) }">
    <h3 class="margin-space-top">
      {{ chartTitle }}
    </h3>

    <pie-chart
      :data="formatData()"
      :colours="chartColours()"
      :dimensions="setDimensions()"
      :id="id"
    ></pie-chart>

    <template v-if="id === 'wdpa' || id === 'kba'">
      <div class="flex">
        <div><i :class="['icon--' + id]"></i></div>
        <p>
          {{ primaryCount }} ({{ primaryPercentageNumber.toFixed(2) }}% of
          sites) are within {{ buffer }} of a {{ areaType(true) }}.
        </p>
      </div>
      <div class="flex">
        <div><i class="icon--grey"></i></div>
        <p>
          {{ secondaryCount }} ({{ secondaryPercentageNumber.toFixed(2) }}% of
          sites) are not within {{ buffer }} of a {{ areaType(true) }}.
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import PieChart from './PieChart'

export default {
  name: 'pdf-pie-chart',

  components: {
    PieChart,
  },

  props: {
    id: {
      type: String,
      required: true
    },
    primaryCount: {
      type: String,
      default: '0'
    },
    primaryPercentage: {
      type: [Number, String],
      required: true
    },
    secondaryCount: {
      type: String,
      default: '0'
    },
    secondaryPercentage: {
      type: [Number, String],
      required: true
    },
    starAreas: {
      type: Object
    },
    buffer: {
      type: String,
      default: ''
    }
  },

  computed: {
    primaryPercentageNumber() {
      return Number(this.primaryPercentage)
    },

    secondaryPercentageNumber() {
      return Number(this.secondaryPercentage)
    },

    chartTitle() {
      if (this.id === 'wdpa' || this.id === 'kba') {
        return `% Summary of ${this.areaType()}s overlap`
      } else {
        if (this.starAreas.region.name === 'Global') {
          return `${this.starAreas.aoi.name}'s total threat abatement score as a 
          proportion of the ${this.starAreas.region.name.toLowerCase()} total threat abatement score`
        }
        return `${this.starAreas.aoi.name}'s total threat abatement score as a 
        proportion of ${this.starAreas.region.name}'s total threat abatement score`
      }
    }
  },

  methods: {
    areaType(full = false) {
      return this.id === 'wdpa' ? 'protected area' : this.kbaType(full)
    },

    chartColours() {
      return {
        primaryColour: this.primaryColor(),
        secondaryColour: this.secondaryColor()
      }
    },

    kbaType(full) {
      return full ? 'Key Biodiversity Area' : 'KBA'
    },

    formatData() {
      let data = this.addCountAndPercentage()

      if (this.starAreas) {
        if (data.hasOwnProperty('primary')) {
          data.primary.label = this.starAreas.aoi.name
        }

        if (data.hasOwnProperty('secondary')) {
          data.secondary.label = this.starAreas.region.name
        }
      }

      return data
    },

    addCountAndPercentage() {
      if (this.primaryPercentageNumber === 0) {
        return {
          secondary: {
            count: this.secondaryCount,
            percentage: this.secondaryPercentageNumber
          }
        }
      } else if (this.primaryPercentageNumber === 100) {
        return {
          primary: {
            count: this.primaryCount,
            percentage: this.primaryPercentageNumber
          }
        }
      } else {
        return {
          primary: {
            count: this.primaryCount,
            percentage: this.primaryPercentageNumber,
          },
          secondary: {
            count: this.secondaryCount,
            percentage: this.secondaryPercentageNumber
          }
        }
      }
    },

    primaryColor() {
      if (this.id === 'wdpa') {
        return '#79A04C'
      } else if (this.id === 'kba') {
        return '#389CD7'
      } else {
        return this.starAreas.aoi.colour
      }
    },

    secondaryColor() {
      if (this.id === 'wdpa' || this.id === 'kba') {
        return "#C0C0C0"
      } else {
        return this.starAreas.region.colour
      }
    },

    setDimensions() {
      if (this.mapType === 'wdpa' || this.mapType === 'kba') {
        return {
          width: 312,
          height: 260,
          margin: 30
        }
      } else {
        return {
          width: 312,
          height: 200,
          margin: 30
        }
      }
    }
  }
}
</script>
