<template>
  <div style="margin-top: 34px; margin-bottom: 30px;">
    <div class="flex flex-h-between desktop-only">
      <span class="list-item--heading-flex-1"></span>
      <span class="list-item--heading-flex-1 list-item--desktop-only margin-space-left">ID</span>
      <span class="list-item--heading-flex-4">Site Name</span>
      <span class="list-item--heading-flex-3">
        <div class="flex">
          <tooltip
            class="tooltip"
            :text="countryTooltipText">
            <span class="column-title">Country</span>
          </tooltip>
        </div>
      </span>
      <span class="list-item--heading-flex-4">Download Type</span>
      <span class="list-item--heading-flex-3">Created By</span>
      <span class="list-item--heading-flex-2">Created<page-sort sort-key="created_at"></page-sort></span>
      <span class="list-item--heading-flex-1"></span>
    </div>

    <page-item-data-download v-for="(item, index) in items" :item="item" :key="generateKey(index)" :can-delete="canDelete"></page-item-data-download>

    <p v-show="noResults" class="center section-padding">You haven't downloaded anything yet.</p>

  </div>

</template>

<script>
  import PageSort from './PageSort.vue';
  import PageItemDataDownload from '../pagination/PageItemDataDownload.vue';

  import { eventHub } from '../../ibat.js';
  import Tooltip from '../tooltip/Tooltip.vue';
  import { TooltipTexts } from '../../mixins/mixin-tooltip-texts.js';
  import { mixinPaginationGroup } from '../../mixins/mixin-pagination-group.js';
  import helpers from '../../utilities/helpers.js';

  export default {
    name: 'page-my-downloads',

    components: { PageItemDataDownload, PageSort, Tooltip },

    mixins: [ mixinPaginationGroup, TooltipTexts ],

    props: {
      canDelete: { type: Boolean, default: false }
    },

    methods: {
      generateKey(string) {
        return helpers.generateKey(string)
      }
    }
  }
</script>
