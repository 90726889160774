<template>
  <div style="display: none;">
  </div>
</template>

<script>

export default {

  props: {
    user: { required: true, type: Object }
  },

  created: function() {
    this.$store.dispatch('setUser', this.user)
  },
}
</script>
