import { eventHub } from '../ibat.js'

export const storeMap = {
  namespaced: true,

  state: {
    map: {},
    bufferAmount: 1,
    downloadArea: 0,
    geometry: {},
    geometryType: '',
    markerHasError: false,
    markerErrorMsg: '',
    latlng: [],
    activeSites: [],
    downloadFeature: '',
    downloadSite: null,
  },

  getters: {
    geometry (state) {
      return state.geometry
    }
  },

  actions: {
    updateSite ({ dispatch, commit, state }, site) {
      if('geometry' in site) { commit('updateGeometry', site.geometry) }
      if('type' in site) { commit('updateGeometryType', site.type) }
    },

    resetSite ({ dispatch, commit }) {
      commit('updateGeometry', {})
      commit('updateGeometryType', '')
      commit('updateLatLng', [])
    },

    updateSelectedSite ({ dispatch, commit, state }, site) {
      commit('updateSelectedSite', site)
      eventHub.$emit('updateSelectedSite', site)
    },

    updateBufferAmount ({ dispatch, commit, state }, buffer) {
      commit('updateBufferAmount', buffer)
      eventHub.$emit('updateBufferAmount')
    },

    updateDownloadFeature ({ dispatch, commit, state }, feature) {
      commit('updateDownloadFeature', feature)
      eventHub.$emit('updateDownloadFeature', feature)
    },

    updateDownloadArea ({ dispatch, commit, state }, area) {
      commit('updateDownloadArea', area)
      eventHub.$emit('updateDownloadArea')
    },

    clearDownloadMap ({ dispatch, commit }) {
      commit('updateBufferAmount', 1)
      commit('updateDownloadArea', null)
      commit('updateDownloadFeature', null)
      commit('updateDownloadSite', null)
      eventHub.$emit('clearDownloadMap')
    }
  },

  mutations: {
    storeMap (state, map) {
      this.state.map.map = map
    },

    updateSelectedSite (state, site) {
      this.state.map.site = site
    },

    updateBufferAmount (state, bufferAmount) {
      this.state.map.bufferAmount = bufferAmount
    },

    updateDownloadArea (state, area) {
      this.state.map.downloadArea = area
    },

    updateDownloadFeature (state, feature) {
      this.state.map.downloadFeature = feature
    },

    updateDownloadSite (state, site) {
      this.state.map.downloadSite = site
    },

    updateGeometry (state, geometry) {
      this.state.map.geometry = geometry
    },

    updateGeometryType (state, geometryType) {
      this.state.map.geometryType = geometryType
    },

    updateMarkerErrorMsg (state, markerErrorMsg) {
      this.state.map.markerErrorMsg = markerErrorMsg
      this.state.map.markerHasError = true
    },

    resetMarkerErrorMsg () {
      this.state.map.markerErrorMsg = ''
      this.state.map.markerHasError = false
    },

    updateLatLng (state, latlng) {
      this.state.map.latlng = latlng
    },

    updateActiveSites (state, id) {
      let array = this.state.map.activeSites

      if(array.indexOf(id) > -1) {
        array.splice(array.indexOf(id), 1)
      } else {
        array.push(id)
      }

      this.state.map.activeSites = array
    },

    resetActiveSites () {
      this.state.map.activeSites = []
    }
  }
}
