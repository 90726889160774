<template>
  <button class="button--cta" @click="closeTab"><slot></slot></button>
</template>

<script>
  export default {
    name: 'close-tab',

    methods: {
      closeTab () {
        window.close()
      }
    }
  }
</script>