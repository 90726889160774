<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'accordion',

    props: {
      id: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        children: this.$children
      }
    },

    mounted () {
      eventHub.$on(this.id, this.toggleAccordionContent)
    },

    methods: {
      toggleAccordionContent (id) {
        // toggle the accordion content as well as hide layers from inactive accordion items
        this.children.forEach(child => {
          child.isActive = child.id === id && child.isActive !== true

          if(typeof child.toggleId !== 'undefined') { 
            if(child.id === id) {
              eventHub.$emit(child.toggleId, true)
            } else {
              eventHub.$emit(child.toggleId, false)
            }
          }
        })
      }
    }
  }
</script>
