<template>
  <span @click.stop="toggleCheckbox" :class="['checkbox', { 'active': checked  }]">
    
  </span>
</template>

<script>
export default {
  props: {
    readOnly: { type: Boolean, required: false }
  },

  data() {
    return {
      isActive: false
    }
  },

  computed: {
    checked() {
      if (this.readOnly) {
        return true
      } else {
        return this.isActive;
      }
    }
  },

  methods: {
    toggleCheckbox() {
      if (!this.readOnly) {
        this.isActive = !this.isActive;

        this.$emit('toggle', this.isActive)
      }
    }
  }
}
</script>
