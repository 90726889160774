<template>
  <div class="card--dashboard-large--left bg-white">
    <p v-if="!isGettingData && !shouldDisplayEmptyState" class="margin-space-top margin-space-left light">Sites in last year</p>
    <div id="bar-chart"></div>
    <empty-state-message 
      v-if="shouldDisplayEmptyState"
      lineOne="You don't have any projects yet"
      lineTwo="Click here to create some"
      link="/sites">
    </empty-state-message>
    <loader v-if="isGettingData" :width="100" color="light-grey"></loader>
  </div>
</template>

<script>
  import axios from 'axios';
  import * as d3 from 'd3';

  import Loader from '../loading/Loader';
  import EmptyStateMessage from '../empty_state/EmptyStateMessage';

  import { mixinCharts } from'../../mixins/mixin-charts.js';

  export default {

    components: {
      Loader,
      EmptyStateMessage
    },

    mixins: [mixinCharts],

    data() {
      return {
        margin: { top: 20, right: 30, bottom: 30, left: 50 }
      }
    },

    computed: {
      fullHeight() {
        return 300
      },

      height() {
        return this.fullHeight - this.margin.top - this.margin.bottom;
      },

      fullWidth() {
        return 590
      },

      width() {
        return this.fullWidth - this.margin.left - this.margin.right;
      },

      shouldDisplayEmptyState() {	
        return !this.isGettingData && (this.chartData.length < 1 || !this.countsExist);	
      },

      countsExist() {
        const totalCount = this.chartData.reduce((total, group) => {
          return total + group.count
        }, 0);

        return totalCount > 0;
      },
    },

    methods: {
      renderChart() {
        this.createSvg();
        this.createData();
        this.buildAxes();
        this.buildBarChart();
        this.addEventListeners();
      },

      createSvg() {
        this.svg = d3.select(this.selector)
          .append("svg")
            .attr('class', 'chart--svg')
            .attr('xmlns', 'http://www.w3.org/1999/xhtml')
            .attr('viewBox', '0 0 ' + this.fullWidth + ' ' + this.fullHeight)
            .attr('preserveAspectRatio', 'xMidYMid meet')
          .append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
      },

      createData() {
        this.createXData();
        this.createYData();
      },

      createXData() {
        const width = this.width
        const x = d3.scaleBand().range([0, width]).padding(0.1);

        x.domain(this.chartData.map(d => { return d.group }));

        this.x = x;
      },

      createYData() {
        const height = this.height;
        const y = d3.scaleLinear().range([height, 0]);

        y.domain([0, d3.max(this.chartData, (d) => { return d.count })]);

        this.y = y;
      },

      buildAxes() {
        this.buildXAxis();
        this.buildYAxis();
      },

      buildXAxis() {
        // Create x gridlines
        const xGridlines = d3.axisLeft(this.y)
          .ticks(this.calculateTicksNumber(this.chartData))
          .tickSize(-this.width)

        // add the x axis
        const xAxis = this.svg.append('g')
          .attr('class', 'bar-chart--gridlines')
          .call(xGridlines)
          
        xAxis.selectAll('text')
          .attr('x', -10)
          .attr('class', 'bar-chart--label-axis') //move labels to the left
        xAxis.select(".domain").remove() //remove the y axis line
      },

      buildYAxis() {
        const yAxis = this.svg.append("g")
          .attr('class', 'bar-chart--x-tick')
          .attr("transform", "translate(0," + this.height + ")")
          .call(d3.axisBottom(this.x))
        
        yAxis.selectAll('text').attr('y', 15)
          .attr('class', 'bar-chart--label-axis')

        yAxis.select(".domain").remove();
      },

       buildBarChart() {
        this.addBars();
        this.addCounts();
      },

      addBars() {
        // append the rectangles for the bar chart
        this.svg.selectAll(".bar-chart--bar")
          .data(this.chartData)
          .enter().append("rect")
          .attr("class", "bar-chart--bar")
          .attr('opacity', 1)
          .attr("x",  (d) => { return this.x(d.group) + 9 })
          .attr("width", this.x.bandwidth() - 18)
              .attr("y",  (d) => this.height)
              .attr("height", 0)
              .transition()
              .duration(500)
              .delay((d, i) => i * 20)
          .attr("y",  (d) => this.y(d.count))
          .attr("height",  (d) => { return this.height - this.y(d.count) });
      },

      addCounts() {
        // add labels to top of bars but hide them
        this.svg.selectAll(".bar-chart--label")        
          .data(this.chartData)
          .enter()
          .append("text")
          .attr('opacity', 0)
          .attr("class", "bar-chart--label")
          .attr("x", (d) => { 
            return this.x(d.group) + (this.x.bandwidth() / 2) - this.barLabelPadding(d.count); 
          })
          .attr("y",  (d) => this.height)
          .text(d => d.count)
          .attr("y",  (d) => { return this.y(d.count) - 4 });
      },

      addEventListeners() {
        this.addMouseover();
        this.addMouseleave();
      },

      addMouseover() {
        this.svg.selectAll(".bar-chart--bar").on('mouseover', (hoveredBar, index, allBars) => {
          // make the label for the hovered bar visible
          this.svg.selectAll('.bar-chart--label')        
          .transition()
          .duration(500)
          .attr('opacity', (bar) => { return bar === hoveredBar ? 1 : 0; })

          // make the hovered bar more opaque than the others
          d3.selectAll('.bar-chart--bar')
            .transition()
            .duration(500)
            .attr('opacity', (bar) => { return bar === hoveredBar ? 1 : 0.7; });
        })
      },

      addMouseleave() {
        this.svg.selectAll(".bar-chart--bar").on('mouseleave', (month, index, allBars) => {
          // hide all bar labels
          this.svg.selectAll(".bar-chart--label")
            .transition()
            .duration(500)
            .attr('opacity', 0)

          // increase the opacity of all bars
          d3.selectAll('.bar-chart--bar')
            .transition()
            .duration(500)
            .attr('opacity', 1)
        });
      },

      barLabelPadding(count) {
        const digitCount = String(count).length

        return digitCount + (digitCount * 2.5)
      },

      calculateTicksNumber(data) {
        const countsByMonth = data.map(month => month.count);
        const maxCount = Math.max(...countsByMonth);

        if (maxCount <= 2) {
          return 2
        } else if (maxCount <= 4) {
          return 3
        } else {
          return 5
        }
      },
    }

  }
</script>
