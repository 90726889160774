<template>
  <div class="list-table--outer">
    <div class="list-table--inner">
      <table class="list-table--table">
        <thead>
          <tr class="bold">
            <th v-for="(item, index) in columns" :key="item.Header" :class="index==0 ? 'fix--header' : '' " >
              {{ item.Header }} 
              <span v-if="item.sorting" @click="() => handleSorting(item)"><i class="icon-sort "></i></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="business in businessData" :key="business.id">
            <template v-for="(column, index) in columns">
              <th v-if="index== 0" class="fix" :key="index">
                <a :href="linkGenerate(business, column)">{{ business[column.value][column.child_key] }}</a>
              </th>
              <td :key="index+2" v-else >
                <span v-if="column.link">
                  <a :href="linkGenerate(business, column)" v-if="column.type === 'obj'">
                    {{ business[column.value][column.child_key] }}
                  </a>
                  <span v-else-if="tabs === 'organisation_user' && column.Header === '' ">
                    <a data-method="get" :href="`${business.details.user_url}/resend_invitation?`">Resend Invitation</a>
                  </span>
                  <template v-else-if="column.value === 'action'">
                    <a :href="linkGenerate(business, column)" >Edit</a>
                    <a data-method="patch" :href="`${business.details.user_url}?user[last_sign_in_at]=${new Date().toISOString().slice(0, 10)}`" v-if="tabs === 'users' || tabs === 'organisation_user' ">Renew Access</a>
                  </template>
                  <template v-else-if="Array.isArray(business[column.value])">
                    <a href="/admin/sectors/" v-if="business[column.value].length > 0" v-for="sector in business[column.value]" :key="sector.id">
                      {{ sector.name }}
                    </a>
                    <a v-else></a>
                  </template >
                  <a href="/" v-else>
                    {{ business[column.value] }}
                  </a>
                </span>
                <span v-else-if="column.type === 'obj'">
                  {{ business[column.value][column.child_key] }}
                </span>
                <span v-else-if="Array.isArray(business[column.value])">
                  <span v-if="business[column.value].length > 0" v-for="sub_sector in business[column.value]" :key="sub_sector.id">
                    {{ sub_sector.name }}
                  </span>
                </span>
                <span v-else>
                  {{ business[column.value] }}
                </span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import  { organisations, users, organisation_user } from "./column.js"

  export default {
    name: "sorting-data",

    components: {},

    props: {
      businesses: {
        type: Array,
        required: true,
      },
      tabs: {
        type : String,
        required : true,
      }
    },

    data() {
      return {
        businessData: this.businesses,
        asc: true,
        searchParams: this.params,
        columns : []
      };
    },

    mounted() {
      this.columns =  this.tabs === 'organisation' ? organisations : this.tabs === 'organisation_user' ? organisation_user : users
    },

    methods: {
      linkGenerate(data, column) {
        if(["Business", "Organisation"].includes(column.Header)) {
          return data.details.business_url
        } else if(["User", "Owner"].includes(column.Header)) {
          return data.details.user_url
        } else if(column.Header === 'Edit') {
          return data.details.edit_url
        }
      },

      compareDates(valueA, valueB) {
        const convertDate = (str) => {
            const [day, monthStr, year] = str.split('/');
            const month = {
                Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
                Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
            }[monthStr];
            return new Date(`20${year}-${month}-${day}`).getTime();
        };
        let dateA = convertDate(valueA);
        let dateB = convertDate(valueB);
        return this.asc ? dateA - dateB : dateB - dateA;
      },

      compareNumbers(valueA, valueB) {
        return this.asc ? valueA - valueB : valueB - valueA;
      },

      compareStrings(valueA, valueB) {
        valueA = String(valueA).toLocaleLowerCase();
        valueB = String(valueB).toLocaleLowerCase();
        return valueA.localeCompare(valueB) * (this.asc ? 1 : -1);
      },

      keySorting(key, a, b, valueType, childKey) {
        let valueA, valueB;

        if (valueType === "obj" && childKey) {
            valueA = a[key][childKey];
            valueB = b[key][childKey];
        } else {
            valueA = a[key];
            valueB = b[key];
        }

        // Handle 'N/A', null, or empty string
        if (valueA === 'N/A' || valueA === null || valueA === "") return -1;
        if (valueB === 'N/A' || valueB === null || valueB === "") return 1;

        // Delegate to helper functions based on valueType
        switch (valueType) {
            case "date":
                return this.compareDates(valueA, valueB);
            case "num":
                return this.compareNumbers(valueA, valueB);
            default: // Handle strings and other types
                return this.compareStrings(valueA, valueB);
        }
      },

      handleSorting(column) {
        this.asc = !this.asc; // Toggle the sorting order
        this.businessData.sort((a, b) => {
            return this.keySorting(column.value, a, b, column.type, column.child_key);
        });
      }
    }
  }
</script>
