<template>
  <div>
    <template v-for="filters, index in filtersArray">

      <template v-if="hasSubsets(filters)">
        <accordion
          :id="accordionId(filters.name)"
          class="accordion--standard"
        >
          <accordion-item
            :id="accordionItemId()"
            :accordionId="accordionId(filters.name)"
            :title="filters.name"
            :hasModal="hasDescription(filters)"
            :modalContent="filters.information"
          >

            <accordion
              :id="accordionId(filters.name + '-subsets')"
              class="accordion--radio"
            >
              <accordion-item
                v-for="subset, index in filters.subsets"
                :key="index"
                :id="accordionItemId()"
                :accordionId="accordionId(filters.name + '-subsets')"
                :title="subset.name"
                :toggleId="toggleId(subset.name)"
                :hasModal="true"
                :modalContent="subset.information"
              >

                <filter-layers
                  :key="index"
                  :id="subset.id"
                  :toggleId="toggleId(subset.name)"
                  :name="subset.name"
                  :datasetName="datasetName(filters)"
                  :layers="subset.layers"
                  :search="search"
                  :layerGroup="layerGroup"
                >
                </filter-layers>
              </accordion-item>
            </accordion>
          </accordion-item>
        </accordion>
      </template>

      <template v-else>
        <filter-layers
          :id="filters.id"
          :name="filters.name"
          :layers="filters.layers"
          :search="search"
          :layerGroup="layerGroup"
        >
        </filter-layers>
      </template>

    </template>
  </div>
</template>

<script>
import helpers from '../../utilities/helpers.js'
import Accordion from '../accordion/Accordion.vue'
import AccordionItem from '../accordion/AccordionItem.vue'
import FilterLayers from './FilterLayers.vue'
import Modal from '../modal/Modal.vue'

export default {
  name: 'filters',

  components: { Accordion, AccordionItem, FilterLayers, Modal },

  props: {
    filters: {
      type: Array
    },
    search: {
      type: Boolean
    },
    layerGroup: {
      required: true,
      type: Object
    }
  },

  computed: {
    filtersArray () {
      return this.filters
    }
  },

  methods: {
    accordionId (name) {
      return 'acc-' + helpers.spacesToKebab(name)
    },

    accordionItemId () {
      return 'acc-item-' + helpers.randomNumber()
    },

    toggleId (name) {
      return 'filters-' + helpers.spacesToKebab(name)
    },

    hasSubsets (filters) {
      return filters.subsets
    },

    datasetName (filters) {
      // Sites of Biodiversity Importance pop-up title
      // to be renamed as Key Biodiversity Areas
      const layer = filters.name.indexOf('Sites') >= 0
      return layer ? 'Key Biodiversity Areas' : filters.name
    },

    hasDescription (filters) {
      return !!filters.information
    },
  }
}
</script>
