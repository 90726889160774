<template>
  <div class="dropdown__trigger" @click="clicked">
    <slot></slot><i class="flex-hs-end breakpoint-small-up" :class="[iconClass]"></i>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'dropdown-trigger',

    data () {
      return {
        isClosed: true
      }
    },

    methods: {
      clicked () {
        this.isClosed = !this.isClosed
        eventHub.$emit('dropdownClicked')
      }
    },

    computed: {
      iconClass () {
        return this.isClosed ? 'icon-chevron-down' : 'icon-chevron-up'
      }
    }
  }
</script>
