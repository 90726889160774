<template>
  <div class="filters flex flex-column">
    <filter-search
      v-if="search"
      :searchEvent="searchEvent"
    ></filter-search>

    <button
      v-if="hasNoMutuallyExclusiveLayers"
      @click.prevent="toggleGroup"
      class="filters__button flex-h-end button--outline-white button--medium"
    >{{ toggleButton }}</button>

    <v-filter
      v-for="layer in layersArray"
      :key="layer.id"
      :event="event"
      :id="layer.id"
      :name="layer.name"
      :datasetName="datasetName"
      :colour="layer.colour"
      :reportsCount="layer.reports_count"
      :allSelected="allSelected"
      :geometry="layer.geometry"
      :cartoUsername="layer.carto_username"
      :cartoAPIKey="layer.carto_api_key"
      :filter="layer.filter"
      :mapFilters="layer.map_filters"
      :pointTable="layer.point_table"
      :polyTable="layer.poly_table"
      :layerGroup="layerGroup"
      :layerType="layer.layer_type"
      :serviceUrl="layer.service_url"
      :legendUrl="layer.legend_url"
      :accordionItemActive="accordionItemActive"
      :mutuallyExclusiveGroupId="layer.mutually_exclusive_group_id"
    >
    </v-filter>
  </div>
</template>

<script>

import { eventHub } from '../../ibat.js'
import FilterSearch from './FilterSearch.vue'
// import helpers from '../../utilities/helpers.js'
import VFilter from './VFilter.vue'

export default {
  name: 'filter-layers',

  components: { FilterSearch, VFilter },

  props: {
    id: {
      required: true,
    },
    name: {
      required: true,
      type: String
    },
    datasetName: String,
    layers: {
      required: true,
      type: Array
    },
    search: {
      type: Boolean
    },
    layerGroup: {
      required: true,
      type: Object
    },
    toggleId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      searchTerm: '',
      allSelected: false,
      children: this.$children,
      event: 'toggleFilter-' + this.id,
      searchEvent: 'searchInitiated-' + this.id,
      accordionItemActive: false
    }
  },

  mounted () {
    eventHub.$on(this.event, this.allFiltersActive)
    eventHub.$on(this.searchEvent, this.searchFilters)

    if (this.toggleId != '') { eventHub.$on(this.toggleId, this.updateAccordionStatus) }
  },

  computed: {
    hasNoMutuallyExclusiveLayers () {
      // is a number set on every layer's mutually exclusive group id
      return this.layers.every((layer) => typeof layer.mutually_exclusive_group_id !== 'number')
    },

    layersArray () {
      return this.layers
    },

    toggleButton () {
      return this.allSelected ? 'Hide all' : 'Select all'
    }
  },

  methods: {
    toggleGroup () {
      this.allSelected = !this.allSelected
    },

    allFiltersActive () {
      let totalFilters = 0
      let activeFilters = 0

      this.children.forEach(child => {
        if (child.isActive) { activeFilters++ }
        totalFilters++
      })

      if (activeFilters == totalFilters) { this.allSelected = true }
      if (activeFilters == 0) { this.allSelected = false }
    },

    searchFilters (searchTerm) {
      if (searchTerm == '') {
        this.children.forEach(filter => {
          filter.matchesSearch = true
        })
      } else {
        const regex = new RegExp(searchTerm, 'i')

        this.children.forEach(filter => {
          if (filter.name) {
            filter.matchesSearch = filter.name.match(regex) ? true : false
          } else {
            filter.matchesSearch = false
          }
        })
      }
    },

    updateAccordionStatus (status) {
      this.accordionItemActive = status
    }
  }
}
</script>
