// Load extra scripts requested by the views
window.onload = function () {
   const filterYearInput = document.getElementById("filter-year");

   if (filterYearInput) {
     filterYearInput.addEventListener('change', manipulateMonthsOptions);
   }
};

// Manipulate months options based on selected year
function manipulateMonthsOptions(event) {
  var selectedYear = event.target.value;
  var currentYear = new Date().getFullYear()
  var months = (selectedYear == currentYear) ? event.target.dataset.monthList : event.target.dataset.monthsFullList;
  var options = JSON.parse(months).map(month => `<option>${month}</option>`).join("");

  document.getElementById("filter-month").innerHTML = options;
}
