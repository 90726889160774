export const mixinValidate = {
  methods: {
    hasSelected(option) {
      return !!option
    },

    isValidLength(enumerable, min = -Infinity, max = Infinity) {
      return (enumerable.length >= min) && (enumerable.length <= max)
    },

    isValidNumber(number, min = -Infinity, max = Infinity) {
      return (number >= min) && (number <= max)
    },

    isValidDecimal(decimal, allowedPlaces) {
      if (isNaN(decimal)) { return false }

      const places = decimal.toString().split('.')[1]

      return places ? places.length <= allowedPlaces : true
    },

    isValidString(string, regex) {
      return !regex.test(string)
    }
  }
}
