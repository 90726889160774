<template>
  <div class="flex full-height relative" :class="{ 'in-modal': inModal }">

    <div v-if="iframe == 'false'" class="sites__form-wrapper bg-grey-xlight flex flex-h-center">
      <form-create-site :in-modal="inModal" :siteTypes="siteTypes"></form-create-site>
    </div>

    <div map-add-site class="sites__map full-height">
      <mapbox :id="mapId" :in-modal="inModal"></mapbox>
    </div>
  </div>
</template>

<script>
import FormCreateSite from '../form/FormCreateSite.vue';
import Mapbox from '../map/Mapbox.vue';

import { eventHub } from '../../ibat.js';

export default {
  name: 'sites',

  components: { FormCreateSite, Mapbox },

  props: {
    modalId: {
      type: String,
      required: true
    },
    modalContent: Boolean,
    mapId: {
      type: String,
      required: true
    },
    siteTypes: {
      type: Array,
      required: false
    },
    iframe: {
      type: String,
      default: 'false'
    }
  },

  data() {
    return {
      inModal: false,
      locations: {
        modal: '[location-modal]',
        page: '[location-data-map]'
      }
    };
  },

  created() {
    if (this.modalContent) {
      this.inModal = true;
    } else {
      eventHub.$on('saveNewSite', this.move);
      eventHub.$on('modalClosed', this.checkModal);
    }
  },

  methods: {
    checkModal(modalId) {
      if (this.modalId == modalId) {
        this.move();
      }
    },

    move() {
      const locationPage = document.querySelector(this.locations.page);

      if (locationPage) {
        const locationModal = document.querySelector(this.locations.modal);

        let oldParent, newParent;

        if (this.inModal) {
          oldParent = locationModal;
          newParent = locationPage;
        } else {
          oldParent = locationPage;
          newParent = locationModal;
        }

        while (oldParent.childNodes.length > 0) {
          newParent.appendChild(oldParent.childNodes[0]);
        }

        this.inModal = !this.inModal;
      }
    }
  }
};
</script>
