<template>
  <span class="vertical-line" :style="`height: ${height}px`"></span>
</template>

<script>
export default {
  props: {
    height: { type: Number, default: 26 }
  }
}
</script>
