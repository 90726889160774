<template>
  <article class="flex flex-h-center">
    <div class="collapse-article--wrapper" :style="{ width: computedWidth }">
      <div @click="toggleBody" class="collapse-article--title flex flex-h-between">
        <span class="bold">{{ title }}</span>
        <i :class="[icon]"></i>
      </div>
      <div ref="articleBody" class="collapse-article--body" :style="{ height }">
        <slot></slot>
      </div>
    </div>
  </article>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    props: {
      title: { required: true },
      width: { default: 100 },
      collapseOnOpen: { type: Boolean, default: false }
    },

    data() {
      return { isViewingBody: false }
    },

    created() {
      if (this.collapseOnOpen) { eventHub.$on('articleOpened', this.hideBody) }
    },

    beforeDestroy() {
      if (this.collapseOnOpen) { eventHub.$off('articleOpened', this.hideBody) }
    },
    
    computed: {
      icon() {
        return this.isViewingBody ? 'icon-minus' : 'icon-plus'
      },

      height() {
        return this.isViewingBody ? `${this.$refs.articleBody.scrollHeight}px` : '0px'
      },

      computedWidth() {
        return `${Number(this.width)}%`
      }
    },

    methods: {
      toggleBody() {
        if (!this.isViewingBody) {
          eventHub.$emit('articleOpened')
        }

        this.isViewingBody = !this.isViewingBody
      },

      hideBody() {
        this.isViewingBody = false
      }
    }
  }  
</script>
