import { eventHub } from '../ibat.js'

export const storeUser = {
  namespaced: true,

  state: {
    currentUser: null
  },

  // computed: {
  //   user: {
  //     get () {
  //       return this.state
  //     }
  //   }
  // },

  // setters: {
  //   setUser(user) {
  //     this.state.currentUser = user;
  //   }
  // },

  // actions: {
  //   setUser (user) {
  //     this.state.currentUser = user
  //   },

  //   getUser
  // },

  mutations: {
  }
}
