<template>
  <div class="card--dashboard-small" @click="navigateToPage">
  <clickable></clickable>
  <span v-if="accountCard">
    <i class="icon-penguin card--dashboard-small--icon z-0"></i>
    <dashboard-title :icon="'resources_dark'" :title="'Resources'"></dashboard-title>
  </span>
  <span v-else>
    <dashboard-title :icon="card.icon" :title="card.title"></dashboard-title>
    <p v-if="card.count >= 0" class="dashboard-card-small--total">{{ card.count }}</p>
    <img v-if="card.img" class="dashboard-card-small--img" :src="image_path">
  </span>
</div>

</template>

<script>
import Clickable from '../clickable/Clickable.vue'
import DashboardTitle from '../dashboard/DashboardTitle.vue'

export default {
  components: { Clickable, DashboardTitle },

  props: {
    card: { required: true, type: Object },
  },

  computed: {
    image_path: function () {
      return require(`images/dashboard/${this.card.img}`);
    },

    accountCard: function () {
      return this.card.title === 'Account';
    },

    user: function () {
      return this.$store.getters.user;
    }
  },

  methods: {
    navigateToPage: function () {
      if (this.card.title === 'Account') {
        window.location.href = '/resources';
      } else {
        window.location.href = this.card.url;
      }
    }
  }
}
</script>
