<template>
  <span>
    {{centroid}}
  </span>
</template>

<script>
  import * as turf from '@turf/turf'

  export default {
    name: 'geo-centroid',

    props: {
      geojson: {
        required: true
      },
      precision: {
        default: 1
      }
    },

    computed: {
      centroid() {
        let centroid = turf.centroid(this.geojson)
        let coords = centroid.geometry.coordinates

        let output = ''

        if (this.precision > 0) {
          output = [Number(coords[1].toFixed(this.precision)), Number(coords[0].toFixed(this.precision))]
        } else {
          output = [Math.round(coords[1]), Math.round(coords[0])]
        }

        return output.join(', ')
      }
    }
  }
</script>
