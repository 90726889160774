import { polyfill } from 'es6-promise'
polyfill()

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex/dist/vuex.esm'
import { storeForm } from './_store-form'
import { storeMap } from './_store-map'
import { storeModal } from './_store-modal'
import { storeCarto } from './_store-carto'
import { storePagination } from './_store-pagination'
import { storeUser } from './_store-user'
import { storeSemaphore } from './_store-semaphore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    preserveFilters: false
  },

  modules: {
    form: storeForm,
    map: storeMap,
    modal: storeModal,
    carto: storeCarto,
    pagination: storePagination,
    user: storeUser,
    semaphore: storeSemaphore,
  },

  getters: {
    user: function(state) {
      return state.user.currentUser
    }
  },

  actions: {
    setUser: function(store, newUser) {
      return store.state.user.currentUser = newUser;
    }
  },

  mutations: {
    preserveFilter(state) {
      state.preserveFilters = true;
    },

    dismissFilter(state) {
      state.preserveFilters = false;
    }
  }
})
