<template>
  <ul class="ul--table ul--sites ul-unstyled ul-no-margin">
    <p v-show="noResults" class="center section-padding">No country profiles to show</p>

    <page-item-country v-show="!noResults"
      v-for="item, index in items" ref="item"
      :key="index"
      :id="item.id"
      :name="item.name"
      :iso2="item.iso2"
      :countryUrl="item.countryProfileUrl">
    </page-item-country>
  </ul>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  import FlashProvider from '../../utilities/flash-provider.js'
  import PageItemCountry from './PageItemCountry.vue'
  import { mixinPaginationGroup } from '../../mixins/mixin-pagination-group.js'

  export default {
    name: 'page-country-profiles',
    components: { PageItemCountry },
    mixins: [ mixinPaginationGroup ]
  }
</script>
