<template>
  <li class="ul__li site-item">
    <a :href="countryUrl" class="flex flex-v-center ul__a">
      <span class="ul__li-column ul__li-column-1">
        <span :class="['flag-icon flag-icon-' + iso2.toLowerCase()]"></span>
      </span>
      <span class="ul__li-column ul__li-column-2"></span>
      <span class="ul__li-column ul__li-column-3 ul__li-column-small light">{{ name }}</span>
      <span class="ul__li-column ul__li-column-4"></span>
      <span class="ul__li-column ul__li-column-5"></span>
      <span class="ul__li-column ul__li-column-6"></span>
      <span class="ul__li-column ul__li-column-7"></span>
      <span class="ul__li-column ul__li-column-8"></span>
      <span class="ul__li-column ul__li-column-9"></span>
      <span class="ul__li-column ul__li-column-10 flex-h-end">
        <i class="ul__icon icon-chevron-right"></i>
      </span>
    </a>
  </li>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'page-item-country',
    props: {
      id: { required: true },
      name: { required: true },
      iso2: { required: true },
      countryUrl: { required: true },
    },
  }
</script>
