import { eventHub } from '../ibat.js'

export const mixinPaginationGroup = {
  props: {
    canDelete: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      items: this.$store.state.pagination.items
    }
  },

  created () {
    eventHub.$on('itemsUpdated', this.updateItems)
    this.updateItems()
  },

  updated () {
    this.$store.commit('pagination/updateAllSelectedItems', false)
    eventHub.$emit('resetCheckboxes')
    eventHub.$emit('resetCheckboxIds')
  },

  computed: { 
    noResults () { 
      return this.$store.state.pagination.items ? this.$store.state.pagination.items.length == 0 : false  
    } 
  },

  methods: {
    updateItems () {
      this.items = this.$store.state.pagination.items
    }
  }
}
