<template>
  <tooltip v-if="updateAll"
    class="tooltip--closer"
    text="Click to update all sites at once">
    <i @click.stop="triggerAllDataRefresh" class="ul__icon icon-refresh"></i>
  </tooltip>
  <tooltip v-else
    class="tooltip--closer"
    text="Click to update.">
    <i @click.stop="triggerDataRefresh" class="ul__icon icon-refresh"></i>
  </tooltip>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  import FlashProvider from '../../utilities/flash-provider.js'
  import Tooltip from '../tooltip/Tooltip.vue'
  import axios from 'axios'

  export default {
    name: 'refresh-button',

    components: { Tooltip },

    props: {
      id: { required: false },
      updateAll: { type: Boolean, default: false },
      sitesToUpdate: { type: Array },
      portfolioId: { required: false }
    },

    created () {
      const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
      axios.defaults.headers.common['X-CSRF-Token'] = csrf
      axios.defaults.headers.common['Accept'] = 'application/json'
    },

    methods: {
      triggerDataRefresh() {
        axios.post(`/sites/${this.id}/refresh`)
          .then((response) => {
            FlashProvider.display(response.data.message)
            eventHub.$emit('setSiteAsUpdating', { id: this.id })
          })
          .catch(console.error)

      },

      triggerAllDataRefresh() {
        axios.post(`/sites/refresh_all`, { site_ids: this.sitesToUpdate })
        .then((response) => {
          this.getSitesToUpdate()
          FlashProvider.display(response.data.message)
        })
        .catch(console.error)
      },

      getSitesToUpdate () {
        axios.get(`/portfolios/${this.portfolioId}/sites_to_update`)
        .then((response) => {
          this.$emit('update', response.data)
          return response.data
        })
        .catch(console.error)
      },
    }
  }
</script>
