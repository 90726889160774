<template>
  <div class="sites__form form--add-site">

    <modal-local
      v-if="showInfoModal"
      :closeable="true"
      size="large"
      @close="showInfoModal = false"
    >
      <h1>Geospatial Upload</h1>
      <ul>
        <li class="margin-space-top">
          The maximum supported <strong>file size for upload is 25MB</strong> and the maximum number of individual <strong>features/geometries per upload is 3000</strong>.
        </li>
        <li class="margin-space-top">
          Individual geometries in your GIS file will be interpreted as <strong>separate projects</strong>. After uploading your file you will be asked to confirm the number of projects that will be imported.
        </li>
        <li class="margin-space-top">
          If a <strong>name</strong> attribute/column is present for the geometries in your file its value will be used to name each project. If a name attribute is not present the name of your file, appended by a number will be used instead.
        </li>
        <li class="margin-space-top">
          Any unknown characters found in your project names will be represented as �. It is possible to edit the name of your projects from the project screen.
        </li>
        <li class="margin-space-top">
          If there are invalid features present in the file you have uploaded you will receive an error message stating this, along with a <strong>downloadable error CSV</strong> that provides you will a more detailed description of each error.
        </li>
        <li class="margin-space-top">
          Please <strong>do not navigate away from the page</strong> while your upload is processing. Navigating away will result in your upload being cancelled and your projects will not be imported.
        </li>
      </ul>
    </modal-local>

    <div v-show="inModal">
      <tabs v-on:changeTab="resetForm" :in-modal="true" class="tabs--form">
        <tab id="form-1" title="Draw" :active="true">

          <div class="form__field">
            <label for="site-name">Site name</label>
            <text-input
              name="add-site-name"
              :showValidationError="!isValidSiteName"
              :on-input="updateSiteName"
              v-model="siteName">
            </text-input>
            <p class="text-12">Please enter a project name for your new project.</p>
          </div>

          <span>
            <label>Select Site Type </label>
            <select v-model="selectedSiteType">
              <option v-for="siteType in siteTypes" :key="siteType" :value="siteType">{{ siteType }}</option>
            </select>
          </span>

          <div class="form__field">
            <label for="location">Location</label>

            <p class="text-12">Enter a location into the search box on the map.</p>

            <p class="text-12">Alternatively, you can manually place a point, polygon or polyline using the map tools.</p>

            <p v-show="showValidationErrors && !locationValid">Location required</p>
          </div>

          <div v-if="isPolygon">
            <label>Selected area:</label>
            <p>{{getPolygonArea.toLocaleString()}} km<sup>2</sup></p>
            <p v-if="checkPolygonAreaLimit" class="error_explanation">Selected area is larger than the 1,000,000 km<sup>2</sup> limit.</p>
          </div>

          <button @click.prevent="save" class="button button--cta margin-space-right" :class="{'button--disabled': disableSave}" :disabled="disableSave">Save</button>
          <a href="/sites" class="button button--underline">Cancel</a>
        </tab>

        <tab id="form-2" title="Upload" class="section-padding">

          <p>IBAT supports uploading up to 3000 project geometries directly from <strong>ESRI Shapefile</strong>, <strong>KML/KMZ</strong>, <strong>CSV</strong> and <strong>XLSX/Excel</strong> files. Expand an option below for further information on uploading each format.</p>

          <collapse-article title="ESRI Shapefile" width="70" :collapse-on-open="true" class="padding-top">
            <div class="margin-space-left">
              <p>All files must be compressed into a .zip file.</p>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Upload Format</strong></p>
                <p class="flex-1">.zip</p>
              </div>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Required Projection</strong></p>
                <p class="flex-1"><a href="https://spatialreference.org/ref/epsg/4326/" target="_blank">WGS 84</a></p>
              </div>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Mandatory Files</strong></p>
                <p class="flex-1">.shp .shx .prj .dbf</p>
              </div>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Optional Files</strong></p>
                <p class="flex-1">.sbn .sbx .atx .fbn .fbx .ain .aih .ixs .mxs .xml .cpg .qpj .shp.xml</p>
              </div>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Example Files</strong></p>
                <p class="flex-1">
                  <a class="form__link" href="/shp/example_point_shp.zip" download>Point</a>
                  <a class="form__link" href="/shp/example_line_shp.zip" download>Line</a>
                  <a class="form__link" href="/shp/example_poly_shp.zip" download>Polygon</a>
                </p>
              </div>
            </div>
          </collapse-article>

          <collapse-article title="KMZ" width="70" :collapse-on-open="true" >
            <div class="margin-space-left">
              <p>All files must be compressed into a .kmz file.</p>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Upload Format</strong></p>
                <p class="flex-1">.kmz</p>
              </div>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Mandatory Files</strong></p>
                <p class="flex-1">.kml</p>
              </div>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Optional Files</strong></p>
                <p class="flex-1">.jpg .jpeg .png .svg</p>
              </div>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Example Files</strong></p>
                <p class="flex-1">
                  <a class="form__link" href="/kmz/example_kmz.kmz" download>Download</a>
                </p>
              </div>
            </div>
          </collapse-article>

          <collapse-article title="KML" width="70" :collapse-on-open="true" >
            <div class="margin-space-left">
              <p>KML files may be uploaded directly.</p>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Upload Format</strong></p>
                <p class="flex-1">.kml</p>
              </div>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Example Files</strong></p>
                <p class="flex-1">
                  <a class="form__link" href="/kml/example_kml.kml" download>Download</a>
                </p>
              </div>
            </div>
          </collapse-article>

          <collapse-article title="XLSX/Excel" width="70" class="padding-bottom" :collapse-on-open="true" >
            <div class="margin-space-left">
              <p>XLSX/Excel files may be used to upload point geometries only, with coordinates being specified in decimal degrees.</p>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Upload Format</strong></p>
                <p class="flex-1">.xlsx</p>
              </div>
              <div class="flex flex-h-between">
                <p class="flex-1"><strong>Template File</strong></p>
                <p class="flex-1">
                  <a class="form__link" href="https://ibat-assets-production.s3.eu-west-2.amazonaws.com/example.xlsx" download>Download</a>
                </p>
              </div>
            </div>
          </collapse-article>

          <span class="display-block margin-space-bottom center grey">Learn more about file formatting<i @click="showInfoModal = true" class="margin-space-left icon-question-mark icon--tooltip"></i></span>

          <file-input></file-input>

        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  import CollapseArticle from '../collapse/CollapseArticle'
  import FileInput from '../form_fields/FileInput.vue'
  import ModalLocal from '../modal/ModalLocal.vue'
  import Tab from '../tabs/Tab.vue'
  import Tabs from '../tabs/Tabs.vue'
  import TextInput from '../form_fields/TextInput.vue'
  import Tooltip from '../tooltip/Tooltip.vue'

  import mapFunctions from '../../utilities/map-functions.js'
  import FlashProvider from '../../utilities/flash-provider.js'
  import { eventHub } from '../../ibat.js'
  import { mixinValidate } from '../../mixins/mixin-validate'
  import { mixinLoggedIn } from '../../mixins/mixin-logged-in'

  import * as turf from '@turf/turf'

  export default {
    name: 'form-create-site',

    components: { CollapseArticle, FileInput, ModalLocal, Tabs, Tab, TextInput, Tooltip },

    mixins: [mixinValidate, mixinLoggedIn],

    props: {
      inModal: {
        type: Boolean,
        required: true
      }
      ,
      siteTypes: {
        type: Array,
        required: false
      }
    },

    data () {
      return {
        resetEvent: 'resetAddSiteForm',
        showValidationErrors: false,
        fieldsWithErrors: 0,
        polygonArea: 0,
        polygonAreaLimit: 1000000,
        siteName: '',
        showInfoModal: false,
        selectedSiteType: "",
      }
    },

    created () {
      eventHub.$on('modalClosed', this.resetForm)
      eventHub.$on('fieldValidated', this.updateFieldsWithErrors)

      const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')
      axios.defaults.headers.common['X-CSRF-Token'] = csrf
      axios.defaults.headers.common['Accept'] = 'application/json'
    },

    beforeDestroy () {
      eventHub.$off('modalClosed')
      eventHub.$off('fieldValidated')
    },

    computed: {
      locationNotFound () {
        return this.$store.state.map.markerHasError
      },

      markerErrorMsg () {
        return this.$store.state.map.markerErrorMsg
      },

      locationValid () {
        let geometry = this.$store.state.map.geometry
        let geometryType = this.$store.state.map.geometryType

        return (this.validateLocationGeometry(geometry) && geometryType)
      },

      isPolygon() {
        return this.$store.state.map.geometryType === 'polygon'
      },

      getPolygonArea() {
        if (!this.$store.state.map.geometryType) {
          return 0
        }

        let geometry = this.geometryToGeoJSON(this.$store.state.map.geometry)
        this.polygonArea = Math.ceil(Number(turf.area(geometry)) / 1000000)

        return this.polygonArea
      },

      checkPolygonAreaLimit() {
        return this.polygonArea >= this.polygonAreaLimit
      },

      disableSave() {
        return (
          !this.$store.state.map.geometryType
          || this.$store.state.map.geometryType === ''
          || (this.$store.state.map.geometryType === 'polygon' && this.checkPolygonAreaLimit)
          || !this.isValidSiteName
        )
      },

      isValidSiteName () {
        return this.isValidLength(this.siteName, 1) && this.isValidString(this.siteName, /\\|\//)
      }
    },

    methods: {
      updateSiteName(siteName) {
        this.$store.commit('form/updateFormField', { name: 'addSiteName', value: siteName })
      },

      save () {
        const hasErrors = this.fieldsWithErrors > 0

        if (hasErrors || !this.locationValid) {
          this.showValidationErrors = true

        } else {
          const is_polygon = this.$store.state.map.geometryType == "polygon"

          const site = {
            geom: this.$store.state.map.geometry,
            geom_type: this.$store.state.map.geometryType,
            name: this.$store.state.form.addSiteName,
            site_type_name: this.selectedSiteType,
            area: is_polygon ? this.getPolygonArea : 0
          }

          axios.post('/sites', { site })
          .then(response => {
            if (typeof response.data.site_id !== "undefined") {
              Turbo.visit(`/sites/${response.data.site_id}`)
            } else {
              Turbo.visit('/sites')
            }
          })
          .catch(error => {
            FlashProvider.display(error.response.data.message, 'alert')
          })
        }
      },

      cancel () {
        eventHub.$emit('requestModalClose', 'create-site')
      },

      updateFieldsWithErrors (hasPassed) {
        this.fieldsWithErrors = hasPassed ? this.fieldsWithErrors - 1 : this.fieldsWithErrors + 1
      },

      resetForm () {
        eventHub.$emit('siteFormReset')

        this.$store.dispatch('map/resetSite')
        this.$store.dispatch('form/resetAddSiteForm')

        this.resetDefaults();
        eventHub.$emit(this.resetEvent)
      },

      validateLocationGeometry (geometry) {
        const isObject = typeof geometry == 'object'

        return (isObject && (geometry.hasOwnProperty('point') || geometry.hasOwnProperty('poly')))
      },

      geometryToGeoJSON (geometryObj) {
        let geometry

        if (geometryObj.poly) {
          geometry = turf.polygon([geometryObj.poly.map((coord) => {
            return [coord.lng, coord.lat]
          })])
        }

        return geometry
      },

      resetDefaults() {
        this.siteName = ''
        this.showValidationErrors = false
        this.fieldsWithErrors = 0
      }
    }
  }
</script>
