<template>
  <div class="modal-container" :class="{ 'active' : isActive }">
    <div v-if="closeable" class="modal-wrapper flex-h-center" :class="{ 'active' : isActive }" @click.self="closeModal"></div>
    <div v-else class="modal-wrapper flex-h-center" :class="{ 'active' : isActive }"></div>

    <div class="modal" :class="[{'active' : isActive}, modalClasses]">

      <div class="modal__content">
        <button v-if="closeable" class="button--close modal__close icon-close" @click="closeModal"></button>

        <slot></slot>

        <div v-show="useStoreContent" v-html="storeModalContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'modal',

    props: {
      modalId: {
        type: String,
        required: true
      },
      modalClasses: String,
      closeable: { default: true, type: Boolean },
      active: { default: false, type: Boolean }
    },

    data () {
      return {
        isActive: false
      }
    },

    created () {
      this.isActive = this.active
      eventHub.$on('requestModalOpen', this.onRequestModalOpen)
      eventHub.$on('requestModalClose', this.onRequestModalClose)
    },

    beforeDestroy () {
      eventHub.$off('requestModalOpen', this.onRequestModalOpen)
      eventHub.$off('requestModalClose', this.onRequestModalClose)
    },

    computed: {
      useStoreContent () {
        return this.$store.state.modal.useStoreContent
      },

      storeModalContent () {
        return this.$store.state.modal.modalContent
      }
    },

    methods: {
      onRequestModalOpen (id) {
        if (this.modalId !== id) {
          return
        }

        this.openModal()
      },

      onRequestModalClose (id) {
        if (this.modalId !== id) {
          return
        }

        this.closeModal()
      },

      openModal () {
        eventHub.$emit('resetModalTabs', this.modalId)
        this.toggleModal()
        eventHub.$emit('modalOpened', this.modalId) 
      },

      closeModal () {
        this.toggleModal()
        this.$store.commit('modal/updateUseStoreContent')
        this.$store.commit('modal/updateUseStoreContent', false)
        eventHub.$emit('modalClosed', this.modalId)
        eventHub.$emit('resetPages')
      },

      toggleModal () {
        this.isActive = !this.isActive
      }
    }
  }
</script>

<style lang="scss">
  .modal-container {
    display: none;
    position: absolute;
    top: -1000px;

    &.active {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2000;
    }
  }

  .modal-wrapper {
    background-color: rgba(black, .3);

    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
</style>
