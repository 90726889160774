<template>
  <div :id="id" class="carousel">
    <div class="carousel__slides" :style="styles" v-touch:swipe.right="moveBackward" v-touch:swipe.left="moveForward">
      <slot></slot>
    </div>

    <carousel-dots v-show="showDots" :pages="totalPages" :active-page="activePage"></carousel-dots>
  </div>
</template>

<script>
  import { mixinResponsive } from '../../mixins/mixin-responsive.js'
  import { eventHub } from '../../ibat.js'

  import CarouselDots from './CarouselDots'

  export default {
    name: 'carousel',

    mixins: [mixinResponsive],

    components: { CarouselDots },

    props: {
      id: {
        type: String,
        required: true
      },
      responsiveSlidesToShow: {
        type: Array,
        default: () => [1, 1, 1]
      }
    },

    data () {
      return {
        carouselElement: '',
        carouselStyle: '',
        slides: [],
        totalPages: 0,
        totalSlides: 0,
        activePage: 1,
        slideWidth: 0,
        stylesObject: {
          'flex-basis': '0px',
          'flex-wrap': 'nowrap',
          transform: 'translateX(0px)',
          width: '0px'
        }
      }
    },

    created () {
      eventHub.$on('requestNewPage', this.moveTo)
      eventHub.$on('windowResized', this.configureCarousel)
    },

    mounted () {
      this.carouselSetup()
    },

    computed: {
      styles () {
        return this.stylesObject
      },
      showDots () {
        return this.totalPages > 1
      }
    },

    methods: {
      carouselSetup () {
        this.carouselElement = document.getElementById(this.id),
        this.carouselStyle = window.getComputedStyle(this.carouselElement, null)
        this.slides = this.$children.filter(child => {
          return child.$options._componentTag == 'carousel-slide'
        })
        this.totalSlides = this.slides.length
        
        this.configureCarousel()
      },

      configureCarousel () {
        const carouselWidth = this.carouselElement.clientWidth - parseInt(this.carouselStyle.getPropertyValue('padding-left')) - parseInt(this.carouselStyle.getPropertyValue('padding-right')),
          currentBreakpoint = this.getCurrentBreakpoint()

        this.slidesToShow = this.getSlidesToShow(currentBreakpoint)

        if(this.slidesToShow === 'all') {
          this.stylesObject['flex-wrap'] = 'wrap'
          this.stylesObject.width = 'auto'
        } else {
          this.stylesObject['flex-wrap'] = 'nowrap'
          this.slideWidth = carouselWidth / this.slidesToShow
          this.totalPages = Math.ceil(this.totalSlides / this.slidesToShow)

          const slidesWidth = this.slideWidth * this.totalSlides

          this.stylesObject['flex-basis'] = (this.slideWidth / slidesWidth)*100 + '%'
          this.stylesObject.width = slidesWidth + 'px'
        }

        this.move(1)
      },

      getSlidesToShow (breakpoint) {
        const currentSlidesToShow = {
          'small': this.responsiveSlidesToShow[0],
          'medium': this.responsiveSlidesToShow[1],
          'large': this.responsiveSlidesToShow[2],
        }

        return currentSlidesToShow[breakpoint]
      },

      moveForward () {
        if(this.activePage == this.totalPages ) { return false }

        this.move(this.activePage + 1)
      },

      moveBackward () {
        if(this.activePage == 1) { return false }

        this.move(this.activePage - 1)
      },

      moveTo (page) {
        if(this.activePage == page) { return false }

        this.move(page)
      },

      move (page) {
        this.activePage = page
        const offset = (this.activePage - 1) * -(this.slideWidth * this.slidesToShow)
        
        this.stylesObject.transform = `translateX(${offset}px)`
      }
    }
  }
</script>
