<template>
  <div>
    <div class="flex flex-wrap">
      <p v-for="option in options" :key="option.id" class="radio--rectangle flex no-margin">
        <input
          v-if="checkActiveState(option)"
          required
          type="radio"
          class="radio__input"
          :value="option.id"
          :id="`${id}-${option.id}`"
          v-model="selectedOption"
          @click="changeInput(option)">

        <label
          :for="`${id}-${option.id}`"
          class="radio__label flex flex-v-center flex-h-center"
          :class="{ 'radio__label--disabled': !checkActiveState(option) }">
          {{ option.title }}
        </label>
      </p>
    </div>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'radio-buttons',

    props: {
      id: { required: true },
      options: { required: true },
    },

    data() {
      return {
        selectedOption: undefined
      }
    },

    // TODO: Pass in default to component
    created () {
      this.changeInput(this.options[0])
    },

    methods: {
      checkActiveState (option) {
        if('active' in option) {
          return option.active
        } else {
          return true
        }
      },

      changeInput (option) {
        this.selectedOption = option.id

        this.$emit('input', option)
      }
    }
  }
</script>
