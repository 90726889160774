<template>
  <button @click.prevent="openPane">{{ title }}</button>
</template>

<script>
  import helpers from '../../utilities/helpers.js'
  import { eventHub } from '../../ibat.js'

  export default {
    name: 'filter-pane-trigger',

    props: {
      id: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    },

    methods: {
      openPane () {
        const event = 'triggerPaneOpen-' + helpers.spacesToKebab(this.id)
        
        eventHub.$emit(event)
      }
    }
  }
</script>
