<template>
  <div>
    <modal :modal-id="modalId" modal-classes="modal--delete">
      <p>All the users, projects, reports and GIS Downloads will be deleted. Are you sure?</p>

      <div class="flex flex-center">
        <button @click="deleteItems" class="button--cta-red margin-space-right">Yes</button>
        <button class="button--cta" @click="closeModal">Cancel</button>
      </div>
    </modal>

    <div v-show="!hasApiAccess" class="flex flex-v-end button-margin-top flex-h-end">
      <modal-trigger modal-id="delete-organisation-modal" class="button--cta-red">Delete</modal-trigger>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  import { eventHub } from '../../ibat.js'
  import Modal from '../modal/Modal.vue'
  import ModalTrigger from '../modal/ModalTrigger.vue'
  import FlashProvider from '../../utilities/flash-provider'
  export default {
    name: 'delete-organisation-modal',
    components: { Modal, ModalTrigger },
    props: {
      businessId: { type: Number, required: true },
      redirectUrl: { type: String, required: true },
      hasApiAccess: { type: Boolean, required: true },
    },
    data () {
      return {
        modalId: 'delete-organisation-modal',
      }
    },
    methods: {
      deleteItems () {
        axios.delete(`/admin/business/${this.businessId}`)
          .then((response) => {
            window.location.href = this.redirectUrl
          })
          .catch(err => {
            if (err.response.data) {
              FlashProvider.display(err.response.data.message, 'alert')
            }
          })
      },
      closeModal () {
        eventHub.$emit('requestModalClose', this.modalId)
      },
    }
  }
</script>
